import React from "react";
import Page from "../library/Page";
import { Table } from "semantic-ui-react";
import CertificateListItem from "./CertificateListItem";
import Spinner from "../library/Spinner";
import { spacing, stateColors } from "../library/theme";
import { css } from "glamor";

const emptyClass = css({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: spacing.l,
  ">h4": {
    color: stateColors.empty,
  },
});

export default function CertificatesList(props) {
  const {
    classrooms,
    subscriptions,
    hasClassroomCertificate,
    hasSubscriptionCertificate,
    loading,
  } = props;

  if (loading) return <Spinner />;

  return (
    <Page title="Certificados">
      {hasClassroomCertificate || hasSubscriptionCertificate ? (
        <Table>
          <Table.Body>
            {subscriptions
              .filter((subscription) =>
                subscription.classrooms.every(
                  (classroom) => classroom.meta.progress >= 0.8
                )
              )
              .map((subscription) => (
                <CertificateListItem
                  key={`subscription${subscription.id}`}
                  subscription={subscription}
                />
              ))}

            {classrooms
              .filter(
                (classroom) =>
                  classroom.meta.progress >= 0.8 &&
                  !classroom.isCertificateOnlyBySubscription
              )
              .map((classroom) => (
                <CertificateListItem
                  key={`certificate${classroom.id}`}
                  classroom={classroom}
                />
              ))}
          </Table.Body>
        </Table>
      ) : (
        <div className={emptyClass}>
          <h4>Sem certificados</h4>
        </div>
      )}
    </Page>
  );
}
