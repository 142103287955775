import React from "react";
import { DropdownField } from "../../library";

const cakeOptions = [
  { text: "Coberto", value: "covered", key: "covered" },
  { text: "Naked", value: "naked", key: "naked" },
  { text: "Seminaked", value: "seminaked", key: "seminaked" },
];

export default function CakeTypeField({
  cakeTypeFieldName = "cakeType",
  options = cakeOptions,
}) {
  return (
    <DropdownField
      name={cakeTypeFieldName}
      label="Tipo de bolo"
      options={options}
      errorMessageDisabled
      placeholder="Selecione"
      required
      fluid
    />
  );
}
