import { createSelector } from "reselect";

export const getCurrentUserId = (state) => state.currentUserId;
const getUsers = (state) => state.entities.users;

export const getCurrentUser = createSelector(
  getUsers,
  getCurrentUserId,
  (users, id) => users[id]
);

export const getCurrentUserSubscriptionIds = createSelector(
  getCurrentUser,
  (user) => user?.subscriptionIds || []
);

export const getCurrentUserValidSubscriptionIds = createSelector(
  getCurrentUser,
  (user) => user?.validSubscriptionIds || []
);
