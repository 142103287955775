import Fields from "./CakePerGuestCalculatorFields";
import Result from "./CakePerGuestCalculatorResult";
import Description from "./CakePerGuestCalculatorDescription";
import calculate from "./calculate";

const CakePerGuestCalculator = {
  Description,
  Fields,
  Result,
  Custom: () => null,
  calculate,
};

export default CakePerGuestCalculator;
