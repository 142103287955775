import { apiGet } from "./helpers/api";
import { normalize, schema } from "normalizr";

const subscription = new schema.Entity("subscriptions");

export const fetchSubscriptions = (dispatch) => {
  dispatch({ type: "SUBSCRIPTIONS_FETCH_REQUESTED" });
  return apiGet(`/api/v1/subscriptions`).then((response) =>
    dispatch({
      type: "SUBSCRIPTIONS_FETCHED",
      ...normalize(response.body.data, new schema.Array(subscription)),
    })
  );
};

export const fetchSubscription = (dispatch, subscriptionId) => {
  dispatch({ type: "SUBSCRIPTIONS_FETCH_REQUESTED" });
  return apiGet(`/api/v1/subscriptions/${subscriptionId}`).then((response) =>
    dispatch({
      type: "SUBSCRIPTION_FETCHED",
      ...normalize(response.body.data, subscription),
    })
  );
};
