import { connect } from "react-redux";
import { getCurrentUser } from "../../selectors/userSelectors";
import MainMenuProfile from "./MainMenuProfile";

function mapStateToProps(state) {
  const currentUser = getCurrentUser(state);
  return {
    currentUser,
  };
}

export default connect(mapStateToProps)(MainMenuProfile);
