import React from "react";
import { Search } from "semantic-ui-react";
import { makeIsMobile } from "../../../utils/useScreenWidth";

export default function SearchInput(props) {
  const isMobile = makeIsMobile();

  return (
    <Search
      input={
        props.value && !props.loading && props.results
          ? {
              icon: "search",
              iconPosition: "left",
              fluid: true,
              labelPosition: "right",
              label: {
                content: "Ver mais",
                as: "a",
                href: `/search/${props.value}`,
                target: "_self",
                color: "red",
              },
            }
          : {
              icon: "search",
              iconPosition: "left",
              fluid: true,
            }
      }
      noResultsMessage={`Nenhum resultado encontrado para "${props.value}"`}
      onSearchChange={props.onChange}
      aligned={isMobile ? "left" : "right"}
      {...props}
    />
  );
}
