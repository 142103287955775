import React from "react";
import {
  css,
  spacing,
  uiColors,
  typographyColors,
  Container,
} from "../../library";

const container = {
  marginTop: spacing.xl,
  padding: spacing.m,
  background: uiColors.fieldBackground,
  borderRadius: spacing.m,
  display: "flex",
  flexWrap: "wrap",
  gap: spacing.m,
  color: typographyColors.defaultBody,
};
const titleClass = css({
  color: typographyColors.defaultTitle,
  marginBottom: spacing.m,
  fontWeight: "bold",
});
const resultNumberClass = css({ fontWeight: "bold", fontSize: 16 });

const leftContent = css({ minWidth: 275, flex: 1 });
const imageWrapper = css({
  position: "relative",
  paddingBottom: "56.25%",
  height: 0,
});
const imageClass = css({
  position: "absolute",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  border: "none",
  borderRadius: spacing.m,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center center",
});
const rightContent = css({
  minWidth: 275,
  flex: 1,
  display: "flex",
  flexDirection: "column",
});

export default function CakePerGuestCalculatorResult({ result }) {
  return result ? (
    <Container style={container}>
      <div className={leftContent}>
        <div className={imageWrapper}>
          <div
            className={css(imageClass, {
              backgroundImage: `url(${result.imageUrl})`,
            })}
          ></div>
        </div>
      </div>

      <div className={rightContent}>
        <h3 className={titleClass}>{result.title}</h3>
        <p>
          Você precisará de um total de{" "}
          <strong className={resultNumberClass}>
            "{result.cakeAmount} kg"
          </strong>{" "}
          de bolo
        </p>
        <p>
          Serão{" "}
          <strong className={resultNumberClass}>"{result.perGuest} g"</strong>{" "}
          de bolo por convidado
        </p>
      </div>
    </Container>
  ) : null;
}
