import React, { useCallback, useState, useEffect } from "react";
import { connect } from "react-redux";
import { getLevelsByLevelGroup } from "../../selectors/levelSelectors";
import { getCurrentLevel } from "../../selectors/lessonSelectors";
import LevelGroupListItem from "./LevelGroupListItem";

function LevelGroupListItemContainer(props) {
  const { currentLevel, levelGroup, levels } = props;
  const isActive = currentLevel?.levelGroupId === levelGroup.id;
  const [active, setActive] = useState(isActive);
  const handleClick = useCallback(() => setActive(!active));

  useEffect(() => setActive(isActive), [isActive]);

  if (!levels.length) return null;

  return (
    <LevelGroupListItem {...props} handleClick={handleClick} active={active} />
  );
}

function mapStateToProps(state, ownProps) {
  return {
    levels: getLevelsByLevelGroup(state, ownProps),
    currentLevel: getCurrentLevel(state),
  };
}

export default connect(mapStateToProps)(LevelGroupListItemContainer);
