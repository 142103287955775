const initialState = {
  loading: false,
  results: [],
  value: "",
  entity: "classrooms",
};

export default function searchReducer(state = initialState, action) {
  switch (action.type) {
    case "SEARCH_FETCH_REQUEST":
      return { ...state, loading: true };
    case "SEARCH_FETCHED":
      return { ...state, results: action.results, loading: false };
    case "ADVANCED_SEARCH_TERM_CHANGED":
      return {
        ...state,
        term: action.term,
      };
    case "ADVANCED_SEARCH_ENTITY_CHANGED":
      return {
        ...state,
        loading: false,
        entity: action.entity,
      };
    default:
      return state;
  }
}
