import { createSelector } from "reselect";

export const getTrails = (state) => state.entities.trails;
export const getTrailId = (_, id) => id;

export const getTrailById = createSelector(
  getTrails,
  getTrailId,
  (trails, trailId) => trails[trailId]
);
