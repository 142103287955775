import React from "react";
import { DropdownField } from "../../library";

const layerOptions = [
  { text: "2 massas e 1 recheio", value: 0.66, key: "2" },
  { text: "3 massas e 2 recheios", value: 1, key: "3" },
  { text: "4 massas e 3 recheios", value: 1.33, key: "4" },
  { text: "5 massas e 4 recheios", value: 1.66, key: "5" },
  { text: "6 massas e 5 recheios", value: 2, key: "6" },
  { text: "7 massas e 6 recheios", value: 2.33, key: "7" },
];

export default function LayerFactorField({
  layerFactorFieldName = "layerFactor",
  layerFactorLabel = "Selecione as camadas",
  options = layerOptions,
}) {
  return (
    <DropdownField
      name={layerFactorFieldName}
      label="Selecione as camadas"
      options={options}
      placeholder="Selecione uma opção"
      errorMessageDisabled
      required
      fluid
    />
  );
}
