import { css } from "glamor";
import React from "react";
import Spinner from "../library/Spinner";
import { spacing } from "../library/theme";
import SubscriptionClassroomListContainer from "./SubscriptionClassroomListContainer";
import Page from "../library/Page";
import SearchFormContainer from "../classrooms/SearchFormContainer";
import BannersContainer from "../banner/BannersContainer";
import ClassroomsInProgressContainer from "../classrooms/ClassroomsInProgressContainer";
import TrailsContainer from "../trails/TrailsContainer";

const loadingWrapper = css({
  padding: spacing.xxl,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
});
const contentStyle = css({
  marginRight: `-${spacing.m}`,
});

export default function SubscriptionList(props) {
  const {
    loading,
    subscription,
    title = undefined,
    hasClassroomsInProgress,
    tags,
  } = props;

  return loading ? (
    <div className={loadingWrapper}>
      <Spinner inline text="Carregando..." />
    </div>
  ) : (
    <Page
      title={title}
      filter={<SearchFormContainer />}
      className={contentStyle.toString()}
    >
      <BannersContainer />
      {hasClassroomsInProgress && (
        <ClassroomsInProgressContainer subscriptionId={subscription?.id} />
      )}
      {subscription?.main && (
        <TrailsContainer subscriptionId={subscription?.id} />
      )}
      <SubscriptionClassroomListContainer
        tags={tags}
        subscription={subscription}
      />
    </Page>
  );
}
