import React, { useCallback } from "react";
import { connect } from "react-redux";
import Lesson from "./Lesson";
import {
  getCurrentLesson,
  getPrevLesson,
  getNextLesson,
} from "../../selectors/lessonSelectors";
import { createOrUpdateLessonsUser } from "../../processes/lessonsUserProcesses";
import { makeCurrentProgress } from "../../selectors/lessonsUserSelectors";
import { canRateLesson } from "../../selectors/ratingSelectors";
import { createLessonRating } from "../../processes/ratingsProcesses";

function LessonContainer(props) {
  const { dispatch, lesson, currentClassroomId } = props;
  const subscriptionId =
    props?.subscriptionId || props?.match?.params?.subscriptionId;
  const lessonRatingHandler = useCallback(
    (_, { rating }) => {
      createLessonRating(dispatch, lesson?.id, rating);
    },
    [dispatch, lesson]
  );
  const lessonProgressHandler = useCallback(
    (progress) =>
      createOrUpdateLessonsUser(
        dispatch,
        currentClassroomId,
        lesson.id,
        progress
      ),
    [dispatch, lesson, currentClassroomId]
  );

  return (
    <Lesson
      lessonProgressHandler={lessonProgressHandler}
      lessonRatingHandler={lessonRatingHandler}
      subscriptionId={subscriptionId}
      {...props}
    />
  );
}

function mapStateToProps(state, ownProps) {
  const { id } = ownProps.match.params;
  const lesson = getCurrentLesson(state, id);

  return {
    lesson,
    currentClassroomId: state.currentClassroomId,
    nextLesson: getNextLesson(state),
    prevLesson: getPrevLesson(state),
    progress: makeCurrentProgress(state, lesson?.id),
    canRate: canRateLesson(state, lesson?.id),
  };
}

export default connect(mapStateToProps)(LessonContainer);
