import React from "react";
import ClassroomListItemContainer from "../classrooms/ClassroomListItemContainer";
import ClassroomsEmpty from "../classrooms/ClassroomsEmpty";
import List from "../library/List";

export default function Trails(props) {
  const { subscriptionId, loading, classrooms } = props;

  const renderItem = (classroom, index) => (
    <ClassroomListItemContainer
      key={index}
      classroom={classroom}
      subscriptionId={subscriptionId}
    />
  );
  return (
    <>
      <div>
        {loading ? (
          <Spinner />
        ) : (
          <>
            {classrooms.length ? (
              <List
                title="Sua trilha"
                data={classrooms}
                renderItem={renderItem}
                hideIcon
              />
            ) : (
              <ClassroomsEmpty />
            )}
          </>
        )}
      </div>
    </>
  );
}
