const initialState = {};
const getUsers = (action) => action.entities.users;

export default function UsersReducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case "USER_FETCHED":
    case "USER_UPDATED":
    case "USERS_FETCHED":
      return { ...state, ...getUsers(action) };
    case "USER_TERMS_ACCEPTED":
      newState = state;
      const user = newState[action.userId];
      return user
        ? { ...newState, [user.id]: { ...user, acceptedTerms: true } }
        : state;
    default:
      return state;
  }
}
