const initialState = {
  loading: false,
};

export default function recommendationListReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case "RECOMMENDATIONS_FETCH_REQUESTED":
      return { ...state, loading: true };
    case "RECOMMENDATIONS_FETCHED":
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
