import React from "react";
import { Grid, DropdownField } from "../../library";
import makeDropDownOptions from "../../../utils/makeDropDownOptions";
import {
  ShapeField,
  LayerFactorField,
  CakeTypeField,
  CircleDiameterField,
  RetangularDimensionsFields,
} from "../utils";

const fruitOptions = [
  { text: "Sim", value: "withFruits", key: "withFruits" },
  { text: "Não", value: "withoutFruits", key: "withoutFruits" },
];

const layerOptions = [
  { text: "3 massas e 2 recheios", value: 3, key: "3" },
  { text: "4 massas e 3 recheios", value: 4, key: "4" },
  { text: "5 massas e 4 recheios", value: 5, key: "5" },
  { text: "6 massas e 5 recheios", value: 6, key: "6" },
  { text: "7 massas e 6 recheios", value: 7, key: "7" },
];

export default function SlicesPerGuestCalculatorFields({ shape }) {
  return (
    <>
      <Grid.Column>
        <ShapeField />
      </Grid.Column>

      <Grid.Column>
        <LayerFactorField options={layerOptions} />
      </Grid.Column>

      <Grid.Column>
        <CakeTypeField />
      </Grid.Column>

      <Grid.Column>
        <DropdownField
          name="fruitsPresent"
          label="Com geleias ou frutas?"
          options={fruitOptions}
          placeholder="Selecione uma opção"
          errorMessageDisabled
          required
          fluid
        />
      </Grid.Column>

      {shape == "circular" && (
        <Grid.Column>
          <CircleDiameterField />
        </Grid.Column>
      )}

      {shape == "retangular" && (
        <Grid.Column>
          <RetangularDimensionsFields />
        </Grid.Column>
      )}
    </>
  );
}
