import React from "react";
import { Form, TextField } from "../library/forms";
import { Menu, Tab } from "semantic-ui-react";
import AdvancedSearchList from "./AdvancedSearchList";
import { spacing } from "../library/theme";
import { css } from "glamor";

const formStyle = {
  display: "flex",
  justifyContent: "end",
  padding: spacing.s,
  marginTop: spacing.l,
  width: "100%",
};
const containerWrapper = css({
  padding: spacing.m,
});

export default function AdvancedSearchForm(props) {
  const { searchResults, activeIndex, onTabChange, loading } = props;

  const tabs = [
    {
      menuItem: <Menu.Item disabled={loading}> Turmas </Menu.Item>,
      entity: "classrooms",
      pane: {
        attached: "false",
        content: (
          <AdvancedSearchList loading={loading} searchResults={searchResults} />
        ),
      },
    },
    {
      menuItem: <Menu.Item disabled={loading}> Aulas </Menu.Item>,
      entity: "lessons",
      pane: {
        attached: "false",
        content: (
          <AdvancedSearchList loading={loading} searchResults={searchResults} />
        ),
      },
    },
    {
      menuItem: <Menu.Item disabled={loading}> Arquivos </Menu.Item>,
      entity: "attachments",
      pane: {
        attached: "false",
        content: (
          <AdvancedSearchList loading={loading} searchResults={searchResults} />
        ),
      },
    },
  ];

  return (
    <div className={containerWrapper}>
      <Form onSubmit={props.handleSubmit} error={props.error} style={formStyle}>
        <TextField
          name="nameUnaccent"
          placeholder="Pesquisar..."
          width="100%"
          icon="search"
          iconPosition="left"
          fluid
        />
      </Form>
      <Tab
        menu={{ secondary: true, pointing: true }}
        panes={tabs}
        renderActiveOnly={false}
        activeIndex={activeIndex}
        defaultActiveIndex={0}
        onTabChange={onTabChange}
        fluid
      />
    </div>
  );
}
