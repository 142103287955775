import React, { useCallback } from "react";
import { createAcContactTag } from "../../processes/activeCampaignProcesses";
import AttachmentListItem from "./AttachmentListItem";

export default function AttachmentListItemContainer(props) {
  const { attachment, index } = props;

  const createAcContactTagHandler = useCallback(
    async () => await createAcContactTag("attachment", attachment.id),
    [attachment.id]
  );

  return (
    <AttachmentListItem
      createAcContactTagHandler={createAcContactTagHandler}
      attachment={attachment}
      index={index}
    />
  );
}
