import React from "react";
import { Form } from "../library/forms";
import SearchField from "../library/forms/SearchField";

export default function SearchForm(props) {
  const { loading, results, onResultSelect } = props;
  return (
    <Form onSubmit={props.handleSubmit} error={props.error}>
      <SearchField
        name="nameUnaccent"
        placeholder="Pesquisar..."
        category
        onResultSelect={onResultSelect}
        loading={loading}
        results={results}
      />
    </Form>
  );
}
