import React from "react";
import { Route, Switch } from "react-router-dom";
import LMS from "../components/LMS";
import CalculatorFormContainer from "../components/calculators/CalculatorFormContainer";
import CertificatesListContainer from "../components/certificates/CertificatesListContainer";
import ClassroomModal from "../components/classrooms/ClassroomModal";
import HomeContainer from "../components/home/HomeContainer";
import LessonContainer from "../components/lessons/LessonContainer";
import ProfileEditFormContainer from "../components/profileEdit/ProfileEditFormContainer";
import RecipesContainer from "../components/recipes/RecipesContainer";
import CreateRecommendationsModal from "../components/recommendations/CreateRecommendationsModal";
import RecommendationsContainer from "../components/recommendations/RecommendationsContainer";
import AdvancedSearchFormContainer from "../components/search/AdvancedSearchFormContainer";
import SignInFormContainer from "../components/signIn/SignInFormContainer";
import SubscriptionContainer from "../components/subscriptions/SubscriptionContainer";
import TagContainer from "../components/tags/TagContainer";
import TrailContainer from "../components/trails/TrailContainer";
import {
  canAcessMainSubscription,
  canAcessSubscription,
} from "../selectors/subscriptionSelectors";
import { restrictAccess } from "./helpers";

export default function LmsRoutes(props) {
  return (
    <LMS {...props}>
      <Switch>
        <Route path="/" component={HomeContainer} exact />
        <Route path="/signin" component={SignInFormContainer} exact />
        <Route path="/trails/:id/" component={TrailContainer} exact />
        <Route
          path="/subscriptions/:id"
          component={restrictAccess(
            SubscriptionContainer,
            canAcessSubscription
          )}
          exact
        />
        <Route
          path="/subscriptions/:subscriptionId/classrooms/:id/"
          component={restrictAccess(
            SubscriptionContainer,
            canAcessSubscription
          )}
          exact
        />
        <Route path="/tags/:tagId/" component={TagContainer} exact />
        <Route
          path="/subscriptions/:subscriptionId/classrooms/:classroomId/lessons/:id"
          component={restrictAccess(LessonContainer, canAcessSubscription)}
          exact
        />
        <Route
          path="/calculators"
          component={restrictAccess(
            CalculatorFormContainer,
            canAcessMainSubscription
          )}
          exact
        />
        <Route
          path="/profile/edit"
          component={ProfileEditFormContainer}
          exact
        />
        <Route
          path="/certificates"
          component={CertificatesListContainer}
          exact
        />
        <Route
          path="/search/:nameUnaccent"
          component={AdvancedSearchFormContainer}
          exact
        />
        <Route
          path="/recommendations"
          component={RecommendationsContainer}
          exact
        />
        <Route
          path="/recommendations/new"
          component={CreateRecommendationsModal}
          exact
        />
        <Route
          path="/recipes"
          component={RecipesContainer}
          exact
        />
      </Switch>
      <Switch>
        <Route
          path="/subscriptions/:subscriptionId/classrooms/:id/"
          component={restrictAccess(ClassroomModal, canAcessSubscription)}
          exact
        />
      </Switch>
    </LMS>
  );
}
