import React from "react";
import { TextField } from "../../library";

export default function CircleDiameterField({
  diameterFieldName = "diameter",
  diameterLabel = "Diâmetro (cm)",
}) {
  return (
    <TextField
      name={diameterFieldName}
      label={diameterLabel}
      placeholder="XX"
      shortMessages
      required
      type="number"
      errorMessageDisabled
      step="0.1"
      fluid
    />
  );
}
