import React from "react";
import { css } from "glamor";
import { spacing } from "./theme";
import { BsPlus, BsPlayFill, BsLockFill } from "react-icons/bs";

const iconWrapper = css({
  display: "flex",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: spacing.s,
  color: "white",
  fontWeight: 510,
});
const iconClass = css({
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  height: 52,
  width: 52,
  background: "white",
  borderRadius: "50%",
});

export default function IconMore({ icon, iconColor = "red", label }) {
  let Icon;

  switch (icon) {
    case "play":
      Icon = BsPlayFill;
      break;
    case "lock":
      Icon = BsLockFill;
      break;
    default:
      Icon = BsPlus;
  }

  return (
    <div className={iconWrapper}>
      <div className={iconClass}>
        <Icon color={iconColor} size={36} />
      </div>
      {label}
    </div>
  );
}
