const getSubscriptions = (action) => action.entities.subscriptions;

export default function subscriptionsReducer(state = {}, action) {
  switch (action.type) {
    case "SUBSCRIPTION_FETCHED":
    case "SUBSCRIPTIONS_FETCHED":
      return { ...state, ...getSubscriptions(action) };
    default:
      return state;
  }
}
