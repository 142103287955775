import { css } from "glamor";
import React from "react";
import ClassroomsEmpty from "../classrooms/ClassroomsEmpty";
import TagsEmpty from "../tags/TagsEmpty";
import Spinner from "../library/Spinner";
import { spacing, styles, typographyColors } from "../library/theme";
import Title from "../library/Title";
import TagListItem from "./TagListItem";
import TagClassroomListItem from "./TagClassroomListItem";

const container = css({
  padding: spacing.m,
  paddingTop: spacing.xl,
});
const titleClass = css({
  textTransform: "uppercase",
  fontWeight: 510,
  margin: `0 0 ${spacing.l} 0`,
});
const upTitle = css({
  margin: 0,
  color: typographyColors.subtitle,
  fontWeight: 510,
});
const classroomsWrapper = css({
  display: "grid",
  gridTemplateColumns: "repeat(auto-fit, minmax(400px, 1fr))",
  gridGap: "1rem",
});
const tagsWrapper = css(styles.gridHorizontalScroll);

export default function Tag(props) {
  const { tag, tags, classrooms, loading } = props;
  const bannerStyle = css({
    background: `linear-gradient(to bottom, white 10%, transparent 40%, transparent 60%, white 100%), url('${tag?.bannerUrl}')`,
    backgroundSize: "cover",
    minHeight: "540px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  });

  return loading ? (
    <Spinner text="Carregando..." />
  ) : (
    <div>
      <div className={bannerStyle.toString()}></div>
      <div className={container}>
        <Title as="p" strong className={upTitle}>
          CATEGORIA
        </Title>
        <Title as="h2" strong className={titleClass}>
          {tag?.label}
        </Title>
        <Title as="p" strong>
          Todos os cursos ({+classrooms?.length})
        </Title>
        <div className={classroomsWrapper}>
          {classrooms.length ? (
            classrooms.map((classroom) => (
              <TagClassroomListItem key={classroom.id} classroom={classroom} />
            ))
          ) : (
            <ClassroomsEmpty title="Essa categoria não possuí nenhum curso no momento" />
          )}
        </div>
        <Title strong>Outras categorias</Title>
        <div className={tagsWrapper}>
          {!!tags.length ? (
            tags.map((tag) => <TagListItem key={tag.id} tag={tag} />)
          ) : (
            <TagsEmpty />
          )}
        </div>
      </div>
    </div>
  );
}
