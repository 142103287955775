import React from "react";
import {
  Button,
  Link,
  Spinner,
  css,
  spacing,
  typographyColors,
} from "../library";
import { colors, uiColors } from "../library/theme";
import EmptyPreview from "../library/EmptyPreview";

const TABLE_CELL_PADDING = 5;

const tableContainer = css({
  display: "grid",
  overflowX: "auto",
  overflowY: "hidden",
  whiteSpace: "nowrap",
  "@media(max-width: 768px)": {
    gridTemplateColumns: "768px",
  },
});
const linkWrapper = {
  textAlign: "right",
};
const linkClass = css({
  color: `${uiColors.theme} !important`,
  border: `1px solid ${uiColors.theme} !important`,
  borderRadius: "7px !important",
  backgroundColor: "transparent !important",
  marginRight: "0 !important",
  "&:hover": {
    filter: "contrast(1.8)",
  },
});
const tableClass = css({
  tableLayout: "fixed",
  width: "100%",
  borderCollapse: "collapse",
  borderRadius: 10,
});
const tableRowClass = css({
  "> td > span": {
    marginLeft: TABLE_CELL_PADDING,
  },
  "> th": {
    padding: `${TABLE_CELL_PADDING + 5}px ${TABLE_CELL_PADDING}px`,
    textAlign: "left",
    backgroundColor: colors.greyLightest,
    ":first-child": {
      borderTopLeftRadius: `${spacing.xs} !important`,
      borderBottomLeftRadius: `${spacing.xs} !important`,
      width: "20%",
    },
    ":last-child": {
      borderTopRightRadius: `${spacing.xs} !important`,
      borderBottomRightRadius: `${spacing.xs} !important`,
      width: "20%",
    },
    ":nth-last-child(2)": {
      width: "20%",
    },
  },
  "> td": {
    padding: TABLE_CELL_PADDING,
    textAlign: "left",
    borderBottom: `1px solid ${uiColors.border} !important`,
    ":last-child": {
      paddingRight: 0,
    },
  },
});
const ellipsis = css({
  position: "relative",
  "> span": {
    position: "absolute",
    left: 0,
    right: 0,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  ":before": {
    content: "&nbsp;",
    visibility: "hidden",
  },
});

export function RecipesList({ recipes, loading }) {
  return (
    <div className={tableContainer}>
      {loading ? (
        <Spinner text="Buscando receitas..." wrapper />
      ) : (
        <>
          {recipes.length > 0 ? (
            <table className={tableClass}>
              <thead>
                <tr className={tableRowClass}>
                  <th>Tema</th>
                  <th>Receita</th>
                  <th>Curso</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {recipes.map((recipe) => (
                  <tr className={tableRowClass} key={recipe.id}>
                    <td className={ellipsis}>
                      <span>{recipe.themeName}</span>
                    </td>
                    <td className={ellipsis}>
                      <span>{recipe.name}</span>
                    </td>
                    <td className={ellipsis}>
                      <span>{recipe.courseName}</span>
                    </td>

                    <td>
                      <div style={linkWrapper}>
                        <Link
                          component={Button}
                          className={linkClass}
                          href={recipe.url}
                          target="_blank"
                        >
                          Abrir receita
                        </Link>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <EmptyPreview description="Receitas não encontradas." />
          )}
        </>
      )}
    </div>
  );
}
