import { getInput, getMoldFactor, round } from "../utils/calculateHelpers";

const ORIGINAL_DIAMETER = 21;
const BASE_BATTER = 1100;

export default function calculate({
  calculator,
  calculatorFormValues,
  custom,
}) {
  const { shape, height, width, diameter, layerFactor, fillingId } =
    calculatorFormValues;

  const moldFactor = getMoldFactor({
    originalDiameter: ORIGINAL_DIAMETER,
    shape,
    height,
    width,
    diameter,
  });
  const suppliesWithSelectedFilling = calculator.meta.supplies.filter(
    (supply) => !supply.category || supply.id == fillingId
  );

  const decreasingFactor = getInput(calculator, "decreasing") || 0;
  const originalYield = BASE_BATTER * (custom ? 1 : 1 - decreasingFactor);

  const inputYield = getInput(calculator, "yield");

  const quantitySum =
    inputYield ||
    suppliesWithSelectedFilling.reduce((acc, current) => {
      return acc + +current.quantity;
    }, 0);

  const yieldFactor = originalYield / quantitySum;

  const circularFactor = moldFactor * layerFactor * yieldFactor;
  const retangularFactor = moldFactor * layerFactor;

  const factor = shape == "retangular" ? retangularFactor : circularFactor;

  const supplyList = suppliesWithSelectedFilling.map(
    ({ quantity, ...attrs }) => {
      return { quantity: round(quantity * factor), ...attrs };
    }
  );

  return {
    title: calculator.name,
    imageUrl: calculator.imageUrl,
    ctaLabel: calculator.ctaLabel,
    ctaLink: calculator.ctaLink,
    supplies: supplyList,
    factor,
  };
}
