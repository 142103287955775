import { css } from "glamor";
import React from "react";
import { Grid } from "semantic-ui-react";
import makeDropDownOptions from "../../utils/makeDropDownOptions";
import Button from "../library/Button";
import Link from "../library/Link";
import DropdownField from "../library/forms/DropdownField";
import Form from "../library/forms/Form";
import Label from "../library/forms/Label";
import RadioField from "../library/forms/RadioField";
import { spacing } from "../library/theme";

const container = css({
  marginTop: spacing.xl,
  marginBottom: spacing.xl,
  marginLeft: 14,
  "> p": {
    marginBottom: "0 !important",
  },
});
const rowClass = css({
  display: "flex",
  gap: spacing.m,
});
const radioFieldsWrapper = css({
  display: "flex",
  gap: spacing.s,
});
const fieldWrapper = css({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});
const labelClass = css({
  marginBottom: spacing.xxs,
});
const buttonStyle = {
  display: "flex",
  gap: spacing.m,
  marginTop: spacing.m,
  alignItems: "center",
  justifyContent: "flex-end",
};
const linkClass = css({
  ".ui.basic.button": {
    color: "black !important",
    backgroundColor: "white !important",
  },
});

const supplyCategoriesOptions = [
  { text: "Todas", value: " " },
  { text: "Ingrediente", value: "ingredient" },
  { text: "Marca", value: "brand" },
  { text: "Embalagem", value: "packaging" },
  { text: "Utensílio", value: "utensil" },
  { text: "Equipamento", value: "equipment" },
  { text: "Local", value: "location" },
];

export function RecommendationsFilterForm({
  handleSubmit,
  submitting,
  pristine,
  invalid,
  states,
  isSupplier,
  isCoupon,
}) {
  return (
    <div className={container}>
      <Form onSubmit={handleSubmit}>
        <Grid>
          <Grid.Row className={rowClass.toString()}>
            <RadioField name="typeEq" value="0" label="Lojas / Fornecedores" />
            <RadioField name="typeEq" value="1" label="Confeitaria" />
            <RadioField name="typeEq" value="2" label="Cupom de Desconto" />
          </Grid.Row>
          <Grid.Row className={rowClass.toString()}>
            {isSupplier && (
              <div className={fieldWrapper}>
                <Label className={labelClass} strong>
                  Selecione o tipo
                </Label>
                <div className={radioFieldsWrapper}>
                  <RadioField
                    name="locationTypeEq"
                    value="online"
                    label="Online"
                  />
                  <RadioField
                    name="locationTypeEq"
                    value="physical"
                    label="Física"
                  />
                </div>
              </div>
            )}

            <div className={fieldWrapper}>
              <Label className={labelClass} strong>
                Selecione o estado
              </Label>
              <DropdownField
                name="stateIdEq"
                options={makeDropDownOptions(states, "Todo o Brasil")}
                placeholder="Selecione uma opção"
                noResultsMessage="Estado não encontrado."
                search
              />
            </div>

            {isSupplier && (
              <div className={fieldWrapper}>
                <Label className={labelClass} strong>
                  Categoria
                </Label>
                <DropdownField
                  name="categoryEq"
                  options={supplyCategoriesOptions}
                  placeholder="Selecione uma opção"
                  noResultsMessage="Categoria não encontrada."
                  search
                />
              </div>
            )}

            <Button
              style={buttonStyle}
              circular={false}
              color="red"
              loading={submitting}
              disabled={submitting || pristine || invalid}
            >
              Buscar
            </Button>

            {!isCoupon && (
              <Link
                to="/recommendations/new"
                className={linkClass.toString()}
                style={buttonStyle}
                component={Button}
                basic
                size="large"
                type="button"
              >
                Fazer uma indicação
              </Link>
            )}
          </Grid.Row>
        </Grid>
      </Form>
    </div>
  );
}
