import React from "react";
import { connect } from "react-redux";
import ClassroomListItem from "./ClassroomListItem";
import { listOfLessons } from "../../selectors/lessonSelectors";

function ClassroomListItemContainer(props) {
  const { subscriptionId } = props;
  return <ClassroomListItem subscriptionId={subscriptionId} {...props} />;
}

function mapStateToProps(state, ownProps) {
  const classroom = ownProps?.classroom;

  return {
    classroom,
    lessonsCount: listOfLessons(state, classroom?.id)?.length,
  };
}

export default connect(mapStateToProps)(ClassroomListItemContainer);
