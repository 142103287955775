import React, { useEffect } from "react";
import { fetchLessons } from "../../processes/lessonProcesses";
import { connect } from "react-redux";
import LessonList from "./LessonList";
import { listOfLessons } from "../../selectors/lessonSelectors";

function LessonListContainer(props) {
  const { dispatch, classroom = { id: null }, subscriptionId } = props;

  useEffect(() => {
    if (classroom.id) {
      fetchLessons(dispatch, classroom.id);
    }
  }, [dispatch, classroom.id]);

  return <LessonList subscriptionId={subscriptionId} {...props} />;
}

function mapStateToProps(state, ownProps) {
  const { classroom } = ownProps;

  return {
    lessons: classroom
      ? listOfLessons(state, classroom.id)
      : listOfLessons(state),
  };
}

export default connect(mapStateToProps)(LessonListContainer);
