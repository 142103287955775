import { connect } from "react-redux";
import buildForm from "../library/forms/buildForm";
import ReplyForm from "./ReplyForm";
import { createReply } from "../../processes/replyProcesses";
import { getCurrentUser } from "../../selectors/userSelectors";

const form = buildForm("replyForm");

function mapStateToProps(state) {
  const currentUser = getCurrentUser(state);
  return {
    initialValues: {},
    currentUserName: currentUser?.name,
    currentUserAvatarUrl: currentUser?.avatarUrl,
    currentClassroomId: state.currentClassroomId,
  };
}

export default connect(mapStateToProps)(
  form.connect({
    onSubmit: createReply,
  })(ReplyForm)
);
