import React from "react";
import { css } from "glamor";
import truncateString from "../../utils/truncateString";
import { spacing } from "../library/theme";
import Card from "../library/Card";

const columnStyle = css({
  gridRow: "1",
  gridColumn: "auto",
  position: "relative",
});
const titleClass = css({
  paddingTop: 0,
  marginTop: spacing.xs,
  fontWeight: "bold",
});

export default function TagListItem({ tag }) {
  const { label, id, imageUrl } = tag;

  return (
    <div className={columnStyle}>
      <Card
        to={`/tags/${id}`}
        imageUrl={imageUrl}
        icon="info"
        label="Mais info"
      />
      <h4 className={titleClass}>{truncateString(label, 60)}</h4>
    </div>
  );
}
