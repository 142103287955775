import React from "react";
import { Grid } from "../../library";
import {
  CakeTypeField,
  CircleDiameterField,
  LayerFactorField,
  ShapeField,
  RetangularDimensionsFields,
} from "../utils";

const cakeOptions = [
  { text: "Coberto com cinta", value: "covered", key: "covered" },
  { text: "Seminaked com cinta", value: "seminaked", key: "seminaked" },
];

const layerOptions = [
  { text: "3 massas e 2 recheios", value: 1, key: "3" },
  { text: "4 massas e 3 recheios", value: 1.33, key: "4" },
  { text: "5 massas e 4 recheios", value: 1.66, key: "5" },
  { text: "6 massas e 5 recheios", value: 2, key: "6" },
  { text: "7 massas e 6 recheios", value: 2.33, key: "7" },
];
export default function GanacheCalculatorFields({ shape }) {
  return (
    <>
      <Grid.Column>
        <ShapeField />
      </Grid.Column>

      <Grid.Column>
        <LayerFactorField options={layerOptions} />
      </Grid.Column>

      <Grid.Column>
        <CakeTypeField options={cakeOptions} />
      </Grid.Column>

      {shape == "circular" && (
        <Grid.Column>
          <CircleDiameterField />
        </Grid.Column>
      )}

      {shape == "retangular" && (
        <Grid.Column>
          <RetangularDimensionsFields />
        </Grid.Column>
      )}
    </>
  );
}
