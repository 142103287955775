import React, { useEffect } from "react";
import { connect } from "react-redux";
import Classrooms from "../classrooms/Classrooms";
import { listOfClassroomsByCurrentTrail } from "../../selectors/classroomSelectors";
import { fetchClassrooms } from "../../processes/classroomProcesses";
import { getQueryParam } from "../../processes/helpers/getQueryParam";
import { getTagById } from "../../selectors/configSelectors";

function TrailContainer(props) {
  const { dispatch, tag } = props;
  const params = { tagsIdIn: tag?.id };

  useEffect(() => {
    fetchClassrooms(dispatch, params);
  }, [dispatch, tag]);

  return <Classrooms title="Sua Trilha" {...props} />;
}

function mapStateToProps(state, ownProps) {
  const { id } = ownProps.match.params;
  const tagId = getQueryParam("tagId");
  return {
    classrooms: listOfClassroomsByCurrentTrail(state, id),
    loading: state.classroomList.loading,
    tag: getTagById(state, tagId),
  };
}

export default connect(mapStateToProps)(TrailContainer);
