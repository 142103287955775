import React from "react";
import { hasClassroomCertificate } from "../../selectors/classroomSelectors";
import {
  getAllCurrentUserSubscriptions,
  hasSubscriptionCertificate,
  getCurrentUserClassrooms,
} from "../../selectors/subscriptionSelectors";
import CertificatesList from "./CertificatesList";
import { useLoadCertificates } from "./useLoadCertificates";
import { connect } from "react-redux";

function CertificatesListContainer(props) {
  const { loading } = useLoadCertificates();

  return <CertificatesList loading={loading} {...props} />;
}

function mapStateToProps(state) {
  return {
    classrooms: getCurrentUserClassrooms(state),
    subscriptions: getAllCurrentUserSubscriptions(state),
    hasClassroomCertificate: hasClassroomCertificate(state),
    hasSubscriptionCertificate: hasSubscriptionCertificate(state),
  };
}

export default connect(mapStateToProps)(CertificatesListContainer);
