import React from "react";
import {
  Container,
  Link,
  typographyColors,
  spacing,
  uiColors,
  css,
} from "../../library";

const container = {
  marginTop: spacing.xl,
  display: "flex",
  gap: spacing.m,
  flexWrap: "wrap",
  padding: spacing.m,
  background: uiColors.fieldBackground,
  borderRadius: spacing.m,
};
const leftContent = css({ minWidth: 275, flex: 1 });
const imageWrapper = css({
  position: "relative",
  paddingBottom: "56.25%",
  height: 0,
});
const imageClass = css({
  position: "absolute",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  border: "none",
  borderRadius: spacing.m,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center center",
});
const rightContent = css({
  minWidth: 275,
  flex: 1,
  display: "flex",
  flexDirection: "column",
});
const titleClass = css({
  color: typographyColors.defaultTitle,
  fontWeight: "bold",
});
const subtitleClass = css({
  marginBottom: `0px 0px ${spacing.s} 0px`,
  fontWeight: 500,
});
const suppliesWrapper = css({
  flex: 1,
  fontSize: 16,
});
const supplyClass = css({
  display: "inline-block",
  width: "50%",
  marginBottom: spacing.s,
  "@media(max-width: 768px)": {
    width: "100%",
  },
});
const ctaWrapper = css({
  marginTop: spacing.l,
  borderTop: `1px solid ${uiColors.border}`,
  paddingTop: spacing.m,
});
const ctaLabelClass = css({
  fontWeight: "bold",
  cursor: "pointer",
});

export default function FillingCalculatorResult({ result }) {
  return result ? (
    <Container style={container}>
      <div className={leftContent}>
        <div className={imageWrapper}>
          <div
            className={css(imageClass, {
              backgroundImage: `url(${result.imageUrl})`,
            })}
          ></div>
        </div>
      </div>
      <div className={rightContent}>
        <h3 className={titleClass}>{result.title}</h3>
        <div className={suppliesWrapper}>
          {result?.supplies?.map((supply) => (
            <span className={supplyClass}>
              {supply.name}: {supply.quantity}
              {supply.quantitySuffix}
            </span>
          ))}
        </div>
        <h3 className={titleClass}>{result.extraTitle}</h3>
        <div className={suppliesWrapper}>
          {result?.extraSupplies?.map((supply) => (
            <span className={supplyClass}>
              {supply.name}: {supply.quantity}
              {supply.quantitySuffix}
            </span>
          ))}
        </div>
        <div className={ctaWrapper}>
          <Link
            href={result.ctaLink}
            component="p"
            target="_blank"
            className={ctaLabelClass}
          >
            {result?.ctaLabel || result.ctaLink}
          </Link>
          {result?.extraCtaLink && (
            <Link
              href={result?.extraCtaLink}
              component="p"
              target="_blank"
              className={ctaLabelClass}
            >
              {result?.extraCtaLabel || result?.extraCtaLink}
            </Link>
          )}
        </div>
      </div>
    </Container>
  ) : null;
}
