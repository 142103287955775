import React from "react";
import { RiBookOpenLine, RiMedalLine } from "react-icons/ri";
import { Icon } from "semantic-ui-react";
import makeDropDownOptions from "../../utils/makeDropDownOptions";
import {
  DropdownField,
  Form,
  Label,
  Link,
  Pagination,
  TextField,
  css,
  spacing,
  typographyColors,
} from "../library";
import Page from "../library/Page";
import { colors, styles, uiColors } from "../library/theme";
import RecipesListContainer from "./RecipesListContainer";

const container = css({});
const fieldsContainer = css({
  display: "flex",
  gap: spacing.m,
  backgroundColor: "white",
  borderRadius: 10,
  padding: spacing.m,
  "@media(max-width: 768px)": {
    flexDirection: "column",
    textAlign: "center",
  },
}).toString();
const fieldWrapper = css({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  flex: 1,
});
const labelClass = css({
  color: typographyColors.defaultTitle,
  marginBottom: spacing.xxs,
});
const cleanFilterWrapper = css({
  marginTop: spacing.l,
  "@media(max-width: 768px)": {
    marginTop: 0,
  },
});
const cleanFilterClass = css({
  margin: `0 ${spacing.l}`,
  color: uiColors.theme,
  cursor: "pointer",
  ":hover": {
    color: uiColors.theme,
    filter: "contrast(1.8)",
  },
});

const paginationContainer = css({
  display: "flex",
  justifyContent: "space-between",
  marginTop: spacing.m,
  flexWrap: "wrap",
  "@media(max-width: 1024px)": {
    flexWrap: "nowrap",
    flexDirection: "column",
    alignItems: "center",
    gap: spacing.s,
  },
});
const paginationWrapper = css({
  "@media(max-width: 768px)": {
    transform: "scale(0.7)",
  },
});
const rowsPerPageSelectWrapper = css({
  display: "flex",
  alignItems: "center",
  gap: spacing.s,
  flex: 1,
});
const flexFillStyle = { flex: 1 };
const recipesListContainer = css({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  minHeight: 630,
  marginTop: spacing.m,
  backgroundColor: "white",
  padding: spacing.m,
  borderRadius: 10,
});
const rowsPerPageLabelClass = css({
  marginBottom: 0,
  fontWeight: "bold",
  color: "black",
});

const rowsPerPageOptions = [
  { key: 1, text: "10 linhas", value: 10 },
  { key: 2, text: "20 linhas", value: 20 },
  { key: 3, text: "50 linhas", value: 50 },
  { key: 4, text: "100 linhas", value: 100 },
];

export function Recipes({
  currentPage,
  totalPages,
  onPageChangeHandler,
  handleSubmit,
  cleanFilterHandler,
  themes,
}) {
  const themeOptions = makeDropDownOptions(themes, "Todos");

  return (
    <Page title="Índice de Receitas">
      <div className={container}>
        <Form onSubmit={handleSubmit}>
          <div className={fieldsContainer}>
            <div className={fieldWrapper}>
              <Label className={labelClass} strong>
                Tema <Icon name="food" />
              </Label>
              <DropdownField
                name="themeIdEq"
                options={themeOptions}
                noResultsMessage="Tema não encontrado."
                search
                fluid
              />
            </div>

            <div className={fieldWrapper}>
              <Label className={labelClass} strong>
                Receita/Aula <RiBookOpenLine />
              </Label>
              <TextField
                name="nameCont"
                type="text"
                icon="search"
                iconPosition="left"
                placeholder="Pesquise uma receita ou aula"
                fluid
              />
            </div>

            <div className={fieldWrapper}>
              <Label className={labelClass} strong>
                Curso <RiMedalLine />
              </Label>
              <TextField
                name="courseNameCont"
                type="text"
                icon="search"
                iconPosition="left"
                placeholder="Pesquise um curso"
                fluid
              />
            </div>

            <div className={cleanFilterWrapper}>
              <Link className={cleanFilterClass} onClick={cleanFilterHandler}>
                Limpar filtros
              </Link>
            </div>
          </div>

          <div className={recipesListContainer}>
            <RecipesListContainer />

            <div className={paginationContainer}>
              <div className={rowsPerPageSelectWrapper}>
                <p className={rowsPerPageLabelClass}>Visualizar</p>
                <DropdownField
                  name="rowsPerPage"
                  options={rowsPerPageOptions}
                  fluid
                />
              </div>

              <Pagination
                wrapperClass={paginationWrapper}
                activePage={currentPage}
                totalPages={totalPages}
                onPageChange={onPageChangeHandler}
              />

              <div style={flexFillStyle}></div>
            </div>
          </div>
        </Form>
      </div>
    </Page>
  );
}
