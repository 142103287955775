import React from "react";
import { css } from "glamor";
import { Image } from "semantic-ui-react";
import LogoA from "./logo-a.png";
import { spacing, typographyColors } from "../library/theme";

const footerStyle = css({
  width: "100%",
  maxHeight: 120,
  borderTop: `1px solid ${typographyColors.inverseBody}`,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: spacing.m,
  "@media(max-width: 1024px)": {
    display: "none !important",
  },
  "> *:not(:last-child)": {
    marginBottom: spacing.s,
  },
}).toString();

const linkStyle = css({
  "> *": {
    color: "inherit",
    textDecoration: "underline",
    fontWeight: 400,
    "&:hover": {
      color: "inherit",
      textDecoration: "underline",
    },
  },
}).toString();

const textStyle = css({
  fontWeight: 400,
}).toString();

export default function Footer() {
  return (
    <div className={footerStyle}>
      <div>
        <Image src={LogoA} />
      </div>
      <div className={textStyle}>
        Todos os direitos reservados 2023 © | Addolcire Cnpj 20.266.277/0001-78
      </div>
      <div className={linkStyle}>
        <a
          href="https://www.addolcire.com.br/politica-de-privacidade"
          target="_blank"
        >
          Política de Privacidade
        </a>{" "}
        <a href="https://www.addolcire.com.br/termos-de-uso" target="_blank">
          Termos de Uso
        </a>
      </div>
    </div>
  );
}
