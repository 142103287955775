import { createSelector } from "reselect";
import orderByPosition from "../utils/orderByPosition";
import { getAllClassrooms, getClassrooms } from "./classroomSelectors";
import {
  getCurrentUser,
  getCurrentUserSubscriptionIds,
  getCurrentUserValidSubscriptionIds,
} from "./userSelectors";
import { statusString } from "../utils/statusString";

export const getSubscriptions = (state) => state.entities.subscriptions;
export const getId = (_, id) => id;
const getSubscriptionIdFromRoute = (state) =>
  parseInt(state?.router?.location?.pathname?.split("/")?.[2]);

export const getAllSubscriptions = createSelector(
  getSubscriptions,
  getAllClassrooms,
  (subscriptions, classrooms) =>
    Object.values(subscriptions)
      .map((subscription) => ({
        ...subscription,
        classrooms: classrooms.filter((classroom) =>
          subscription?.classroomIds?.includes(classroom.id)
        ),
      }))
      .sort((a, b) => b.position - a.position)
);

export const getAllCurrentUserSubscriptions = createSelector(
  getAllSubscriptions,
  getCurrentUserSubscriptionIds,
  (subscriptions, ids) =>
    subscriptions.filter((subscription) => ids.includes(subscription.id))
);

export const getAllCurrentUserValidSubscriptions = createSelector(
  getAllSubscriptions,
  getCurrentUserValidSubscriptionIds,
  (subscriptions, ids) =>
    subscriptions.filter((subscription) => ids.includes(subscription.id))
);

export const hasMainSubscriptions = createSelector(
  getAllCurrentUserValidSubscriptions,
  (subscriptions) =>
    !!subscriptions.filter((subscription) => subscription.main).length
);

export const getSubscriptionById = createSelector(
  getSubscriptions,
  getId,
  (subscriptions, subscriptionId) => subscriptions[subscriptionId]
);

export const hasSubscriptionCertificate = createSelector(
  getAllCurrentUserSubscriptions,
  (subscriptions) =>
    subscriptions.some((subscription) =>
      subscription.classrooms.every(
        (classroom) => classroom.meta.progress >= 0.8
      )
    )
);

export const getClassroomsBySubscription = createSelector(
  getClassrooms,
  getSubscriptionById,
  (classrooms, subscription) => {
    return subscription?.classroomIds
      ?.map((classroomId) => classrooms[classroomId])
      .filter((item) => !!item)
      .sort(orderByPosition);
  }
);

export const getAllSubscriptionsClassroomsIds = createSelector(
  getAllSubscriptions,
  getCurrentUserSubscriptionIds,
  (subscriptions, ids) => [
    ...new Set(
      subscriptions
        .filter((subscription) => ids.includes(subscription.id))
        .map((subscription) => subscription.classroomIds)
        .flat()
    ),
  ]
);

export const getCurrentUserClassrooms = createSelector(
  getAllClassrooms,
  getAllSubscriptionsClassroomsIds,
  (classrooms, ids) =>
    classrooms?.filter((classroom) => ids?.includes(classroom?.id))
);

export const getAllLockedClassroomsIds = createSelector(
  getAllSubscriptions,
  getCurrentUserSubscriptionIds,
  (subscriptions, ids) => [
    ...new Set(
      subscriptions
        .filter(
          (subscription) => subscription.main && !ids.includes(subscription.id)
        )
        .map((subscription) => subscription.classroomIds)
        .flat()
    ),
  ]
);

export const listOfLockedClassrooms = createSelector(
  getAllClassrooms,
  getAllLockedClassroomsIds,
  (classrooms, lockedClassroomsIds) =>
    classrooms.filter(
      (classroom) =>
        lockedClassroomsIds.includes(classroom.id) && classroom.linkUrl
    )
);

export const hasLockedClassroom = createSelector(
  listOfLockedClassrooms,
  (lockedClassrooms) => lockedClassrooms.length > 0
);

export const hasEscolaAddolcire = createSelector(
  getAllCurrentUserSubscriptions,
  (subscriptions) =>
    subscriptions.some(
      (subscription) => subscription.name.toLowerCase() == "escola addolcire"
    )
);

export const makeIsExpired = createSelector(
  getCurrentUser,
  getSubscriptionById,
  (user, subscription) => {
    if (user?.isAdmin) {
      return;
    }

    return user?.expiredSubscriptionIds.some(
      (subscriptionId) => subscriptionId == subscription?.id
    );
  }
);

export const hasSubscription = createSelector(
  getAllCurrentUserValidSubscriptions,
  getId,
  (subscriptions, id) => subscriptions.map(({ id }) => id).includes(id)
);

export const getMainSubscriptions = createSelector(
  getAllSubscriptions,
  (subscriptions) => subscriptions.filter(({ main }) => main)
);

export const getHomeSubscription = createSelector(
  getSubscriptions,
  getAllCurrentUserValidSubscriptions,
  getAllSubscriptions,
  (subscriptions, userSubscriptions, allSubscriptions) => {
    const sidebarSubscriptionIds = new Set(
      [...userSubscriptions, ...allSubscriptions].map(({ id }) => id)
    );

    return [...sidebarSubscriptionIds]
      .map((id) => subscriptions[id])
      .filter(({ id }) => id);
  }
);

export const getSidebarSubscriptions = createSelector(
  getSubscriptions,
  getAllCurrentUserValidSubscriptions,
  getMainSubscriptions,
  (subscriptions, allSubscriptions, mainSubscriptions) => {
    const sidebarSubscriptionIds = new Set(
      [...mainSubscriptions, ...allSubscriptions].map(({ id }) => id)
    );

    return [...sidebarSubscriptionIds]
      .map((id) => subscriptions[id])
      .filter(({ id }) => id);
  }
);

export const canAcessSubscription = createSelector(
  getCurrentUser,
  getAllSubscriptions,
  getCurrentUserValidSubscriptionIds,
  getSubscriptionIdFromRoute,
  (user, subscriptions, userSubscriptions, id) => {
    if (!subscriptions || !user || !getSubscriptionIdFromRoute) {
      return statusString.loading;
    }
    if (userSubscriptions?.includes(id)) {
      return statusString.allow;
    }
    return statusString.deny;
  }
);

export const canAcessMainSubscription = createSelector(
  getCurrentUser,
  getMainSubscriptions,
  getCurrentUserValidSubscriptionIds,
  (user, subscriptions, userSubscriptions) => {
    const mainIds = subscriptions.map((subscription) => subscription.id);

    if (!subscriptions || !user) {
      return statusString.loading;
    }
    if (
      userSubscriptions.some((subscriptionId) =>
        mainIds.includes(subscriptionId)
      )
    ) {
      return statusString.allow;
    }
    return statusString.deny;
  }
);
