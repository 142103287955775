import Result from "./EmptyCalculatorResult";

const EmptyCalculator = {
  Fields: () => null,
  Result,
  Custom: () => null,
  calculate: () => {},
};

export default EmptyCalculator;
