import React from "react";
import { Field } from "redux-form";
import buildFormField from "./buildFormField";
import SearchForOptionsInput from "./SearchForOptionsInput";

const FormField = buildFormField(SearchForOptionsInput, (input, meta, rest) => {
  return {
    ...input,
    autoComplete: "off",
    ...rest,
    invalid: `${meta.touched && (meta.invalid || rest.invalid)}`,
  };
});

export default function SearchForOptionsField(props) {
  return <Field {...props} component={FormField} />;
}
