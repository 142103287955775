import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Recommendations } from "./Recommendations";
import { getAllRecommendations } from "../../selectors/recommendationSelectors";
import { fetchRecommendations } from "../../processes/recommendationProcesses";

function RecommendationsContainer(props) {
  const { dispatch } = props;

  useEffect(() => {
    fetchRecommendations(dispatch);
  }, [dispatch]);

  return <Recommendations {...props} />;
}

function mapStateToProps(state) {
  return {
    loading: state.recommendationList.loading,
    recommendations: getAllRecommendations(state),
  };
}

export default connect(mapStateToProps)(RecommendationsContainer);
