import React from "react";
import { css } from "glamor";
import Link from "../library/Link";
import { Icon, Button, Label } from "semantic-ui-react";
import { spacing } from "../library/theme";

const iconName = (type) => {
  switch (type) {
    case "PDF":
      return "file pdf outline";
    case "MP3":
      return "file audio outline";
    case "XLS":
    case "XLSX":
      return "file excel outline";
    case "DOC":
      return "file outline";
    case "MIND":
      return "sitemap";
    default:
      return "file pdf outline";
  }
};

const titleAndButton = css({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: spacing.m,
});

export default function AttachmentListItem(props) {
  const { createAcContactTagHandler, attachment, index} = props;
  const fileType = attachment.url.toUpperCase().split(".").slice(-1);

  return (
    <div className={titleAndButton}>
      <span>
        {index + 1}. {attachment.name || `Arquivo ${index + 1}`}
        <br />
        <Label>{fileType || "PDF"}</Label>
      </span>

      <Link
        basic
        floated="right"
        component={Button}
        href={attachment.url}
        color="red"
        key={index}
        target="_blank"
        onClick={createAcContactTagHandler}
      >
        <Icon name={iconName(fileType)} />
        Baixar
      </Link>
    </div>
  );
}
