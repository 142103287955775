import { normalize, schema } from "normalizr";
import { apiPost, apiGet, apiDel } from "./helpers/api";
import { notifySubmitSucceeded } from "./notifierProcesses";

const replySchema = new schema.Entity("replies");
const commentSchema = new schema.Entity("comments", {
  meta: { replies: [replySchema] },
});

export const fetchComments = (dispatch, classroomId, lessonId, page = 1) => {
  dispatch({ type: "COMMENTS_FETCH_REQUESTED" });
  apiGet(
    `/api/v1/classrooms/${classroomId}/lessons/${lessonId}/comments?page=${page}`
  ).then((response) => {
    dispatch({
      type: "COMMENTS_FETCHED",
      ...normalize(response.body.data, new schema.Array(commentSchema)),
      totalPages: response.body.totalPages,
      currentPage: response.body.currentPage,
    });
  });
};

export const deleteComment = (dispatch, classroomId, lessonId, commentId) => {
  apiDel(
    `/api/v1/classrooms/${classroomId}/lessons/${lessonId}/comments/${commentId}`
  ).then(() => {
    dispatch({ type: "COMMENT_DELETED", commentId });
    notifySubmitSucceeded(dispatch, "Seu comentário foi apagado.");
  });
};

export const createComment = (values, dispatch, props) => {
  return apiPost(
    `/api/v1/classrooms/${props.currentClassroomId}/lessons/${props.lessonId}/comments`
  )
    .send({ ...values, classroomId: props.currentClassroomId })
    .then((response) => {
      dispatch({
        type: "COMMENT_CREATED",
        ...normalize({ ...response.body.data, pristine: true }, commentSchema),
      });
      notifySubmitSucceeded(
        dispatch,
        "Seu comentário foi enviado com sucesso! Será exibido após aprovação."
      );
      props.reset();
    });
};
