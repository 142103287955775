import React from "react";
import { Grid } from "../../library";
import {
  CircleDiameterField,
  ShapeField,
  RetangularDimensionsFields,
} from "../utils";

export default function MoldCalculatorFields({ shape }) {
  return (
    <>
      <Grid.Column>
        <ShapeField />
      </Grid.Column>

      {shape == "circular" && (
        <Grid.Column>
          <CircleDiameterField />
        </Grid.Column>
      )}

      {shape == "retangular" && (
        <Grid.Column>
          <RetangularDimensionsFields />
        </Grid.Column>
      )}
    </>
  );
}
