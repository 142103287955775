import React from "react";
import {
  Button,
  Form,
  Grid,
  Page,
  SearchForOptionsField,
  Spinner,
} from "../library";
import BatterCalculator from "./batterCalculator";
import BrigadeiroCalculator from "./brigadeiroCalculator";
import BrownieCalculator from "./brownieCalculator";
import ButtercreamCalculator from "./buttercreamCalculator";
import CakePerGuestCalculator from "./cakePerGuestCalculator";
import CookieCalculator from "./cookieCalculator";
import EmptyCalculator from "./emptyCalculator";
import FillingCalculator from "./fillingCalculator";
import GanacheCalculator from "./ganacheCalculator";
import MoldCalculator from "./moldCalculator";
import SlicesPerGuestCalculator from "./slicesPerGuestCalculator";
import SweetsPerGuestCalculator from "./sweetsPerGuestCalculator";
import { css } from "glamor";
import { spacing } from "../library";

const calculatorComponents = {
  cookie: CookieCalculator,
  brigadeiro: BrigadeiroCalculator,
  sweets: SweetsPerGuestCalculator,
  cake: CakePerGuestCalculator,
  mold: MoldCalculator,
  brownie: BrownieCalculator,
  batter: BatterCalculator,
  slices: SlicesPerGuestCalculator,
  ganache: GanacheCalculator,
  filling: FillingCalculator,
  buttercream: ButtercreamCalculator,
};

const columnsClass = css({
  "> .column": {
    paddingTop: spacing.s,
  },
});
const buttonWrapper = { maxWidth: 118, minWidth: 118, marginTop: "auto" };
const buttonStyle = { marginTop: "auto" };
const formStyle = { paddingBottom: spacing.m };

export default function CalculatorForm(props) {
  const {
    calculator,
    calculators,
    calculatorOptions,
    calculatorFormValues,
    calculateHandler,
    result,
    invalid,
    dirty,
    loading,
    isMobile,
    ...rest
  } = props;
  const Calculator = calculatorComponents[calculator?.slug] || EmptyCalculator;

  return (
    <Page title="Calculadoras" subtitle="Ferramentas úteis :)" contained>
      <Form onSubmit={calculateHandler} style={formStyle}>
        {loading ? (
          <Spinner wrapped />
        ) : (
          <>
            <Grid stackable>
              <Grid.Row>
                <Grid.Column computer={6}>
                  <SearchForOptionsField
                    name="calculatorId"
                    label="Selecione uma calculadora"
                    placeholder="Selecione uma calculadora..."
                    options={calculatorOptions}
                  />
                </Grid.Column>
              </Grid.Row>

              {!!Calculator?.Description && (
                <Grid.Row>
                  <Calculator.Description {...calculatorFormValues} />
                </Grid.Row>
              )}

              <Grid.Row columns={3} className={columnsClass.toString()}>
                <Calculator.Fields
                  calculator={calculator}
                  calculators={calculators}
                  {...calculatorFormValues}
                />

                {dirty && (
                  <Grid.Column floated="right" style={buttonWrapper}>
                    <Button color="red" disabled={invalid} style={buttonStyle}>
                      Calcular
                    </Button>
                  </Grid.Column>
                )}
              </Grid.Row>
            </Grid>

            <Calculator.Result result={result} />

            {calculator && (
              <Calculator.Custom
                calculator={calculator}
                calculate={Calculator.calculate}
              />
            )}
          </>
        )}
      </Form>
    </Page>
  );
}
