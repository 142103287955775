import React from "react";
import { Grid, Card, Progress, Label } from "semantic-ui-react";
import Button from "../library/Button";
import { css } from "glamor";
import { spacing, decorationColors } from "../library/theme";
import Link from "../library/Link";
import truncateString from "../../utils/truncateString";

const progressClass = css({
  marginBottom: `${spacing.xxs} !important`,
  "& .bar": {
    backgroundColor: "#61EA20 !important",
  },
});
const imageContainer = css({
  height: 0,
  paddingBottom: "56.25%",
  backgroundSize: "cover",
  backgroundPosition: "center",
});
const infoWrapperClass = css({
  minHeight: "160px",
});
const descriptionClass = css({
  width: "100%",
  wordWrap: "break-word",
  overflow: "hidden",
  paddingTop: spacing.s,
});
const progressWrapper = css({
  textAlign: "center",
  border: "none !important",
});
const difficultyClass = css({
  paddingBottom: spacing.s,
});
const difficulties = {
  easy: { label: "Básico", color: "green" },
  medium: { label: "Intermediário", color: "yellow" },
  hard: { label: "Avançado", color: "red" },
};
const rateLinkClass = css({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: spacing.xs,
  color: `${decorationColors.decorationBrandDark} !important`,
  fontSize: "14px",
});

export default function TrailListItem({ trail, canRate }) {
  const { name, id, description, meta, imageUrl, difficulty } = trail;
  const progressValue = meta.progress * 100;
  return (
    <Grid.Column computer={4} mobile={16} stretched>
      <Card fluid centered>
        <div
          className={css(imageContainer, {
            backgroundImage: `url(${imageUrl})`,
          })}
        ></div>
        <Card.Content className={infoWrapperClass.toString()}>
          <div className={difficultyClass}>
            {difficulty && (
              <Label color={difficulties[difficulty].color}>
                {difficulties[difficulty].label}
              </Label>
            )}
          </div>
          <Card.Header>{truncateString(name, 60)}</Card.Header>
          <Card.Meta className={descriptionClass.toString()}>
            {truncateString(description, 120)}
          </Card.Meta>
        </Card.Content>
        <Card.Content className={progressWrapper.toString()}>
          <Progress
            className={progressClass.toString()}
            percent={progressValue}
            size="tiny"
          ></Progress>
          <small>{`${progressValue.toFixed(0)}%`}</small>
        </Card.Content>
        <Card.Content extra>
          <Link component={Button} to={`/classrooms/${id}`} color="red" fluid>
            Continuar
          </Link>
          {canRate && (
            <Link className={rateLinkClass} to={`/classrooms/${id}`}>
              Avalie este curso
            </Link>
          )}
        </Card.Content>
      </Card>
    </Grid.Column>
  );
}
