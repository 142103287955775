import React from "react";
import TextField from "../../library/forms/TextField";
import { Button, Icon, uiColors, spacing } from "../../library";
import { css } from "glamor";
import { round } from "../utils/calculateHelpers";

const customSupplyContainer = css({
  flex: 1,
  padding: spacing.m,
  display: "flex",
  justifyContent: "center",
  flexWrap: "wrap",
  gap: spacing.s,
  background: uiColors.contentCards,
  borderRadius: spacing.m,
});
const fieldWrapper = css({
  flex: 1,
  minWidth: 200,
});

export default function SuppliesInput({ fields, calculator }) {
  return fields ? (
    <>
      {fields.map((input, index) => (
        <div key={index} className={customSupplyContainer}>
          <div className={fieldWrapper}>
            <TextField
              name={`${input}.defaultValue`}
              label={calculator?.meta?.inputs[index]?.name}
              placeholder="Seu valor"
              type="number"
              errorMessageDisabled
              step="0.1"
              fluid
            />
          </div>
        </div>
      ))}
    </>
  ) : null;
}
