import React from "react";
import { css } from "glamor";
import Image from "../library/Image";
import Logo from "../library/images/logo.png";
import { spacing } from "../library/theme";
import LobbyBackground from "./bg-header.png";
import Footer from "../footer/Footer";

const container = css({
  minHeight: "100vh",
  display: "flex",
  height: "100vh",
  flexDirection: "column",
  alignItems: "center",
  gap: spacing.l,
});
const content = css({
  flex: 1,
  width: "100%",
  display: "flex",
  justifyContent: "center",
  minWidth: 450,
  margin: "0 !important",
}).toString();
const logoClass = css({
  margin: `${spacing.m} 0`,
});

export default function LobbyLayout({ children }) {
  return (
    <div className={container}>
      <Image src={LobbyBackground} />
      <div className={content}>
        <div style={{ width: 350 }}>
          <Image src={Logo} size="medium" className={logoClass} centered />
          {children}
        </div>
      </div>
      <Footer />
    </div>
  );
}
