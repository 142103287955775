import { normalize, schema } from "normalizr";
import toQueryString from "../utils/toQueryString";
import { apiGet } from "./helpers/api";

const recipeSchema = new schema.Entity("recipes");

export const fetchRecipes = (dispatch, params = {}) => {
  const { page = 1, rowsPerPage = 10, ...rest } = params;
  const filters = rest ? toQueryString(rest) : "";

  dispatch({ type: "RECIPES_FETCH_REQUESTED" });
  apiGet(
    `/api/v1/recipes?${filters}page=${page}&rows_per_page=${rowsPerPage}`
  ).then((response) => {
    const { totalPages = 0, currentPage = 1 } = response.body;

    dispatch({
      type: "RECIPES_FETCHED",
      ...normalize(response.body.data, new schema.Array(recipeSchema)),
      totalPages,
      currentPage,
    });
  });
};

export const submitRecipesFilter = (values, dispatch) => {
  fetchRecipes(dispatch, values);
};
