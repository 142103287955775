import React from "react";
import { TextField } from "../../library";

export default function VolumeField({
  name = "volume",
  label = "Volume (L)",
}) {
  return (
    <TextField
      name={name}
      label={label}
      placeholder="XX"
      shortMessages
      required
      type="number"
      errorMessageDisabled
      step="0.1"
      fluid
    />
  );
}
