import React, { useCallback } from "react";
import { connect, useDispatch } from "react-redux";
import MainMenu from "./MainMenu";
import { getCurrentUser } from "../../selectors/userSelectors";
import { signOut } from "../../processes/sessionProcesses";
import {
  hasMainSubscriptions,
  getSidebarSubscriptions,
} from "../../selectors/subscriptionSelectors";

function MainMenuContainer(props) {
  const dispatch = useDispatch();
  const signOutHandler = useCallback(() => signOut(dispatch), [dispatch]);

  return <MainMenu signOutHandler={signOutHandler} {...props} />;
}

function mapStateToProps(state) {
  return {
    currentClassroomId: state.currentClassroomId,
    currentUser: getCurrentUser(state),
    trailSurveyId: state.entities.configs.trailSurveyId,
    hasMainSubscriptions: hasMainSubscriptions(state),
    subscriptions: getSidebarSubscriptions(state),
  };
}

export default connect(mapStateToProps)(MainMenuContainer);
