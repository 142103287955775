import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { signOut } from "../../processes/sessionProcesses";
import Header from "./Header";
import { matchPath } from "react-router";

function HeaderContainer(props) {
  const { pathName } = props;
  const subscriptionId = parseInt(pathName.split("/")[2]);
  const dispatch = useDispatch();
  const signOutHandler = useCallback(() => signOut(dispatch), [dispatch]);
  const isSubscriptionPath = !!matchPath(pathName, {
    path: "/subscriptions/:id",
    exact: true,
  });

  const [showSearchIcon, setShowSearchIcon] = useState(true);
  const showSearchIconHandler = useCallback(() => {
    setShowSearchIcon(false);
  });

  useEffect(() => {
    if (!isSubscriptionPath) {
      setShowSearchIcon(true);
    }
  }, [isSubscriptionPath]);

  return (
    <Header
      {...props}
      signOutHandler={signOutHandler}
      showSearchIcon={showSearchIcon}
      showSearchIconHandler={showSearchIconHandler}
      isSubscriptionPath={isSubscriptionPath}
      subscriptionId={subscriptionId}
    />
  );
}

function mapStateToProps(state) {
  return {
    currentClassroomId: state.currentClassroomId,
    pathName: state.router.location.pathname,
  };
}

export default connect(mapStateToProps)(HeaderContainer);
