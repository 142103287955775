import React from "react";
import { Title } from "../../library";

const titleStyle = {
  padding: "0 14px",
};

export default function CookieCalculatorDescription() {
  return (
    <Title as="h4" style={titleStyle} strong>
      Para auxiliar na produção de cookies e compra dos ingredientes, calcule
      aqui a sua receita!
    </Title>
  );
}
