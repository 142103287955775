import React from "react";
import { createTextMask } from "redux-form-input-masks";
import { Grid } from "semantic-ui-react";
import makeDropDownOptions from "../../utils/makeDropDownOptions";
import Title from "../library/Title";
import DropdownField from "../library/forms/DropdownField";
import TextAreaField from "../library/forms/TextAreaField";
import TextField from "../library/forms/TextField";
import { spacing } from "../library/theme";

const formTitleStyle = {
  padding: `${spacing.s} 0`,
  marginBottom: spacing.xs,
};
const cepMask = createTextMask({
  pattern: "99999-999",
  guide: false,
  allowEmpty: true,
});

export const CandyStoreFields = ({
  states,
  cities,
  stateId,
  loadingCities,
}) => {
  return (
    <>
      <Title style={formTitleStyle} strong>
        Indicar uma confeitaria
      </Title>

      <Grid stackable>
        <Grid.Row columns={1}>
          <Grid.Column>
            <TextField
              name="name"
              label="Nome da confeitaria"
              placeholder="Digite aqui..."
              required
              fluid
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row columns={3}>
          <Grid.Column width={4}>
            <TextField
              name="addressZipcode"
              label="CEP"
              placeholder="99999-999"
              {...cepMask}
              required
              fluid
            />
          </Grid.Column>

          <Grid.Column width={9}>
            <TextField
              name="address"
              label="Endereço"
              placeholder="Digite seu endereço..."
              required
              fluid
            />
          </Grid.Column>

          <Grid.Column width={3}>
            <TextField
              name="addressNumber"
              label="Número"
              placeholder="999"
              type="number"
              shortMessages
              required
              fluid
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row columns={2}>
          <Grid.Column>
            <DropdownField
              name="stateId"
              label="Estado"
              options={makeDropDownOptions(states)}
              placeholder="Selecione uma opção."
              required
              selection
              search
              fluid
            />
          </Grid.Column>

          <Grid.Column>
            <DropdownField
              label="Cidade"
              name="cityId"
              placeholder="Selecione uma opção."
              options={makeDropDownOptions(cities)}
              required
              loading={loadingCities}
              disabled={!stateId || loadingCities}
              selection
              search
              fluid
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row columns={2}>
          <Grid.Column>
            <TextField
              name="website"
              label="Website"
              placeholder="https://www.exemplo.com.br"
              required
              fluid
            />
          </Grid.Column>

          <Grid.Column>
            <TextField
              name="instagram"
              label="Instagram"
              placeholder="@exemplo"
              required
              fluid
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row columns={1}>
          <Grid.Column>
            <TextAreaField
              rows={3}
              name="reason"
              label="Motivo da indicação"
              placeholder="Digite aqui o que torna esta confeitaria especial."
              required
              fluid
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};
