const initialState = {
  totalPages: 0,
  currentPage: 1,
  loading: false,
};

export default function recipeListReducer(state = initialState, action) {
  switch (action.type) {
    case "RECIPES_FETCH_REQUESTED":
      return { ...state, loading: true };
    case "RECIPES_FETCHED":
      return {
        ...state,
        totalPages: action.totalPages,
        currentPage: action.currentPage,
        loading: false,
      };
    default:
      return state;
  }
}
