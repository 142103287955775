import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "../../selectors/userSelectors";
import { fetchTerms, acceptTerm } from "../../processes/termProcesses";
import { getTerm } from "../../selectors/termSelectors";
import Terms from "./Terms";

export default function TermsContainer({ open, onlyPreview, onClose }) {
  const dispatch = useDispatch();
  const term = useSelector(getTerm);
  const currentUser = useSelector(getCurrentUser);
  const active =
    (term && currentUser && !currentUser.acceptedTerms && !onlyPreview) ||
    (open && term);

  useEffect(() => fetchTerms(dispatch), [dispatch]);

  const onClickHandler = useCallback(() => {
    acceptTerm(dispatch, term.id, currentUser.id);
  }, [dispatch, term, currentUser]);

  return active ? (
    <Terms
      term={term}
      onClickHandler={onClickHandler}
      open={active}
      onClose={onClose}
      onlyPreview={onlyPreview}
    />
  ) : null;
}
