import React from "react";
import LessonListItemContainer from "./LessonListItemContainer";

export default function LessonList(props) {
  const { hideProgress, locked, subscriptionId } = props;
  return props.lessons.map((lesson, index) => (
    <LessonListItemContainer
      key={index}
      index={index}
      lesson={lesson}
      sidebar={props.sidebar}
      hideLeftSidebarHandler={props.hideLeftSidebarHandler}
      hideProgress={hideProgress}
      locked={locked}
      subscriptionId={subscriptionId}
    />
  ));
}
