const supplyCategoriesOptions = [
  { text: "Ingrediente", value: "ingredient" },
  { text: "Marca", value: "brand" },
  { text: "Embalagem", value: "packaging" },
  { text: "Utensílio", value: "utensil" },
  { text: "Equipamento", value: "equipment" },
  { text: "Local", value: "location" },
  { text: "Todas", value: "all_categories" },
];

const locationTypesOptions = [
  { text: "Online", value: "online" },
  { text: "Físico", value: "physical" },
];

export {
  supplyCategoriesOptions,
  locationTypesOptions
}
