import { createSelector } from "reselect";
import { getLevel, getLevels } from "./levelSelectors";
import { getClassroomId } from "./classroomSelectors";
import orderByPosition from "./helpers/orderByPosition";

const getLessons = (state) => state.entities.lessons;
export const getLessonId = (_, id) => id;
export const getCurrentLessonId = (state) => state.currentLessonId;
const getFetchedIds = (state) => state.lessonsList.lessonIds;

export const getCurrentLesson = createSelector(
  getLessons,
  getLessonId,
  (lessons, id) => lessons[id]
);

export const getLessonsByLevel = createSelector(
  getLevel,
  getLessons,
  (level, lessons) =>
    Object.values(lessons)
      .filter((lesson) => lesson.levelId === level.id)
      .sort(orderByPosition)
);

export const getCurrentLevel = createSelector(
  getLevels,
  getLessons,
  getCurrentLessonId,
  (levels, lessons, lessonId) => {
    const currentLesson = lessons[lessonId];
    return levels[currentLesson?.levelId];
  }
);

export const listOfLessons = createSelector(
  getLessons,
  getClassroomId,
  (lessons, classroomId) =>
    Object.values(lessons)
      .filter((lesson) => lesson.classroomIds.includes(Number(classroomId)))
      .sort(orderByPosition)
);

const nextOrPrevLesson = (lessons, currentId, value = 1) => {
  const lesson = lessons.filter((lesson) => lesson.id === currentId)[0];
  const idx = lessons?.indexOf(lesson);
  return lessons[idx + value];
};

export const getNextLesson = createSelector(
  listOfLessons,
  getCurrentLessonId,
  (lessons, currentId) => nextOrPrevLesson(lessons, currentId)
);

export const getPrevLesson = createSelector(
  listOfLessons,
  getCurrentLessonId,
  (lessons, currentId) => nextOrPrevLesson(lessons, currentId, -1)
);

export const getAllLessons = createSelector(getLessons, (lessons) =>
  Object.values(lessons)
);

export const getLessonsFetchedIds = createSelector(
  getAllLessons,
  getFetchedIds,
  (lessons, ids) => lessons.filter((lesson) => ids.includes(lesson.id))
);
