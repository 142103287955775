import { createSelector } from "reselect";
import { getLessonId } from "./lessonSelectors";
import { orderByCreatedAtDesc } from "../utils/orderByCreatedAt";

const getComments = (state) => state.entities.comments;
const getFetchedIds = (state) => state.commentsList.commentIds;
export const getCommentId = (_, id) => id;

export const getCommentsByLesson = createSelector(
  getLessonId,
  getComments,
  getFetchedIds,
  (lessonId, comments, ids) =>
    Object.values(comments)
      .filter(
        (comment) =>
          ids.includes(comment.id) &&
          comment.commentableType === "Lesson" &&
          comment.commentableId === lessonId
      )
      .sort(orderByCreatedAtDesc)
);
