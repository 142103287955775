import Fields from "./SlicesPerGuestCalculatorFields";
import Result from "./SlicesPerGuestCalculatorResult";
import Description from "./SlicesPerGuestCalculatorDescription";
import calculate from "./calculate";

const SlicesPerGuestCalculator = {
  Description,
  Fields,
  Result,
  Custom: () => null,
  calculate,
};

export default SlicesPerGuestCalculator;
