import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { Sidebar } from "semantic-ui-react";
import HeaderContainer from "./header/HeaderContainer";
import MainMenuContainer from "./mainMenu/MainMenuContainer";
import NotifierContainer from "./notifier/NotifierContainer";
import { sessionValidate } from "../processes/sessionProcesses";
import { fetchLessons } from "../processes/lessonProcesses";
import { fetchLessonsUsers } from "../processes/lessonsUserProcesses";
import { fetchSubscriptions } from "../processes/subscriptionProcesses";
import { fetchRatings } from "../processes/ratingsProcesses";
import { fetchConfigs } from "../processes/configProcessess";
import { utilityColors, uiColors } from "./library";
import { matchPath } from "react-router";
import TermsContainer from "./terms/TermsContainer";
import { getCurrentUser } from "../selectors/userSelectors";
import { fetchTrail } from "../processes/trailProcesses";
import MainMenuProfileContainer from "./mainMenu/MainMenuProfileContainer";
import { makeIsMobile } from "../utils/useScreenWidth";
import { css } from "glamor";

const sidebarStyle = {
  padding: 0,
  boxShadow: `0 2px 5px ${utilityColors.shadow}`,
  background: uiColors.fieldBackground,
};
const homeClass = css({
  padding: 0,
});

function LMS(props) {
  const { dispatch, location, currentClassroomId, currentTrailId } = props;
  const currentPath = location.pathname;
  const isMobile = makeIsMobile();
  const isLessonPath = !!matchPath(currentPath, {
    path: "/subscriptions/:id/classrooms/:id/lessons",
  });
  const isHomePath = !!matchPath(currentPath, {
    path: "/",
    exact: true,
  });

  const [showSidebar, setShowSidebar] = useState(false);
  const toogleSidebar = useCallback(() => {
    setShowSidebar(!showSidebar);
  }, [showSidebar]);
  const hideSidebar = useCallback(() => setShowSidebar(false));
  const onShowMobileSidebar = useCallback(
    () => (document.documentElement.scrollTop = 0)
  );

  useEffect(() => {
    sessionValidate(dispatch);
    fetchConfigs(dispatch);
    fetchSubscriptions(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (currentClassroomId) {
      fetchLessons(dispatch, currentClassroomId);
      fetchLessonsUsers(dispatch, currentClassroomId);
      fetchRatings(dispatch);
    }
  }, [dispatch, currentClassroomId]);

  useEffect(() => {
    if (currentTrailId) {
      fetchTrail(dispatch, currentTrailId);
    }
  }, [dispatch, currentTrailId]);

  const contentClass = css({
    minHeight: "100vh",
    background: uiColors.background,
    overflow: "auto",
    padding: "70px 0 0 0",
    transition: "margin 0.5s ease",
  });

  return (
    <React.Fragment>
      <TermsContainer />
      <Sidebar.Pushable>
        <Sidebar
          animation="overlay"
          visible={showSidebar}
          onHide={hideSidebar}
          onShow={onShowMobileSidebar}
          style={sidebarStyle}
          onClick={hideSidebar}
        >
          <MainMenuProfileContainer />
          <MainMenuContainer {...props} mobile={true} />
        </Sidebar>
        <Sidebar.Pusher dimmed={showSidebar && isMobile}>
          <HeaderContainer
            toogleSidebar={toogleSidebar}
            isLessonPath={isLessonPath}
            isHomePath={isHomePath}
          />
          <div className={css(contentClass, isHomePath && homeClass)}>
            <NotifierContainer />
            {props.children}
          </div>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </React.Fragment>
  );
}

function mapStateToProps(state) {
  const currentUser = getCurrentUser(state);
  return {
    currentClassroomId: state.currentClassroomId,
    currentTrailId: currentUser?.trailId,
  };
}

export default connect(mapStateToProps)(LMS);
