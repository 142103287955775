const initialState = {
  subscriptionIds: [],
  loading: false,
};

const getSubscriptionsFetchedIds = (action) =>
  action.result ? action.result : initialState.classroomIds;

export default function subscriptionListReducer(state = initialState, action) {
  switch (action.type) {
    case "SUBSCRIPTIONS_FETCH_REQUESTED":
      return { ...state, loading: true };
    case "SUBSCRIPTIONS_FETCHED":
      return {
        ...state,
        subscriptionIds: getSubscriptionsFetchedIds(action),
        loading: false,
      };
    default:
      return state;
  }
}
