import { css } from "glamor";
import React from "react";
import { Grid } from "semantic-ui-react";
import Spinner from "../library/Spinner";
import { spacing } from "../library/theme";
import AdvancedSearchListItem from "./AdvancedSearchListItem";
import ClassroomsEmpty from "../classrooms/ClassroomsEmpty";

const loadingWrapper = css({
  padding: spacing.xxl,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
});

export default function AdvancedSearchList(props) {
  const { loading, searchResults } = props;
  return loading ? (
    <div className={loadingWrapper}>
      <Spinner inline text="Carregando..." />
    </div>
  ) : (
    <>
      {!searchResults?.length && <ClassroomsEmpty />}
      {!!searchResults?.length && (
        <Grid centered padded stackable stretched>
          {searchResults?.map((result, index) => (
            <Grid.Column computer={4} tablet={8} mobile={1}>
              <AdvancedSearchListItem key={index} content={result} />
            </Grid.Column>
          ))}
        </Grid>
      )}
    </>
  );
}
