import React from "react";
import { Title, css, uiColors } from "../library";
import AttachmentListItemContainer from "./AttachmentListItemContainer";

const container = css({
  backgroundColor: uiColors.background,
});

export default function AttachmentList(props) {
  return (
    <div className={container}>
      {props.attachments.length > 0 ? (
        props.attachments.map((attachment, index) => {
          return (
            <AttachmentListItemContainer
              key={attachment.id}
              attachment={attachment}
              index={index}
            />
          );
        })
      ) : (
        <Title>Nenhum arquivo associado a este conteúdo</Title>
      )}
    </div>
  );
}
