import Fields from "./SweetsPerGuestCalculatorFields";
import Result from "./SweetsPerGuestCalculatorResult";
import Description from "./SweetsPerGuestCalculatorDescription";
import calculate from "./calculate";

const SweetsPerGuestCalculator = {
  Description,
  Fields,
  Result,
  Custom: () => null,
  calculate,
};

export default SweetsPerGuestCalculator;
