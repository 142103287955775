import React from "react";
import { Title } from "../../library";

const titleStyle = {
  padding: "0 14px",
};

export default function BrigadeiroCalculatorDescription() {
  return (
    <Title as="h4" style={titleStyle} strong>
      Recebeu uma encomenda de brigadeiros e não sabe quanto de ingrediente vai
      precisar? Ou quantas receitas precisa fazer? Faça seu cálculo aqui!
    </Title>
  );
}
