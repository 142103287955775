const getRecipes = (action) => action.entities.recipes;

export default function recipesReducer(state = {}, action) {
  switch (action.type) {
    case "RECIPE_FETCHED":
    case "RECIPES_FETCH_REQUESTED":
      return {};
    case "RECIPES_FETCHED":
      return { ...state, ...getRecipes(action) };
    default:
      return state;
  }
}
