import React from "react";
import { spacing } from "../library/theme";
import { css } from "glamor";
import TagListItem from "./TagListItem";
import Spinner from "../library/Spinner";
import List from "../library/List";

const loadingWrapper = css({
  padding: spacing.xxl,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
});

const renderItem = (tag, index) => <TagListItem key={index} tag={tag} />;

export default function Tags(props) {
  const { loading, tags, title = "Categorias" } = props;

  return (
    <>
      {loading ? (
        <div className={loadingWrapper}>
          <Spinner inline text="Carregando..." />
        </div>
      ) : (
        <div>
          {!!tags.length ? (
            <List title={title} data={tags} renderItem={renderItem} hideIcon />
          ) : null}
        </div>
      )}
    </>
  );
}
