import { normalize, schema } from "normalizr";
import { apiPost, apiDel } from "./helpers/api";
import { notifySubmitSucceeded } from "./notifierProcesses";

const replySchema = new schema.Entity("replies");

export const createReply = (values, dispatch, props) => {
  return apiPost(
    `/api/v1/classrooms/${props.currentClassroomId}/lessons/${props.comment.commentableId}/comments/${props.comment.id}/replies`
  )
    .send(values)
    .then((response) => {
      dispatch({
        type: "REPLY_CREATED",
        ...normalize({ ...response.body.data, pristine: true }, replySchema),
      });
      notifySubmitSucceeded(
        dispatch,
        "Sua resposta foi enviada com sucesso! Será exibida após aprovação."
      );
      props.reset();
    });
};

export const deleteReply = (dispatch, commentId, replyId) => {
  return apiDel(`/api/v1/replies/${replyId}`).then(() => {
    dispatch({ type: "REPLY_DELETED", commentId, replyId });
    notifySubmitSucceeded(dispatch, "Sua resposta foi apagada.");
  });
};
