import React from "react";
import { Modal } from "semantic-ui-react";
import Button from "../library/Button";

export default function Terms({
  term,
  open,
  onClose,
  onClickHandler,
  onlyPreview,
}) {
  return (
    <Modal open={open} onClose={onClose} closeOnDimmerClick={onlyPreview}>
      <Modal.Header>Termos de Uso</Modal.Header>
      <Modal.Content scrolling>
        <Modal.Description>
          <p dangerouslySetInnerHTML={{ __html: term?.content }} />
        </Modal.Description>
      </Modal.Content>
      {!onlyPreview && (
        <Modal.Actions>
          <Button
            color="red"
            content="Aceitar e Continuar"
            icon="checkmark"
            onClick={onClickHandler}
          />
        </Modal.Actions>
      )}
    </Modal>
  );
}
