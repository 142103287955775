import React from "react";
import { Title } from "../../library";

const titleStyle = {
  padding: "0 14px",
};

export default function BatterCalculatorDescription() {
  return (
    <Title as="h4" style={titleStyle} strong>
      Veja a quantidade necessária de massa que deve ser feita para o seu bolo.
      Obs.: Esses valores são aproximados e podem variar. A espessura padrão de
      cada disco de massa é de 2 a 2,5 cm.
    </Title>
  );
}
