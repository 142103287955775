import React from "react";
import { spacing } from "../library/theme";

const paragraph = { flex: 1, textAlign: "center", padding: spacing.m };

export default function ClassroomsEmpty({
  title = "Sua busca não retornou nenhum resultado.",
}) {
  return <p style={paragraph}>{title}</p>;
}
