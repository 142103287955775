import { css } from "glamor";
import React, { useCallback, useEffect, useState } from "react";
import Title from "./Title";
import { colors, spacing } from "./theme";
import { Menu } from "semantic-ui-react";

const layout = css({ overflowY: "hidden", marginBottom: spacing.xl });
const header = css({
  marginBottom: spacing.m,
  marginLeft: spacing.offset,
  marginRight: spacing.offset,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});
const titleClass = css({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});
const list = css({
  width: "100%",
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  marginBottom: "20px",
  paddingLeft: 0,
  position: "relative",
});
const menuWrapperClass = css({
  padding: spacing.xs,
  marginBottom: spacing.m,
});
const menuClass = css({
  flexWrap: "wrap",
  gap: spacing.xxs,
});
const menuItemClass = css({
  ".active": {
    color: `${colors.brandDark} !important`,
    backgroundColor: `${colors.greyLightest} !important`,
    fontWeight: "bold !important",
  },
});
const cardWrapper = css({
  flex: "0 0 160px",
  marginBottom: "16px",
  marginRight: "16px",
});

const ClassroomCategoryButton = ({
  classroomCategory,
  selectedCategory,
  setSelectedCategory,
}) => {
  const changeSelectedCategory = useCallback(() => {
    setSelectedCategory(classroomCategory);
  }, [classroomCategory, setSelectedCategory]);

  return (
    <Menu.Item
      content={classroomCategory?.label}
      active={classroomCategory?.id == selectedCategory?.id}
      onClick={changeSelectedCategory}
      className={menuItemClass}
    />
  );
};

export default function List({ data = [], renderItem, title, tags }) {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [filteredData, setFilteredData] = useState(data);
  const [filteredTags, setFilteredTags] = useState([]);

  useEffect(() => {
    if (selectedCategory) {
      const filtered = data.filter((item) =>
        item.tags.includes(selectedCategory.id)
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(data);
    }
  }, [data, selectedCategory]);

  const handleResetFilter = () => {
    setSelectedCategory(null);
    setFilteredData(data);
  };

  useEffect(() => {
    handleResetFilter();
  }, []);

  useEffect(() => {
    const usedTagIds = data?.reduce((tagIds, item) => {
      return [...tagIds, ...item?.tags];
    }, []);
    const filtered = tags?.filter((tag) => usedTagIds?.includes(tag?.id));
    setFilteredTags(filtered);
  }, [data, tags]);

  return (
    <div className={layout}>
      <div className={header}>
        <div className={titleClass}>
          <Title strong>{title}</Title>
        </div>
      </div>
      {filteredTags?.length > 0 && (
        <div className={menuWrapperClass}>
          <Menu
            secondary
            fluid
            stackable
            className={menuClass}
            items={[
              ...filteredTags?.map((classroomCategory) => (
                <ClassroomCategoryButton
                  key={classroomCategory.id}
                  classroomCategory={classroomCategory}
                  selectedCategory={selectedCategory}
                  setSelectedCategory={setSelectedCategory}
                />
              )),
              <Menu.Item
                key="reset"
                content="Todos"
                active={!selectedCategory}
                onClick={handleResetFilter}
                className={menuItemClass}
              />,
            ]}
          />
        </div>
      )}
      <div className={list}>
        {filteredData.map((item, index) => (
          <div key={index} className={cardWrapper}>
            {renderItem(item)}
          </div>
        ))}
      </div>
    </div>
  );
}
