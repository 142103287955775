import { normalize, schema } from "normalizr";
import { apiGet } from "./helpers/api";

const tags = new schema.Entity("tags");
const stateSchema = new schema.Entity("states");
const citySchema = new schema.Entity("cities");

const valuesSchema = new schema.Values(
  {
    tags: new schema.Array(tags),
    states: new schema.Array(stateSchema),
    cities: new schema.Array(citySchema),
  },
  (input, parent, key) => `${key}`
);

export const fetchConfigs = (dispatch) => {
  return apiGet("/api/v1/config").then((response) => {
    dispatch({
      type: "CONFIGS_FETCHED",
      ...normalize(response.body.data[0], valuesSchema),
      trailSurveyId: response.body.data[0].trailSurveyId,
    });
  });
};

export const fetchCities = (dispatch, stateId) => {
  dispatch({ type: "CITIES_FETCH_REQUESTED" });
  return apiGet(`/api/v1/states/${stateId}/cities`).then((response) => {
    dispatch({
      type: "CITIES_FETCHED",
      ...normalize({ cities: response.body.data }, valuesSchema),
    });
  });
};
