import { apiGet } from "./helpers/api";
import { normalize, schema } from "normalizr";

const trail = new schema.Entity("trails");

export const fetchTrail = (dispatch, trailId) => {
  apiGet(`/api/v1/trails/${trailId}/`).then((response) => {
    dispatch({
      type: "TRAILS_FETCHED",
      ...normalize(response.body.data, trail),
    });
  });
};
