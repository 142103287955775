import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReplyListItem from "./ReplyListItem";
import { deleteReply } from "../../processes/replyProcesses";
import { getCurrentUser } from "../../selectors/userSelectors";

export default function ReplyListItemContainer(props) {
  const { reply } = props;
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => getCurrentUser(state));

  const deleteReplyHandler = useCallback(() => {
    deleteReply(dispatch, reply.commentId, reply.id);
  }, [dispatch, reply.commentId, reply.id]);

  return (
    <ReplyListItem
      deleteReplyHandler={deleteReplyHandler}
      currentUser={currentUser}
      {...props}
    />
  );
}
