import React from "react";
import { css, select } from "glamor";
import Link from "./Link";
import { spacing, typographyColors, decorationColors, uiColors } from "./theme";
import { Icon } from "semantic-ui-react";

const iconClass = css({
  marginRight: `${spacing.m} !important`,
  opacity: 0.3,
});
const activeClass = css(
  {
    color: decorationColors.decorationBrandDark,
    fontWeight: "bold",
    transition: ".3s all",
    borderRight: `2px solid ${typographyColors.defaultLink}`,
  },
  select(`& .${iconClass}`, {
    transition: ".3s opacity",
    opacity: 1,
  })
);
const item = css({
  display: "flex",
  alignContent: "center",
  padding: `${spacing.s} ${spacing.m}`,
  fontSize: 14,
  color: `${typographyColors.defaultBody} !important`,
  transition: ".3s all",
  height: 58,
  borderLeft: `8px solid transparent`,
  "> i": {
    color: "inherit !important",
  },
  "&:hover": {
    cursor: "pointer",
    color: `${decorationColors.decorationBrandDark} !important`,
    fontWeight: "bold",
    ...activeClass,
  },
});
const subItemClass = css({
  fontSize: 14,
  paddingLeft: 24,
  lineHeight: 1.5,
});
const disabledClass = css({
  "&:hover": {
    borderBottom: "none",
  },
  "&:visited": {
    borderBottom: "none",
    color: uiColors.border,
  },
});
const disabledIconStyle = {
  marginLeft: "auto",
  display: "flex",
  alignItems: "center",
};

export default function MenuItem(props) {
  const {
    label,
    icon,
    active,
    children,
    disabled,
    disabledIcon,
    disabledHref,
    subItem,
    className,
    target,
    href,
    ...rest
  } = props;
  const baseClass = css(
    item,
    subItem && subItemClass,
    className && className,
    active && activeClass,
    disabled && disabledClass
  );

  return (
    <Link
      className={baseClass}
      {...rest}
      href={disabled ? disabledHref : href}
      target={disabled ? "_blank" : target}
    >
      {icon && <Icon name={icon} className={iconClass.toString()} />}
      {label || children}
      {disabled && disabledIcon && (
        <span style={disabledIconStyle}>{disabledIcon}</span>
      )}
    </Link>
  );
}
