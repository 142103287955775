import { createSelector } from "reselect";

const getTerms = (state) => state.entities.terms;

export const getAllTerms = createSelector(getTerms, (terms) =>
  Object.values(terms)
);

export const getTerm = createSelector(
  getAllTerms,
  (terms) => terms[terms.length - 1]
);
