import React from "react";
import { connect } from "react-redux";
import ClassroomContents from "./ClassroomContents";
import { getLevelGroupsByClassroom } from "../../selectors/levelGroupSelectors";

function ClassroomContentsContainer(props) {
  return <ClassroomContents {...props} />;
}
function mapStateToProps(state) {
  const currentClassroomId = state.currentClassroomId;
  return {
    levelGroups: getLevelGroupsByClassroom(state, currentClassroomId),
    currentClassroomId,
  };
}

export default connect(mapStateToProps)(ClassroomContentsContainer);
