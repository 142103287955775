import React from "react";
import { Grid, TextField, DropdownField, spacing } from "../../library";

export default function CakePerGuestCalculatorFields() {
  return (
    <>
      <Grid.Column>
        <TextField
          name="quantity"
          label="Para quantos convidados é a festa?"
          placeholder="Quantidade"
          type="number"
          errorMessageDisabled
          step="0.1"
          required
          fluid
        />
      </Grid.Column>
    </>
  );
}
