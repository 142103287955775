import React from "react";
import { css } from "glamor";
import { Image, Grid, Icon } from "semantic-ui-react";
import Logo from "../library/images/logo.png";
import LogoInverse from "../library/images/logo-inverse.png";
import { uiColors } from "../library/theme";
import Link from "../library/Link";
import { FiSearch } from "react-icons/fi";
import { BiMenuAltLeft } from "react-icons/bi";
import { makeIsMobile } from "../../utils/useScreenWidth";
import SearchFormContainer from "../classrooms/SearchFormContainer";
import { spacing } from "../library/theme.js";

const container = css({
  position: "relative",
});
const layout = css({
  display: "flex",
  position: "fixed",
  width: "100%",
  height: 70,
  top: 0,
  left: 0,
  zIndex: 3,
});
const leftButtonClass = css({
  position: "absolute",
  top: spacing.m,
  left: spacing.m,
  zIndex: 10,
  pointerEvents: "all",
});
const menuButtonClass = css(leftButtonClass, {
  color: uiColors.theme,
  cursor: "pointer",
  marginRight: spacing.m,
  pointerEvents: "all",
});
const menuRightButtonClass = css(menuButtonClass, {
  right: spacing.m,
  left: "auto",
  marginRight: 0,
  width: 300,
  display: "flex",
  justifyContent: "right",
});
const searchStyle = css({
  width: "100%",
  maxWidth: 300,
  marginLeft: "auto",
});
const searchWrapper = css({
  position: "absolute",
  top: spacing.m,
  right: spacing.m,
  zIndex: 10,
  pointerEvents: "all",
  textAlign: "right",
});
const linkStyle = { zIndex: 6 };

const searchGridColumn = css({ display: "flex", justifyContent: "end" });
const logoWrapperClass = css({
  position: "absolute",
  top: 0,
  bottom: 70,
  left: 0,
  right: 0,
  zIndex: 9,
});
const logoContentClass = css({
  paddingTop: spacing.s,
  height: 70,
  width: "100%",
  display: "flex",
  justifyContent: "center",
  zIndex: 5,
});

export default function Header(props) {
  const {
    toogleSidebar,
    isLessonPath,
    isHomePath,
    currentClassroomId,
    showSearchIcon,
    showSearchIconHandler,
    isSubscriptionPath,
    subscriptionId,
  } = props;
  const isMobile = makeIsMobile();
  const searchGridSpace = showSearchIcon ? 1 : 16;

  return (
    <div className={container}>
      {isLessonPath ? (
        <div className={leftButtonClass}>
          <Link
            to={`/subscriptions/${subscriptionId}/classrooms/${currentClassroomId}`}
            style={linkStyle}
          >
            <Icon
              name="angle left"
              color={isHomePath ? "white" : "red"}
              size="large"
              style={linkStyle}
            />
          </Link>
        </div>
      ) : (
        <BiMenuAltLeft
          onClick={toogleSidebar}
          size="30"
          className={menuButtonClass.toString()}
        />
      )}
      <div className={logoWrapperClass}>
        <div className={logoContentClass}>
          <Link to="/">
            <Image size="small" src={isHomePath ? LogoInverse : Logo} />
          </Link>
        </div>
      </div>

      {isSubscriptionPath && (
        <div className={menuRightButtonClass}>
          {showSearchIcon ? (
            <FiSearch
              size="30"
              style={searchStyle}
              onClick={showSearchIconHandler}
            />
          ) : (
            <div className={searchStyle}>
              <SearchFormContainer />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
