import React from "react";
import { Modal } from "semantic-ui-react";
import { Widget } from "react-typeform-embed";

export default function TrailModal({
  openModal,
  onClose,
  trailSurveyId,
  currentUserId,
}) {
  if (!trailSurveyId) {
    return null;
  }

  return (
    <Modal open={openModal} onClose={onClose} closeIcon>
      <Modal.Content>
        <Widget
          id={trailSurveyId}
          height={700}
          width="100%"
          iframeProps={{ height: "100%", width: "100%" }}
          hidden={{ user_id: currentUserId }}
        />
      </Modal.Content>
    </Modal>
  );
}
