import { connect } from "react-redux";
import HomeSubscriptionCard from "./HomeSubscriptionCard";
import {
  getAllSubscriptions,
  hasSubscription,
} from "../../selectors/subscriptionSelectors";

function mapStateToProps(state, { subscription }) {
  return {
    hasSubscription: hasSubscription(state, subscription?.id),
  };
}

export default connect(mapStateToProps)(HomeSubscriptionCard);
