import React from "react";
import { css } from "glamor";
import { Pagination as CustomPagination } from "semantic-ui-react";
import { styles, colors, uiColors } from "./theme";

const activePaginationItem = {
  ...styles.cardShadow,
  color: "white !important",
  background: `${uiColors.theme} !important`,
};
const paginationClass = css({
  gap: 10,
  borderBottom: "none !important",
  "> a": {
    color: `${uiColors.theme} !important`,
    border: `1px solid ${uiColors.theme} !important`,
    borderRadius: "10px !important",
  },
  ".ui.secondary.pointing.menu .item": {
    ":hover": {
      ...activePaginationItem,
      border: `1px solid ${colors.grey}`,
    },
  },
  ".ui.secondary.pointing.menu .active.item": activePaginationItem,
}).toString();

export default function Pagination({
  wrapperClass,
  activePage,
  totalPages,
  onPageChange,
}) {
  return (
    <div className={wrapperClass}>
      <CustomPagination
        className={paginationClass}
        firstItem={null}
        lastItem={null}
        nextItem={null}
        prevItem={null}
        pointing
        secondary
        activePage={activePage}
        totalPages={totalPages}
        onPageChange={onPageChange}
      />
    </div>
  );
}
