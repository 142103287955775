import React from "react";
import Empty from "./images/empty.png";
import { css, spacing, typographyColors } from "./";

const containter = css({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  paddingTop: spacing.xxl,
});
const title = css({
  margin: `${spacing.s} 0px ${spacing.xs} 0px`,
  color: typographyColors.defaultTitle,
  fontWeight: "bold",
});

export default function EmptyPreview({ description = "Tente novamente..." }) {
  return (
    <div className={containter}>
      <img src={Empty} />
      <h3 className={title}>Sem itens por aqui</h3>
      <span>{description}</span>
    </div>
  );
}
