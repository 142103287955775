import React from "react";
import { css } from "glamor";
import { typographyColors } from "../library/theme";
import Image from "../library/Image";

const mobileClass = css({
  background: 'transparent linear-gradient(243deg, #FE5F5F 0%, #CF1D1D 100%)',
  padding: '20px',
});
const textClass = css({
  color: typographyColors.inverseBody,
});

export default function MainMenuProfile({ currentUser }) {
  return (
    <div className={mobileClass}>
      <Image src={currentUser?.avatarUrl} size='tiny' circular />
      <h3 className={textClass}>Olá, { currentUser?.name }!</h3>
      <p className={textClass}>{ currentUser?.email }</p>
    </div>
  );
}
