import React from "react";
import { Comment } from "semantic-ui-react";
import CommentFormContainer from "./CommentFormContainer";
import CommentListItemContainer from "./CommentListItemContainer";
import { Pagination } from "semantic-ui-react";
import { Spinner, uiColors, css } from "../library";

const container = css({ backgroundColor: uiColors.background });

export default function Comments(props) {
  const { fetchCommentsHandler, totalPages, currentPage, loading, comments } =
    props;
  return (
    <div className={container}>
      <CommentFormContainer lessonId={props.lessonId} />
      <h3>Comentários</h3>
      {loading ? (
        <Spinner text="Carregando comentários..." inline="centered" />
      ) : (
        <>
          <Comment.Group>
            {comments.length > 0 ? (
              comments.map((comment) => (
                <CommentListItemContainer
                  key={`comment${comment.id}`}
                  comment={comment}
                />
              ))
            ) : (
              <p>Seja o primeiro a comentar.</p>
            )}
          </Comment.Group>
          {comments.length > 0 && (
            <Pagination
              activePage={currentPage}
              onPageChange={fetchCommentsHandler}
              totalPages={totalPages}
            />
          )}
        </>
      )}
    </div>
  );
}
