import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchClassrooms } from "../../processes/classroomProcesses";
import { fetchSubscriptions } from "../../processes/subscriptionProcesses";

export function useLoadCertificates() {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      await fetchClassrooms(dispatch);
      await fetchSubscriptions(dispatch);
      setLoading(false);
    }
    fetchData();
  }, [dispatch]);

  return { loading };
}

