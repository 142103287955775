const getComments = (action) => action.entities.comments;

export default function commentsReducer(state = {}, action) {
  let newState;
  switch (action.type) {
    case "COMMENT_CREATED":
    case "COMMENTS_FETCHED":
      return { ...state, ...getComments(action) };
    case "COMMENT_DELETED":
      newState = { ...state };
      if (newState[action.commentId]) delete newState[action.commentId];
      return newState;
    default:
      return state;
  }
}
