import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { fetchCalculators } from "../../processes/calculatorProcesses";
import {
  getAllCalculators,
  getCalculatorById,
  getCalculatorOptions,
} from "../../selectors/calculatorSelectors";
import { makeIsMobile } from "../../utils/useScreenWidth";
import buildForm from "../library/forms/buildForm";
import CalculatorForm from "./CalculatorForm";
import BatterCalculator from "./batterCalculator";
import BrigadeiroCalculator from "./brigadeiroCalculator";
import BrownieCalculator from "./brownieCalculator";
import ButtercreamCalculator from "./buttercreamCalculator";
import CakePerGuestCalculator from "./cakePerGuestCalculator";
import CookieCalculator from "./cookieCalculator";
import FillingCalculator from "./fillingCalculator";
import GanacheCalculator from "./ganacheCalculator";
import MoldCalculator from "./moldCalculator";
import SlicesPerGuestCalculator from "./slicesPerGuestCalculator";
import SweetsPerGuestCalculator from "./sweetsPerGuestCalculator";

const calculatorTypes = {
  cookie: CookieCalculator,
  brigadeiro: BrigadeiroCalculator,
  sweets: SweetsPerGuestCalculator,
  cake: CakePerGuestCalculator,
  mold: MoldCalculator,
  brownie: BrownieCalculator,
  batter: BatterCalculator,
  slices: SlicesPerGuestCalculator,
  ganache: GanacheCalculator,
  buttercream: ButtercreamCalculator,
  filling: FillingCalculator,
};

const formName = "calculatorForm";
const form = buildForm(formName);

function CalculatorFormContainer(props) {
  const { dispatch, calculator, calculators, calculatorFormValues } = props;
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(null);

  useEffect(() => {
    setResult(null);
  }, [calculator]);

  useEffect(() => {
    setLoading(true);
    fetchCalculators(dispatch).then(() => {
      setLoading(false);
    });
  }, [dispatch]);

  const calculateHandler = useCallback(() => {
    const calculate = calculatorTypes[calculator?.slug]?.calculate;

    setResult(
      calculate({
        calculator,
        calculatorFormValues,
        calculators,
      })
    );
  }, [calculator, calculators, calculatorFormValues]);

  const isMobile = makeIsMobile();

  return (
    <CalculatorForm
      calculateHandler={calculateHandler}
      result={result}
      loading={loading}
      isMobile={isMobile}
      {...props}
    />
  );
}

function mapStateToProps(state) {
  const calculatorFormValues = state?.form["calculatorForm"]?.values;
  const calculatorId = calculatorFormValues?.calculatorId;

  return {
    initialValues: {},
    calculatorFormValues,
    calculator: getCalculatorById(state, calculatorId),
    calculators: getAllCalculators(state),
    calculatorOptions: getCalculatorOptions(state),
  };
}

export default connect(mapStateToProps)(
  form.connect({})(CalculatorFormContainer)
);
