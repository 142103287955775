import React, { useCallback } from "react";
import { connect } from "react-redux";
import CommentListItem from "./CommentListItem";
import { deleteComment } from "../../processes/commentProcesses";
import { getRepliesByCommentId } from "../../selectors/replySelector";
import { getCurrentUser } from "../../selectors/userSelectors";

function CommentListItemContainer(props) {
  const { dispatch, comment, classroomId, lessonId } = props;

  const deleteHandler = useCallback(() => {
    deleteComment(dispatch, classroomId, lessonId, comment.id);
  }, [dispatch, classroomId, lessonId, comment]);

  return <CommentListItem deleteHandler={deleteHandler} {...props} />;
}

function mapStateToProps(state, ownProps) {
  const { comment } = ownProps;

  return {
    classroomId: state.currentClassroomId,
    lessonId: state.currentLessonId,
    currentUser: getCurrentUser(state),
    replies: getRepliesByCommentId(state, comment.id),
  };
}

export default connect(mapStateToProps)(CommentListItemContainer);
