import React from "react";
import { Title } from "../../library";

const titleStyle = {
  padding: "0 14px",
};

export default function SlicesPerGuestCalculatorDescription() {
  return (
    <Title as="h4" style={titleStyle} strong>
      Esses são valores aproximados. Pode variar de acordo com o peso do tipo de
      recheio, massa e cobertura escolhidos.
    </Title>
  );
}
