import React from "react";
import { Title } from "../../library";

const titleStyle = {
  padding: "0 14px",
};

export default function FillingCalculatorDescription() {
  return (
    <Title as="h4" style={titleStyle} strong>
      Veja a quantidade necessária de recheio que deve ser feita para o seu
      bolo! Obs.: Esses valores são aproximados e podem variar. O tamanho padrão
      de cada disco de massa calculado é de aproximadamente 2 cm. Atenção: ao
      selecionar uma combinação de recheio, considere que são dois recheios em
      cada camada.
    </Title>
  );
}
