import React, { useCallback } from "react";
import HomeSubscriptionCardContainer from "./HomeSubscriptionCardContainer";
import {
  Page,
  Container,
  Spinner,
  Button,
  spacing,
  css,
  uiColors,
  decorationColors,
} from "../library";
import LogoInverse from "../library/images/logo-inverse";
import LogoShortInvese from "../library/images/logo-short-inverse";
import HomeBanner from "./homeBanner.png";

const container = css({
  paddingBottom: spacing.l,
});
const homeBannerClass = css({
  position: "relative",
  backgroundColor: "#2F1F17",
  display: "flex",
  flexDirection: "column",
  justifyContent: "end",
  padding: "6vw",
  marginBottom: spacing.l,
  minHeight: "100vh",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "right",
  backgroundSize: "contain",
  backgroundImage: `linear-gradient(to right, rgba(47,31,23,1) 0%, transparent 90%), url(${HomeBanner})`,
  "@media(max-width: 768px)": {
    backgroundSize: "cover",
    backgroundPosition: "center center",
    padding: "6vh",
  },
});
const topContentClass = css({
  position: "absolute",
  top: spacing.l,
  left: 0,
  right: 0,
});
const topContentWrapper = css({
  display: "flex",
  justifyContent: "center",
});
const logoClass = css({
  width: "250px",
  "@media(max-width: 768px)": {
    width: "200px",
  },
});
const shortLogoClass = css({
  marginBottom: "3vw",
  width: "6vw",
  "@media(max-width: 768px)": {
    marginBottom: "3vh",
    width: "6vh",
  },
});
const bannerContent = css({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
});
const bannerTextClass = css({
  fontSize: "min(6vw, 78px)",
  color: uiColors.fieldBackground,
  wordBreak: "break-word",
  whiteSpace: "pre-line",
  fontWeight: "bolder",
  lineHeight: 1.2,
  userSelect: "none",
  "@media(max-width: 768px)": {
    fontSize: "min(6vh, 42px) !important",
  },
  "> strong": {
    display: "block",
  },
});
const redTextClass = css(bannerTextClass, {
  color: decorationColors.decorationBrandDark,
});
const rightContent = css({ flex: 1 });
const shortcutButtonClass = css({
  width: 250,
  "@media(max-width: 768px)": {
    width: "100%",
  },
});
const subscriptionWrapper = css({
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)",
  gridGap: spacing.m,
  "@media(max-width: 1205px)": {
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  "@media(max-width: 768px)": {
    gridTemplateColumns: "repeat(1, 1fr)",
  },
});
const titleClass = css({
  color: decorationColors.decorationBrandDark,
  marginBottom: spacing.l,
  fontWeight: "bold",
});

export default function Home(props) {
  const { subscriptions } = props;

  const scrollDownHandler = useCallback(() => {
    window.scrollTo({
      top: window.innerHeight,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className={container}>
      <div className={homeBannerClass}>
        <div className={bannerContent}>
          <div className={bannerTextClass}>
            <strong>Bem-vindo à</strong>
            <strong className={redTextClass}>{`maior Escola
             Online de Confeitaria`}</strong>
            <strong>do Brasil</strong>
          </div>
        </div>
        <Button
          onClick={scrollDownHandler}
          color="red"
          className={shortcutButtonClass}
        >
          Conheça nossos produtos
        </Button>
      </div>
      {subscriptions.length ? (
        <Container>
          <h1 className={titleClass}>Conheça nossos produtos</h1>
          <div className={subscriptionWrapper} id="ourProducts">
            {subscriptions.map((subscription) => (
              <HomeSubscriptionCardContainer subscription={subscription} />
            ))}
          </div>
        </Container>
      ) : (
        <Spinner text="Carregando..." wrapped />
      )}
    </div>
  );
}
