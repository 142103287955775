import React, { useEffect } from "react";
import { connect } from "react-redux";
import { fetchAttachments } from "../../processes/attachmentProcesses";
import AttachmentList from "./AttachmentList";
import { getAttachmentsByLesson } from "../../selectors/attachmentSelectors";

function AttachmentListContainer(props) {
  const { dispatch, currentClassroomId, lessonId } = props;
  useEffect(() => fetchAttachments(dispatch, currentClassroomId, lessonId), [
    dispatch,
    currentClassroomId,
    lessonId,
  ]);
  return <AttachmentList {...props} />;
}

function mapStateToProps(state, ownProps) {
  const lessonId = ownProps.lessonId;
  return {
    attachments: getAttachmentsByLesson(state, lessonId),
    currentClassroomId: state.currentClassroomId,
    lessonId,
  };
}

export default connect(mapStateToProps)(AttachmentListContainer);
