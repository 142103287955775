import React from "react";
import { connect } from "react-redux";
import { RecommendationsFilterForm } from "./RecommendationsFilterForm";
import { formValueSelector, reduxForm, change } from "redux-form";
import { submitRecommendationsFilter } from "../../processes/recommendationProcesses";
import { getConfig } from "../../selectors/configSelectors";

const FORM_NAME = "recommendationsFilterForm";

function RecommendationsFilterFormContainer(props) {
  const { dispatch, typeEq } = props;

  React.useEffect(() => {
    typeEq == "2" && dispatch(change(FORM_NAME, "categoryEq", undefined));
  }, [dispatch, typeEq]);

  return <RecommendationsFilterForm {...props} />;
}

function mapStateToProps(state) {
  const recommendationsFilterFormSelector = formValueSelector(FORM_NAME);
  const typeEq = recommendationsFilterFormSelector(state, "typeEq");

  const isSupplier = typeEq == 0;
  const isCoupon = typeEq == 2;

  return {
    states: getConfig(state, "states"),
    isSupplier,
    isCoupon,
    typeEq,
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: FORM_NAME,
    onSubmit: submitRecommendationsFilter,
  })(RecommendationsFilterFormContainer)
);
