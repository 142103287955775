import React from "react";
import { Grid } from "../../library";
import {
  CircleDiameterField,
  ShapeField,
  RetangularDimensionsFields,
} from "../utils";
import VolumeField from "../utils/VolumeField";

const shapeOptions = [
  { text: "Redonda", value: "circular", key: "circular" },
  { text: "Retangular/Quadrada", value: "retangular", key: "retangular" },
  { text: "Com furo no meio", value: "holed", key: "holed" },
];

export default function MoldCalculatorFields({ fromShape, toShape }) {
  return (
    <>
      <Grid.Column>
        <ShapeField
          options={shapeOptions}
          shapeFieldName="fromShape"
          shapeLabel="De"
        />
      </Grid.Column>

      <Grid.Column>
        <ShapeField
          options={shapeOptions}
          shapeFieldName="toShape"
          shapeLabel="Para"
        />
      </Grid.Column>

      {fromShape == "circular" && (
        <Grid.Column>
          {toShape == "holed" ? (
            <VolumeField label="Volume Original (L)" name="fromVolume" />
          ) : (
            <CircleDiameterField
              diameterFieldName="fromDiameter"
              diameterLabel="Diâmetro original (cm)"
            />
          )}
        </Grid.Column>
      )}

      {fromShape == "retangular" && (
        <Grid.Column>
          {toShape == "holed" ? (
            <VolumeField label="Volume Original (L)" name="fromVolume" />
          ) : (
            <RetangularDimensionsFields
              heightFieldName="fromHeight"
              widthFieldName="fromWidth"
              label="Dimensões originais (cm)"
            />
          )}
        </Grid.Column>
      )}

      {fromShape == "holed" && (
        <Grid.Column>
          <VolumeField label="Volume Original (L)" name="fromVolume" />
        </Grid.Column>
      )}

      {toShape == "circular" && (
        <Grid.Column>
          {fromShape == "holed" ? (
            <VolumeField label="Volume Desejado (L)" name="toVolume" />
          ) : (
            <CircleDiameterField
              diameterFieldName="toDiameter"
              diameterLabel="Diâmetro desejado (cm)"
            />
          )}
        </Grid.Column>
      )}

      {toShape == "retangular" && (
        <Grid.Column>
          {fromShape == "holed" ? (
            <VolumeField label="Volume Desejado (L)" name="toVolume" />
          ) : (
            <RetangularDimensionsFields
              heightFieldName="toHeight"
              widthFieldName="toWidth"
              label="Dimensões desejadas (cm)"
            />
          )}
        </Grid.Column>
      )}

      {toShape == "holed" && (
        <Grid.Column>
          <VolumeField label="Volume Desejado (L)" name="toVolume" />
        </Grid.Column>
      )}
    </>
  );
}
