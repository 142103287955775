import { createSelector } from "reselect";

const getSearchResults = (state) => state?.searchResults?.results;
const getProps = (_, props) => props;
export const getSearchParams = (state) => state?.searchResults;

export const getAdvancedSearchResults = createSelector(
  getSearchResults,
  getProps,
  (search, { entity }) => search?.[entity]?.results
);
