import React from "react";
import { createTextMask } from "redux-form-input-masks";
import { Grid } from "semantic-ui-react";
import makeDropDownOptions from "../../utils/makeDropDownOptions";
import Title from "../library/Title";
import DropdownField from "../library/forms/DropdownField";
import TextAreaField from "../library/forms/TextAreaField";
import TextField from "../library/forms/TextField";
import { spacing } from "../library/theme";
import {
  locationTypesOptions,
  supplyCategoriesOptions,
} from "./dropdownOptions";

const formTitleStyle = {
  padding: `${spacing.s} 0`,
  marginBottom: spacing.xs,
};
const cepMask = createTextMask({
  pattern: "99999-999",
  guide: false,
  allowEmpty: true,
});

export const SupplierFields = ({
  states,
  cities,
  stateId,
  loadingCities,
  isLocationPhysical,
}) => {
  return (
    <>
      <Title style={formTitleStyle} strong>
        Indicar um fornecedor
      </Title>

      <Grid stackable>
        <Grid.Row columns={2}>
          <Grid.Column>
            <DropdownField
              name="locationType"
              label="Online / Física"
              placeholder="Selecione uma opção."
              options={locationTypesOptions}
              required
              fluid
            />
          </Grid.Column>

          <Grid.Column>
            <TextField
              name="name"
              label="Nome do fornecedor"
              placeholder="Digite aqui..."
              required
              fluid
            />
          </Grid.Column>
        </Grid.Row>

        {isLocationPhysical && (
          <>
            <Grid.Row columns={3}>
              <Grid.Column width={4}>
                <TextField
                  name="addressZipcode"
                  label="CEP"
                  placeholder="99999-999"
                  {...cepMask}
                  required
                  fluid
                />
              </Grid.Column>

              <Grid.Column width={9}>
                <TextField
                  name="address"
                  label="Endereço"
                  placeholder="Digite seu endereço..."
                  required
                  fluid
                />
              </Grid.Column>

              <Grid.Column width={3}>
                <TextField
                  name="addressNumber"
                  label="Número"
                  placeholder="999"
                  type="number"
                  shortMessages
                  required
                  fluid
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row columns={2}>
              <Grid.Column>
                <DropdownField
                  name="stateId"
                  label="Estado"
                  placeholder="Selecione uma opção."
                  options={makeDropDownOptions(states)}
                  required
                  selection
                  search
                  fluid
                />
              </Grid.Column>

              <Grid.Column>
                <DropdownField
                  label="Cidade"
                  name="cityId"
                  placeholder="Selecione uma opção."
                  options={makeDropDownOptions(cities)}
                  required
                  loading={loadingCities}
                  disabled={!stateId || loadingCities}
                  selection
                  search
                  fluid
                />
              </Grid.Column>
            </Grid.Row>
          </>
        )}

        <Grid.Row columns={2}>
          <Grid.Column>
            <TextField
              name="website"
              label="Website"
              placeholder="https://www.exemplo.com.br"
              required
              fluid
            />
          </Grid.Column>

          <Grid.Column>
            <TextField
              name="instagram"
              label="Instagram"
              placeholder="@exemplo"
              required
              fluid
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row columns={1}>
          <Grid.Column>
            <DropdownField
              name="category"
              label="Categoria"
              placeholder="Selecione uma opção."
              options={supplyCategoriesOptions}
              required
              fluid
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row columns={1}>
          <Grid.Column>
            <TextAreaField
              rows={3}
              name="reason"
              label="Motivo da indicação"
              placeholder="Digite aqui o que torna este fornecedor especial."
              required
              fluid
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};
