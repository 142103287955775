import { normalize, schema } from "normalizr";
import { apiGet } from "./helpers/api";

const themeSchema = new schema.Entity("themes");

export const fetchThemes = (dispatch) => {
  dispatch({ type: "THEMES_FETCH_REQUESTED" });
  apiGet(`/api/v1/themes`).then((response) => {
    dispatch({
      type: "THEMES_FETCHED",
      ...normalize(response.body.data, new schema.Array(themeSchema)),
    });
  });
};
