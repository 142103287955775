import React from "react";
import { css } from "glamor";
import ClassroomListItemContainer from "./ClassroomListItemContainer";
import Spinner from "../library/Spinner";
import ClassroomsEmpty from "./ClassroomsEmpty";
import { spacing } from "../library/theme";
import List from "../library/List";

const loadingWrapper = css({
  padding: spacing.xxl,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
});

const renderItem = (props) => (classroom, index) => {
  const subscriptionId = props?.subscription?.id || props?.subscriptionId;
  return (
    <ClassroomListItemContainer
      key={index}
      classroom={classroom}
      subscriptionId={subscriptionId}
      {...props}
    />
  );
};

export default function Classrooms(props) {
  const { tags, loading, classrooms, title = "Todos os cursos" } = props;
  return (
    <>
      {loading ? (
        <div className={loadingWrapper}>
          <Spinner inline text="Carregando..." />
        </div>
      ) : (
        <div>
          {classrooms?.length ? (
            <List
              title={title}
              data={classrooms}
              renderItem={renderItem(props)}
              tags={tags}
            />
          ) : (
            <ClassroomsEmpty />
          )}
        </div>
      )}
    </>
  );
}
