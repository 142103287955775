import React from "react";
import { Grid, DropdownField } from "../../library";
import makeDropDownOptions from "../../../utils/makeDropDownOptions";
import {
  ShapeField,
  LayerFactorField,
  CakeTypeField,
  CircleDiameterField,
  RetangularDimensionsFields,
} from "../utils";

const cakeOptions = [
  { text: "Coberto", value: "covered", key: "covered" },
  { text: "Seminaked", value: "seminaked", key: "seminaked" },
];

const getCakeFillingOptions = (calculator) => {
  const fillings = calculator?.meta?.supplies?.filter(
    ({ category }) => category
  );
  return makeDropDownOptions(fillings, fillings?.length && "Nenhuma");
};

export default function ButtercreamCalculatorFields({ shape, calculator }) {
  const cakeFillingOptions = getCakeFillingOptions(calculator);

  return (
    <>
      <Grid.Column>
        <ShapeField />
      </Grid.Column>

      <Grid.Column>
        <LayerFactorField />
      </Grid.Column>

      <Grid.Column>
        <CakeTypeField options={cakeOptions} />
      </Grid.Column>

      {!!cakeFillingOptions.length && (
        <Grid.Column>
          <DropdownField
            name="fillingId"
            label="Selecione a saborização"
            options={cakeFillingOptions}
            placeholder="Selecione uma opção"
            fluid
          />
        </Grid.Column>
      )}

      {shape == "circular" && (
        <Grid.Column>
          <CircleDiameterField />
        </Grid.Column>
      )}

      {shape == "retangular" && (
        <Grid.Column>
          <RetangularDimensionsFields />
        </Grid.Column>
      )}
    </>
  );
}
