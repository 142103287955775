import toQueryString from "../utils/toQueryString";
import { apiGet } from "./helpers/api";

export const submitSearch = (values, dispatch, props) => {
  values = props?.form == "searchForm" ? { ...values, limit: 3 } : values;
  dispatch({ type: "SEARCH_FETCH_REQUEST" });
  const searchParams = toQueryString(values);
  apiGet(`/api/v1/search?${searchParams}`).then((response) => {
    dispatch({ type: "SEARCH_FETCHED", results: response.body.data[0] });
  });
};

export const changeAdvancedSearchTerm = (values, dispatch) => {
  dispatch({
    type: "ADVANCED_SEARCH_TERM_CHANGED",
    term: values?.nameUnaccent,
  });
};
