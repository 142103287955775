import React from "react";
import { Route } from "react-router-dom";
import Lobby from "../components/Lobby";
import SignInFormContainer from "../components/signIn/SignInFormContainer";
import RecoveryPasswordFormContainer from "../components/recoveryPassword/RecoveryPasswordFormContainer";
import NewPasswordFormContainer from "../components/recoveryPassword/NewPasswordFormContainer";
import RequireSignIn from "../components/lobby/RequireSignIn";

export default function LobbyRoutes(props) {
  return (
    <Lobby {...props}>
      <Route path="/signin" component={SignInFormContainer} exact />
      <Route
        path="/forgotten-password"
        component={RecoveryPasswordFormContainer}
        exact
      />
      <Route
        path="/reset-password"
        component={NewPasswordFormContainer}
        exact
      />
      <Route path="*" component={RequireSignIn} />
    </Lobby>
  );
}
