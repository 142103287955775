import React from "react";
import { Form, TextAreaField } from "../library/forms";
import RatingField from "../library/forms/RatingField";
import { spacing, uiColors } from "../library/theme";
import Button from "../library/Button";
import { css } from "glamor";

const container = css({
  backgroundColor: uiColors.contentCards,
  padding: `0 ${spacing.s} ${spacing.s} ${spacing.s}`,
  marginBottom: spacing.m,
});
const buttonStyle = {
  width: "100px",
  margin: `${spacing.s} calc(50% - 50px) 0 calc(50% - 50px)`,
};
const ratingBox = css({
  margin: 0,
  paddingBottom: 0,
});

export default function RatingForm(props) {
  return (
    <Form
      onSubmit={props.handleSubmit}
      className={container.toString()}
      error={props.error}
    >
      <RatingField
        name="value"
        title="Em uma escala de 1-5 o quanto você indicaria este curso a um amigo?"
        defaultValue={1}
        className={ratingBox}
        required
      />
      <TextAreaField
        name="text"
        placeholder="Justifique sua resposta"
        maxLength={140}
        rows={5}
        required
      />
      <Button size="small" style={buttonStyle}>
        Responder
      </Button>
    </Form>
  );
}
