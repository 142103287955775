import React from "react";
import { css } from "glamor";
import { spacing } from "../library/theme";
import { Form, TextField } from "../library/forms";
import Button from "../library/Button";

const fieldsWrapper = css({
  display: "flex",
});
const buttonStyle = {
  marginLeft: spacing.xs,
};

export default function CommentForm(props) {
  return (
    <Form onSubmit={props.handleSubmit}>
      <div className={fieldsWrapper}>
        <TextField name="text" placeholder="Mensagem" width="100%" fluid />
        <Button
          loading={props.submitting}
          disabled={props.invalid || props.submitting}
          icon="send"
          color="red"
          style={buttonStyle}
        />
      </div>
    </Form>
  );
}
