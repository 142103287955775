import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { reducer as form } from "redux-form";
import usersReducer from "./usersReducer";
import currentUserReducer from "./currentUserReducer";
import currentLessonReducer from "./currentLessonReducer";
import currentClassroomReducer from "./currentClassroomReducer";
import classroomsReducer from "./classroomsReducer";
import levelGroupsReducer from "./levelGroupsReducer";
import levelsReducer from "./levelsReducer";
import lessonsReducer from "./lessonsReducer";
import annotationsReducer from "./annotationsReducer";
import attachmentsReducer from "./attachmentsReducer";
import commentsReducer from "./commentsReducer";
import commentsListReducer from "./commentsListReducer";
import notifier from "./notifierReducer";
import bannersReducer from "./bannersReducer";
import lessonsUsersReducer from "./lessonsUsersReducer";
import classroomListReducer from "./classroomListReducer";
import ratingsReducer from "./ratingsReducer";
import configsReducer from "./configsReducer";
import termsReducer from "./termsReducer";
import repliesReducer from "./repliesReducer";
import searchResultsReducer from "./searchResultsReducer";
import trailsReducer from "./trailsReducer";
import tagsReducer from "./tagsReducer";
import subscriptionsReducer from "./subscriptionsReducer";
import subscriptionListReducer from "./subscriptionListReducer";
import recommendationsReducer from "./recommendationsReducer";
import recommendationListReducer from "./recommendationListReducer";
import calculatorsReducer from "./calculatorsReducer";
import recipeListReducer from "./recipeListReducer";
import recipesReducer from "./recipesReducer";
import themesReducer from "./themesReducer";

export default (history) =>
  combineReducers({
    entities: combineReducers({
      annotations: annotationsReducer,
      attachments: attachmentsReducer,
      banners: bannersReducer,
      classrooms: classroomsReducer,
      calculators: calculatorsReducer,
      recommendations: recommendationsReducer,
      subscriptions: subscriptionsReducer,
      comments: commentsReducer,
      recipes: recipesReducer,
      themes: themesReducer,
      replies: repliesReducer,
      configs: configsReducer,
      levelGroups: levelGroupsReducer,
      lessons: lessonsReducer,
      lessonsUsers: lessonsUsersReducer,
      ratings: ratingsReducer,
      levels: levelsReducer,
      users: usersReducer,
      terms: termsReducer,
      trails: trailsReducer,
      subscriptions: subscriptionsReducer,
      tags: tagsReducer,
    }),
    searchResults: searchResultsReducer,
    currentUserId: currentUserReducer,
    currentClassroomId: currentClassroomReducer,
    currentLessonId: currentLessonReducer,
    router: connectRouter(history),
    commentsList: commentsListReducer,
    classroomList: classroomListReducer,
    recommendationList: recommendationListReducer,
    subscriptionList: subscriptionListReducer,
    recipeList: recipeListReducer,
    form,
    notifier,
  });
