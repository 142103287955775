import { createSelector } from "reselect";

const getTags = (state) => state.entities.tags;
const getId = (_, id) => id;

export const getAllTags = createSelector(getTags, (tags) =>
  Object.values(tags)
);

export const getAllTagsExcept = createSelector(
  getAllTags,
  getId,
  (tags, tagId) => tags.filter((tag) => tag.id != tagId)
);

export const getTagById = createSelector(
  getTags,
  getId,
  (tags, id) => tags[id]
);
