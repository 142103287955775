import React from "react";
import { connect } from "react-redux";
import TrailListItem from "./TrailListItem";

function TrailListItemContainer(props) {
  return <TrailListItem {...props} />;
}

function mapStateToProps(_, ownProps) {
  const trail = ownProps.trail;

  return {
    trail,
  };
}

export default connect(mapStateToProps)(TrailListItemContainer);
