import { apiGet } from "./helpers/api";
import { normalize, schema } from "normalizr";

export const lesson = new schema.Entity("lessons");

export const fetchLessons = (dispatch, classroomId) => {
  apiGet(`/api/v1/classrooms/${classroomId}/lessons`).then((response) => {
    dispatch({
      type: "LESSONS_FETCHED",
      ...normalize(response.body.data, new schema.Array(lesson)),
    });
  });
};
