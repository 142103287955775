import React from "react";
import { css } from "glamor";
import { colors, spacing, typographyColors } from "../library/theme";
import { Progress, Icon, Modal } from "semantic-ui-react";
import LessonListContainer from "../lessons/LessonListContainer";
import Spinner from "../library/Spinner";
import Button from "../library/Button";
import Link from "../library/Link";
import RatingFormContainer from "./RatingFormContainer";
import Title from "../library/Title";
import Paragraph from "../library/Paragraph";

const imageContainer = css({
  height: "0",
  paddingBottom: "40%",
  backgroundSize: "cover",
  backgroundPosition: "center",
});
const progressClass = css({
  marginBottom: `${spacing.xxs} !important`,
  borderRadius: "10px !important",
  "& .bar": {
    height: "10px !important",
    borderRadius: "10px !important",
    backgroundColor: "#61EA20 !important",
  },
});
const descriptionClass = css({
  overflowWrap: "break-word",
  wordBreak: "break-word",
  hyphens: "auto",
  fontSize: "1.1rem",
  lineHeight: "1.5rem",
  paddingBottom: spacing.m,
  paddingTop: spacing.s,
  fontWeight: "300",
});
const progressWrapper = css({
  paddingTop: spacing.s,
  paddingBottom: spacing.m,
  textAlign: "left",
});
const content = css({
  padding: spacing.s,
  paddingTop: spacing.m,
});
const ratingClass = css({
  width: "100%",
  padding: "0 !important",
});
const difficultyClass = css({
  display: "flex",
  alignItems: "center",
  paddingBottom: spacing.xs,
  marginBottom: 0,
  fontWeight: "400",
  color: typographyColors.subtitle,
  fontSize: "15px",
});
const difficulties = {
  easy: { label: "Básico", color: "green" },
  medium: { label: "Intermediário", color: "yellow" },
  hard: { label: "Avançado", color: "red" },
};

export default function Lessons(props) {
  const {
    classroom,
    canRate,
    lessonsCount,
    hideProgress,
    locked,
    subscriptionId,
  } = props;
  const progressValue = classroom?.meta.progress * 100;

  return classroom ? (
    <>
      <div
        className={css(imageContainer, {
          backgroundImage: `url(${classroom?.imageHorizontalUrl})`,
        })}
      ></div>
      <Modal.Content>
        <div className={content.toString()}>
          <Title style={{ fontSize: "27px", color: colors.greyDark }} strong>
            {classroom?.name.toUpperCase()}
          </Title>
          {classroom?.difficulty && (
            <p className={difficultyClass}>
              <Icon
                name="circle"
                color={difficulties[classroom?.difficulty].color}
                size="tiny"
              />
              {difficulties[classroom?.difficulty].label.toUpperCase()} -{" "}
              {lessonsCount} AULAS
            </p>
          )}
          {classroom?.description && (
            <Paragraph className={descriptionClass.toString()}>
              {classroom?.description}
            </Paragraph>
          )}
          {!hideProgress && (
            <div className={progressWrapper}>
              <small style={{ textAlign: "right" }}>{`${progressValue.toFixed(
                0
              )}%`}</small>
              <Progress
                percent={progressValue}
                className={progressClass.toString()}
                size="tiny"
              ></Progress>
            </div>
          )}
          {progressValue >= 80 &&
            !classroom?.isCertificateOnlyBySubscription &&
            !locked && (
              <div style={{ marginBottom: spacing.m }}>
                <Link
                  component={Button}
                  href={`/classrooms/${classroom?.id}/certificates`}
                  target="_blank"
                  size="large"
                  color="red"
                  fluid
                  basic
                >
                  <Icon name="id badge outline" />
                  Baixar Certificado
                </Link>
              </div>
            )}
          <div className={ratingClass}>
            {canRate && <RatingFormContainer />}
          </div>
          {locked && (
            <Link
              component={Button}
              href={classroom.linkUrl}
              target="_blank"
              size="large"
              color="red"
              fluid
            >
              <Icon name="lock" />
              QUERO COMEÇAR AGORA
            </Link>
          )}
          <Title
            style={{
              fontSize: "22px",
              color: colors.greyDark,
              marginBottom: spacing.l,
            }}
            strong
          >
            Aulas ({lessonsCount})
          </Title>
          <LessonListContainer
            classroom={classroom}
            hideProgress={hideProgress}
            locked={locked}
            subscriptionId={subscriptionId}
          />
        </div>
      </Modal.Content>
    </>
  ) : (
    <Spinner text="Carregando informações do curso..." />
  );
}
