import React from "react";
import Link from "../library/Link";
import Image from "../library/Image";
import { spacing, styles } from "../library/theme";
import { css } from "glamor";
import Carousel from "../library/Carousel";

const container = css(styles.roundedCorners, {
  overflow: "hidden",
  padding: 0,
  marginBottom: spacing.m,
  marginRight: spacing.m,
});

export default function Banners(props) {
  const { banners } = props;
  return banners.length > 0 ? (
    <div className={container}>
      <Carousel showIndicators={false} showThumbs={false} showStatus={false}>
        {banners.map((banner) => (
          <Link href={banner.url} target={banner.external ? "_blank" : "_self"}>
            <Image src={banner.imageUrl} width="100%" height="auto" />
          </Link>
        ))}
      </Carousel>
    </div>
  ) : null;
}
