import React from "react";
import { Grid, TextField, DropdownField, spacing } from "../../library";

const columnStyle = { marginBottom: spacing.m };

const eventTypeOptions = [
  { text: "Casamento", value: 8, key: "wedding" },
  { text: "Bodas", value: 6, key: "anniversary" },
  { text: "Aniversário", value: 5, key: "birthday" },
  { text: "Batizado/Chá de bebê", value: 4, key: "baptismOrBabyShower" },
  { text: "Festa em bar", value: 2, key: "barParty" },
  { text: "Corporativo", value: 3, key: "corporateEvent" },
];
const periodOptions = [
  { text: "Manhã", value: 5, key: "morning" },
  { text: "Tarde", value: 7, key: "afternoon" },
  { text: "Noite", value: 6, key: "evening" },
];
const fullPartyOptions = [
  { text: "Sim", value: 3, key: "withCake" },
  { text: "Não", value: 6, key: "withoutCake" },
];
const abundanceOptions = [
  { text: "Na medida", value: 3, key: "justEnough" },
  {
    text: "Fartura para repor a mesa e ainda convidado levar",
    value: 12,
    key: "guestsWillTakeHome",
  },
];
const withAlcoholicDrinksOptions = [
  { text: "Sim", value: 5, key: "hardDrinksPresent" },
  { text: "Não", value: 7, key: "justSoftDrinks" },
];

export default function SweetsPerGuestCalculatorFields() {
  return (
    <>
      <Grid.Column style={columnStyle}>
        <TextField
          name="quantity"
          label="Para quantos convidados é a festa?"
          placeholder="Quantidade"
          required
          fluid
        />
      </Grid.Column>
      <Grid.Column style={columnStyle}>
        <DropdownField
          name="eventType"
          label="Qual é o tipo de evento?"
          options={eventTypeOptions}
          placeholder="Selecione uma opção"
          required
          fluid
        />
      </Grid.Column>
      <Grid.Column style={columnStyle}>
        <DropdownField
          name="period"
          label="Em qual período do dia será a festa?"
          options={periodOptions}
          placeholder="Selecione uma opção"
          required
          fluid
        />
      </Grid.Column>
      <Grid.Column style={columnStyle}>
        <DropdownField
          name="fullParty"
          label="Além dos doces, vai ter bolo ou sobremesa?"
          options={fullPartyOptions}
          placeholder="Selecione uma opção"
          required
          fluid
        />
      </Grid.Column>
      <Grid.Column style={columnStyle}>
        <DropdownField
          name="abundance"
          label="Seu cliente quer doces na medida ou sobrando?"
          options={abundanceOptions}
          placeholder="Selecione uma opção"
          required
          fluid
        />
      </Grid.Column>
      <Grid.Column style={columnStyle}>
        <DropdownField
          name="withAlcoholicDrinks"
          label="Vai ter bebida alcóolica no evento?"
          options={withAlcoholicDrinksOptions}
          placeholder="Selecione uma opção"
          required
          fluid
        />
      </Grid.Column>
    </>
  );
}
