import React from "react";
import { Comment } from "semantic-ui-react";
import { fromNow } from "../../utils/formatDate";
import { utilityColors, spacing, colors } from "../library/theme";
import Link from "../library/Link";
import { css } from "glamor";

const deleteAndInfoClass = css({
  display: "flex",
});
const linkStyle = {
  cursor: "pointer",
  color: utilityColors.danger,
  paddingTop: spacing.xxs,
  display: "block",
  textDecoration: "underline",
};
const pendingClass = css({
  marginLeft: spacing.s,
});
const textClass = css({
  fontWeight: "normal",
});
const metaClass = css({
  fontWeight: "bold",
});
const adminNameClass = css({
  color: `${colors.brandDark} !important`,
});

export default function ReplyListItem(props) {
  const { reply, currentUser, deleteReplyHandler } = props;

  return (
    <Comment>
      <Comment.Avatar src={reply.meta.user.avatarUrl} />
      <Comment.Content>
        <Comment.Author
          as="a"
          className={reply.meta.user.isAdmin && adminNameClass}
        >
          {reply.meta.user.name}
        </Comment.Author>
        <Comment.Metadata className={metaClass}>
          {fromNow(reply.createdAt)}
        </Comment.Metadata>
        <Comment.Text className={textClass}>{reply.text}</Comment.Text>
        {currentUser.id === reply.meta.user.id && (
          <div className={deleteAndInfoClass}>
            <Link onClick={deleteReplyHandler} style={linkStyle} noHref>
              Excluir Resposta
            </Link>
            {reply.pristine && !currentUser.isAdmin && (
              <p className={pendingClass}>
                (Esta resposta só será exibida após aprovação.)
              </p>
            )}
          </div>
        )}
      </Comment.Content>
    </Comment>
  );
}
