import React from "react";
import { Comment } from "semantic-ui-react";
import { fromNow } from "../../utils/formatDate";
import Link from "../library/Link";
import { colors, spacing, utilityColors } from "../library/theme";
import ReplyFormContainer from "./ReplyFormContainer";
import ReplyListItemContainer from "./ReplyListItemContainer";
import { css } from "glamor";

const deleteAndInfoClass = css({
  display: "flex",
});
const linkStyle = {
  cursor: "pointer",
  color: utilityColors.danger,
  paddingTop: spacing.xxs,
  display: "block",
  textDecoration: "underline",
};
const pendingClass = css({
  marginLeft: spacing.s,
});
const textClass = css({
  fontWeight: "normal",
});
const metaClass = css({
  fontWeight: "bold",
});
const adminNameClass = css({
  color: `${colors.brandDark} !important`,
});

export default function CommentListItem(props) {
  const { comment, currentUser, deleteHandler, replies } = props;
  return (
    <Comment>
      <Comment.Avatar src={comment.meta.user.avatarUrl} />
      <Comment.Content>
        <Comment.Author
          as="a"
          className={comment.meta.user.isAdmin && adminNameClass}
        >
          {comment.meta.user.name}
        </Comment.Author>
        <Comment.Metadata className={metaClass}>
          {fromNow(comment.createdAt)}
        </Comment.Metadata>
        <Comment.Text className={textClass}>{comment.text}</Comment.Text>
        {currentUser?.id === comment?.userId && (
          <div className={deleteAndInfoClass}>
            <Link onClick={deleteHandler} style={linkStyle} noHref>
              Excluir Comentário
            </Link>
            {comment.pristine && !currentUser.isAdmin && (
              <p className={pendingClass}>
                (Este comentário só será exibido após aprovação.)
              </p>
            )}
          </div>
        )}
      </Comment.Content>
      <Comment.Group>
        {replies.length > 0 &&
          replies.map((reply) => (
            <ReplyListItemContainer reply={reply} key={`reply${reply.id}`} />
          ))}
        <Comment>
          <ReplyFormContainer
            form={`ReplyComment${comment?.id}Form`}
            comment={comment}
          />
        </Comment>
      </Comment.Group>
    </Comment>
  );
}
