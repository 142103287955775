import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import {
  changeAdvancedSearchTerm,
  submitSearch,
} from "../../processes/searchProcesses";
import debounce from "../../utils/debounce";
import AdvancedSearchForm from "./AdvancedSearchForm";
import {
  getSearchParams,
  getAdvancedSearchResults,
} from "../../selectors/searchSelectors";

function AdvancedSearchFormContainer(props) {
  const { dispatch, nameUnaccent } = props;

  const handleTabChange = useCallback((_, { panes, activeIndex }) => {
    dispatch({
      type: `ADVANCED_SEARCH_ENTITY_CHANGED`,
      entity: panes[activeIndex].entity,
    });
  }, []);

  useEffect(() => {
    submitSearch({ nameUnaccent }, dispatch);
  }, [dispatch, nameUnaccent]);

  return <AdvancedSearchForm onTabChange={handleTabChange} {...props} />;
}
function mapStateToProps(state, ownProps) {
  const initialNameUnaccent = ownProps.match.params.nameUnaccent;
  const { entity, term: nameUnaccent, loading } = getSearchParams(state);

  return {
    initialValues: { nameUnaccent: initialNameUnaccent },
    searchResults: getAdvancedSearchResults(state, { entity }),
    loading,
    nameUnaccent,
    entity,
  };
}
export default connect(mapStateToProps)(
  reduxForm({
    form: "advancedSearchForm",
    onChange: debounce(changeAdvancedSearchTerm, 1000),
  })(AdvancedSearchFormContainer)
);
