import React, { useState, useCallback, useMemo } from "react";
import { css } from "glamor";
import { spacing, uiColors } from "../library/theme";
import LessonListContainer from "./LessonListContainer";
import Video from "./Video";
import Spinner from "../library/Spinner";
import AnnotationsContainer from "../annotations/AnnotationsContainer";
import AttachmentListContainer from "../attachments/AttachmentListContainer";
import CommentsContainer from "../comments/CommentsContainer";
import { Grid, Sidebar, Icon, Tab } from "semantic-ui-react";
import Button from "../library/Button";
import Link from "../library/Link";
import Rating from "../library/Rating";
import { getChatUrl } from "../../utils/formatVideoCode";
import Chat from "./Chat";

const contentStyle = {
  paddingTop: "20px",
};
const sidebarStyle = {
  minHeight: "100vh",
};
const leftSidebarStyle = {
  maxHeight: `calc(100vh - 90px)`,
  overflowY: "auto",
};
const sidebarPushableStyle = {
  height: `calc(100vh - 90px)`,
  overflowY: "hidden",
};
const titleClass = css({ margin: 0 });
const titleAndButton = css({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: spacing.m,
  backgroundColor: uiColors.background,
});
const titleSidebar = { padding: 0, margin: 0 };
const rightSidebarStyle = { textAlign: "right", paddingBottom: spacing.m };
const descriptionStyle = { paddingTop: spacing.m, fontWeight: "normal" };
const butonsWrappper = css(titleAndButton, {
  padding: 0,
  marginTop: spacing.m,
  marginBottom: spacing.m,
});
const iconEditClass = css({
  cursor: "pointer",
});

export default function Lesson({
  lesson,
  lessonProgressHandler,
  currentClassroomId,
  nextLesson,
  prevLesson,
  progress,
  canRate,
  lessonRatingHandler,
  subscriptionId,
}) {
  const [showLeftSidebar, setShowLeftSidebar] = useState(false);
  const [showRightSidebar, setShowRightSidebar] = useState(false);
  const toogleLeftSidebar = useCallback(
    () => setShowLeftSidebar(!showLeftSidebar),
    [showLeftSidebar]
  );
  const toogleRightSidebar = useCallback(
    () => setShowRightSidebar(!showRightSidebar),
    [showRightSidebar]
  );
  const hideLeftSidebar = useCallback(() => setShowLeftSidebar(false));

  const panes = [
    {
      menuItem: { content: "COMENTÁRIOS", icon: "discussions" },
      render: () => (
        <Tab.Pane attached={false}>
          <CommentsContainer lessonId={lesson.id} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: { content: "ARQUIVOS", icon: "download" },
      render: () => (
        <Tab.Pane attached={false}>
          <AttachmentListContainer title="Arquivos" lessonId={lesson.id} />
        </Tab.Pane>
      ),
    },
  ];

  const leftSidebarContent = () => (
    <>
      <div className={titleAndButton}>
        <h3 style={titleSidebar}>Aulas</h3>
        {showLeftSidebar && (
          <Button
            basic
            color="red"
            onClick={toogleLeftSidebar}
            icon="close"
            size="small"
          ></Button>
        )}
      </div>
      <LessonListContainer
        hideLeftSidebarHandler={hideLeftSidebar}
        sidebar
        subscriptionId={subscriptionId}
      />
    </>
  );
  const LeftSidebar = () =>
    useMemo(
      () =>
        !lesson?.live && (
          <Grid.Column only="computer" computer={5} style={leftSidebarStyle}>
            {leftSidebarContent()}
          </Grid.Column>
        ),
      [lesson]
    );

  const rightSidebarContent = () => (
    <>
      <div className={titleAndButton}>
        <h3 style={titleSidebar}>Anotações</h3>
        {showRightSidebar && (
          <Button
            basic
            color="red"
            onClick={toogleRightSidebar}
            icon="close"
            size="small"
          ></Button>
        )}
      </div>
      <AnnotationsContainer lessonId={lesson.id} />
    </>
  );
  const ChatContent = () =>
    useMemo(
      () =>
        lesson?.live && (
          <Grid.Column computer={7} mobile={16}>
            <Chat url={getChatUrl(lesson)} />
          </Grid.Column>
        ),
      [lesson]
    );
  const EditContent = () =>
    useMemo(
      () =>
        !lesson?.live && (
          <Grid.Column width={2} style={rightSidebarStyle}>
            <Icon
              className={iconEditClass}
              color="red"
              onClick={toogleRightSidebar}
              name="edit outline"
              size="large"
            />
          </Grid.Column>
        ),
      [lesson]
    );

  const computerSize = lesson?.live ? 9 : 10;

  return lesson ? (
    <Sidebar.Pushable style={sidebarPushableStyle}>
      <Sidebar
        animation="overlay"
        icon="labeled"
        visible={showLeftSidebar}
        style={sidebarStyle}
        width="wide"
      >
        {leftSidebarContent()}
      </Sidebar>
      <Sidebar
        animation="overlay"
        icon="labeled"
        visible={showRightSidebar}
        style={sidebarStyle}
        direction="right"
        width="wide"
      >
        {rightSidebarContent()}
      </Sidebar>
      <Sidebar.Pusher>
        <Grid columns="equal" padded centered style={contentStyle}>
          <LeftSidebar />
          <Grid.Column
            computer={computerSize}
            mobile={16}
            style={leftSidebarStyle}
          >
            <Grid columns={2} padded>
              <Grid.Column width={14}>
                <h2 className={titleClass}>{lesson.name}</h2>
              </Grid.Column>
              <EditContent />
            </Grid>
            {lesson.videoUrl && (
              <Video
                url={lesson.videoUrl}
                isWatched={lesson.watched}
                lessonProgressHandler={lessonProgressHandler}
                progress={progress}
              />
            )}
            {canRate && (
              <Rating title={"Avalie esta aula"} onRate={lessonRatingHandler} />
            )}

            {lesson.description && (
              <div
                style={descriptionStyle}
                dangerouslySetInnerHTML={{
                  __html: lesson.description,
                }}
              ></div>
            )}
            <div className={butonsWrappper}>
              <Link
                to={`/subscriptions/${subscriptionId}/classrooms/${currentClassroomId}/lessons/${prevLesson?.id}`}
                component={Button}
                basic
                color="red"
                disabled={!prevLesson}
              >
                <Icon name="angle left" />
                Aula Anterior
              </Link>
              <Link
                to={`/subscriptions/${subscriptionId}/classrooms/${currentClassroomId}/lessons/${nextLesson?.id}`}
                component={Button}
                basic
                color="red"
                disabled={!nextLesson}
              >
                Próxima Aula
                <Icon name="angle right" />
              </Link>
            </div>
            <Tab
              menu={{ color: "red", secondary: true, pointing: true }}
              panes={panes}
            />
          </Grid.Column>
          <ChatContent />
        </Grid>
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  ) : (
    <Spinner text="Carregando informações da aula..." />
  );
}
