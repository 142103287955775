import React from "react";
import { DropdownField } from "../../library";

const shapeOptions = [
  { text: "Redonda", value: "circular", key: "circular" },
  { text: "Retangular/Quadrada", value: "retangular", key: "retangular" },
];

export default function ShapeField({
  shapeFieldName = "shape",
  shapeLabel = "Formato da fôrma",
  options = shapeOptions,
}) {
  return (
    <DropdownField
      name={shapeFieldName}
      label={shapeLabel}
      options={options}
      placeholder="Selecione uma opção"
      errorMessageDisabled
      required
      fluid
    />
  );
}
