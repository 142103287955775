import { createSelector } from "reselect";
import orderByPosition from "../utils/orderByPosition";
import orderByOrdering from "../utils/orderByOrdering";
import { getTrailById } from "./trailSelectors";

export const getId = (_, id) => id;
export const getClassrooms = (state) => state.entities.classrooms;
export const getCurrentClassroomId = (state) => state.currentClassroomId;
export const getClassroomId = (state, id) => id || getCurrentClassroomId(state);
const getFetchedIds = (state) => state.classroomList.classroomIds;
const getTrailClassroomIds = (state, id) =>
  getTrailById(state, id)?.classroomIds;

export const getAllClassrooms = createSelector(getClassrooms, (classrooms) =>
  Object.values(classrooms).sort(orderByPosition)
);

export const hasClassroomCertificate = createSelector(
  getAllClassrooms,
  (classrooms) => classrooms.some((classroom) => classroom.meta.progress >= 0.8)
);

export const currentClassroom = createSelector(
  getClassrooms,
  getCurrentClassroomId,
  (classrooms, id) => classrooms[id]
);

export const listOfClassrooms = createSelector(
  getClassrooms,
  getFetchedIds,
  (classrooms, ids) =>
    Object.values(classrooms)
      .filter((classroom) => ids.includes(classroom.id))
      .sort(orderByPosition)
);

export const listOfClassroomsWithProgressBySubscription = createSelector(
  getClassrooms,
  getId,
  getFetchedIds,
  (classrooms, subscriptionId, ids) =>
    Object.values(classrooms)
      .filter((classroom) => ids.includes(classroom.id))
      .filter((classroom) =>
        classroom.meta.subscriptionsIds.includes(subscriptionId)
      )
      .filter(
        (classroom) =>
          classroom.meta.progress > 0 && classroom.meta.progress < 1
      )
      .sort(orderByPosition)
);

export const listOfClassroomsByCurrentTrail = createSelector(
  getClassrooms,
  getTrailClassroomIds,
  (classrooms, ids = []) =>
    Object.values(classrooms)
      .filter((classroom) => ids.includes(classroom.id))
      .map((classroom) => ({
        ...classroom,
        ordering: ids.indexOf(classroom.id),
      }))
      .sort(orderByOrdering)
);

export const getClassroomById = createSelector(
  getClassrooms,
  getClassroomId,
  (classrooms, classroomId) => classrooms[classroomId]
);

export const getClassroomsByTagId = createSelector(
  getAllClassrooms,
  getClassroomId,
  (classrooms, tagId) => {
    return classrooms.filter((classroom) => classroom.tags.includes(tagId));
  }
);

export const getClassroomsByFetchedIds = createSelector(
  getAllClassrooms,
  getFetchedIds,
  (classrooms, ids) =>
    classrooms.filter((classroom) => ids.includes(classroom.id))
);
