import { css } from "glamor";
import React from "react";
import Button from "../library/Button";
import Link from "../library/Link";
import Form from "../library/forms/Form";
import { spacing } from "../library/theme";
import { RecommendationsTypeOptionsFields } from "./RecommendationsTypeOptionsFields";
import { SupplierFields } from "./SupplierFields";
import { CandyStoreFields } from "./CandyStoreFields";

const formContainer = css({
  padding: `${spacing.m} ${spacing.l}`,
});
const buttonStyle = {
  display: "flex",
  gap: spacing.m,
  marginTop: spacing.m,
  alignItems: "center",
  justifyContent: "center",
  minWidth: 100,
};
const linkClass = css({
  ".ui.basic.button": {
    color: "black !important",
    backgroundColor: "white !important",
  },
});
const buttonWrapper = css({ display: "flex", justifyContent: "flex-end" });

export function CreateRecommendationsForm(props) {
  const {
    handleSubmit,
    submitting,
    pristine,
    invalid,
    nextStep,
    nextStepHandler,
    type,
    isSupplier,
    isCandyStore,
  } = props;

  return (
    <Form onSubmit={handleSubmit}>
      <div className={formContainer}>
        {!nextStep && <RecommendationsTypeOptionsFields />}

        {nextStep && isSupplier && <SupplierFields {...props} />}
        {nextStep && isCandyStore && <CandyStoreFields {...props} />}

        <div className={buttonWrapper}>
          {nextStep ? (
            <>
              <Link
                to="/recommendations/"
                className={linkClass.toString()}
                style={buttonStyle}
                component={Button}
                basic
                type="button"
              >
                Cancelar
              </Link>

              <Button
                style={buttonStyle}
                color="red"
                type="submit"
                loading={submitting}
                disabled={submitting || pristine || invalid}
              >
                Indicar
              </Button>
            </>
          ) : (
            <Button
              onClick={nextStepHandler}
              disabled={!type}
              color="red"
              type="button"
            >
              Continuar
            </Button>
          )}
        </div>
      </div>
    </Form>
  );
}
