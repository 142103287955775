import {
  calculateCircleArea,
  calculateRetangularArea,
  round,
  getInput,
} from "../utils/calculateHelpers";

const ORIGINAL_DIAMETER = 21;

export default function calculate({ calculator, calculatorFormValues }) {
  const { shape, height, width, diameter } = calculatorFormValues;

  const quantitySum = calculator?.meta?.supplies?.reduce(
    (acc, { quantity }) => +acc + +quantity,
    0
  );

  const originalArea = calculateCircleArea(ORIGINAL_DIAMETER);

  const desiredArea =
    shape == "retangular"
      ? calculateRetangularArea(height, width)
      : calculateCircleArea(diameter);

  const factor = desiredArea / originalArea;

  return {
    title: calculator.name,
    imageUrl: calculator.imageUrl,
    ctaLabel: calculator.ctaLabel,
    ctaLink: calculator.ctaLink,
    supplies: calculator.meta.supplies.map(({ quantity, ...attrs }) => {
      return { quantity: round(quantity * factor), ...attrs };
    }),
    factor,
  };
}
