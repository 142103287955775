import { createSelector } from "reselect";
import { getLessonId } from "./lessonSelectors";
import { getCurrentUserId } from "./userSelectors";

const getLessonsUsers = (state) => state.entities.lessonsUsers;

export const getLessonsUser = createSelector(
  getLessonsUsers,
  getLessonId,
  getCurrentUserId,
  (lessonsUsers, lessonId, currentUserId) =>
    Object.values(lessonsUsers).filter(
      (lessonsUser) =>
        lessonsUser.lessonId === lessonId &&
        lessonsUser.userId === currentUserId
    )[0]
);

export const makeIsWatched = createSelector(
  getLessonsUser,
  (lessonsUser) => lessonsUser?.watched
);

export const makeCurrentProgress = createSelector(
  getLessonsUser,
  (lessonsUser) => lessonsUser?.progress
);
