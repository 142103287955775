import React, { useEffect } from "react";
import { connect } from "react-redux";
import Classrooms from "./Classrooms";
import { listOfLockedClassrooms } from "../../selectors/subscriptionSelectors";
import { fetchClassrooms } from "../../processes/classroomProcesses";

function LockedClassroomsContainer(props) {
  const { dispatch, tag } = props;
  const params = { tagsIdIn: tag?.id };

  useEffect(() => {
    fetchClassrooms(dispatch, params);
  }, [dispatch, tag]);

  return (
    <Classrooms
      title="Conheça todos os cursos da assinatura completa"
      icon="lock"
      iconColor="grey"
      hideProgress
      locked
      {...props}
    />
  );
}

function mapStateToProps(state) {
  return {
    loading: state.classroomList.loading,
    classrooms: listOfLockedClassrooms(state),
  };
}

export default connect(mapStateToProps)(LockedClassroomsContainer);
