const getCalculators = (action) => action.entities.calculators;

export default function calculatorsReducer(state = {}, action) {
  switch (action.type) {
    case "CALCULATORS_FETCHED":
      return { ...state, ...getCalculators(action) };
    default:
      return state;
  }
}
