import React from "react";
import { Form, Segment, Message } from "semantic-ui-react";

export default function FormComponent(props) {
  const { children, error, ...rest } = props;
  return (
    <>
      {error && <Message content={error} error />}
      <Form {...rest} error={!!error}>
        {children}
      </Form>
    </>
  );
}
