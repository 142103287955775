import React from "react";
import { Grid, TextField } from "../../library";

export default function BrigadeiroCalculatorFields() {
  return (
    <>
      <Grid.Column>
        <TextField
          name="yield"
          label="Quantas unidades de brigadeiro você quer?"
          placeholder="Quantidade"
          required
          type="number"
          errorMessageDisabled
          step="0.1"
          fluid
        />
      </Grid.Column>
      <Grid.Column>
        <TextField
          name="portion"
          label="Com quantas gramas você vai enrolar?"
          placeholder="Gramas"
          required
          type="number"
          errorMessageDisabled
          step="0.1"
          fluid
        />
      </Grid.Column>
    </>
  );
}
