import React from "react";
import Page from "../library/Page";
import Title from "../library/Title";
import RecommendationsFilterFormContainer from "./RecommendationsFilterFormContainer";
import EmptyPreview from "../library/EmptyPreview";
import Spinner from "../library/Spinner";
import { TiLocationOutline } from "react-icons/ti";
import { AiFillInstagram } from "react-icons/ai";
import { BiLinkExternal } from "react-icons/bi";
import { Link, css, spacing, uiColors } from "../library";

const recommendationsContainer = css({
  marginTop: spacing.m,
});
const recommendationItemContainer = css({
  padding: spacing.m,
  marginBottom: spacing.m,
  borderRadius: spacing.s,
  backgroundColor: "white",
});
const recommendationItemTitleWrapper = css({
  display: "flex",
  flexDirection: "column",
  borderBottom: `1px solid ${uiColors.border}`,
  paddingBottom: spacing.s,
});
const recommendationItemInfoWrapper = css({
  display: "flex",
  gap: spacing.m,
  paddingTop: spacing.s,
  "> p": {
    marginBottom: 0,
  },
  "@media (max-width: 685px)": {
    display: "flex",
    flexDirection: "column",
    gap: spacing.xxs,
    paddingTop: spacing.s,
  },
});
const infoWrapper = css({
  display: "flex",
  alignItems: "center",
  gap: spacing.xs,
});

export function Recommendations({ recommendations, loading }) {
  return (
    <Page
      title="Indicações"
      subtitle="Descubra e compartilhe indicações: Explore um universo de serviços gerais com a nova página de indicações."
    >
      <Title strong>O que você está buscando?</Title>
      <RecommendationsFilterFormContainer />

      <div className={recommendationsContainer}>
        <>
          {loading ? (
            <Spinner style={{}} text="Buscando indicações" wrapped />
          ) : (
            <>
              {recommendations.length > 0 ? (
                recommendations?.map((recommendation) => {
                  const {
                    name,
                    reason,
                    address,
                    instagram,
                    website,
                    couponCode,
                    isCoupon,
                  } = recommendation;

                  return (
                    <div
                      key={recommendation.id}
                      className={recommendationItemContainer}
                    >
                      <div className={recommendationItemTitleWrapper}>
                        <Title as="h4" strong>
                          {name}
                        </Title>
                        <p>{reason}</p>
                        {isCoupon && <p>{couponCode}</p>}
                      </div>
                      <div className={recommendationItemInfoWrapper}>
                        {instagram && (
                          <div className={infoWrapper}>
                            <AiFillInstagram />
                            <p>{instagram}</p>
                          </div>
                        )}
                        {address && (
                          <div className={infoWrapper}>
                            <TiLocationOutline /> <p>{address}</p>
                          </div>
                        )}
                        {website && (
                          <div className={infoWrapper}>
                            <BiLinkExternal />{" "}
                            <Link href={website} target="_blank">
                              {website}
                            </Link>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })
              ) : (
                <EmptyPreview description="Indicações não encontradas." />
              )}
            </>
          )}
        </>
      </div>
    </Page>
  );
}
