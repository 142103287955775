import { connect } from "react-redux";
import buildForm from "../library/forms/buildForm";
import CommentForm from "./CommentForm";
import { createComment } from "../../processes/commentProcesses";
import { getCurrentClassroomId } from "../../selectors/classroomSelectors";

const form = buildForm("commentForm");

function mapStateToProps(state, { lessonId }) {
  const currentClassroomId = getCurrentClassroomId(state);

  return {
    initialValues: {},
    lessonId,
    currentClassroomId,
  };
}

export default connect(mapStateToProps)(
  form.connect({
    onSubmit: createComment,
  })(CommentForm)
);
