import React from "react";

const whiteList = ["/forgotten-password", "/reset-password"];

export default function RequireSignIn(props) {
  const { history, location } = props;
  React.useEffect(() => {
    if (whiteList.indexOf(location.pathname) != -1) return;
    history.push(`/signin`);
  }, [history]);
  return <div />;
}
