const getReplies = (action) => action.entities.replies;

export default function repliesReducer(state = {}, action) {
  let newState;
  switch (action.type) {
    case "REPLY_CREATED":
    case "COMMENTS_FETCHED":
      return { ...state, ...getReplies(action) };
    case "REPLY_DELETED":
      newState = { ...state };
      if (newState[action.replyId]) delete newState[action.replyId];
      return newState;
    default:
      return state;
  }
}
