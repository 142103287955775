import React from "react";
import { Field } from "redux-form";
import buildFormField from "./buildFormField";
import RatingInput from "./RatingInput";
import memoize from "fast-memoize";

const buildValidator = memoize((required, other) => {
  return [
    (value) => {
      if (required && !value) {
        return "required";
      }
      return undefined;
    },
  ].concat(other || []);
});

export default function RatingField(props) {
  const { required, validate } = props;
  const FormField = buildFormField(RatingInput, (input, meta, rest) => {
    return {
      ...input,
      autoComplete: "off",
      invalid: `${meta.touched && (meta.invalid || rest.invalid)}`,
      ...rest,
    };
  });

  return (
    <Field
      {...props}
      component={FormField}
      validate={buildValidator(required, validate)}
    />
  );
}
