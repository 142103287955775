import { apiGet } from "./helpers/api";
import { normalize, schema } from "normalizr";

export const calculatorSchema = new schema.Entity("calculators");

export const fetchCalculators = (dispatch) => {
  return apiGet("/api/v1/calculators").then((response) =>
    dispatch({
      type: "CALCULATORS_FETCHED",
      ...normalize(response.body.data, [calculatorSchema]),
    })
  );
};
