import { createSelector } from "reselect";
import { getLessonId } from "./lessonSelectors";

const getAttachments = (state) => state.entities.attachments;
const getFetchedIds = (state) => state.attachmentsList.attachmentIds;

export const getAttachmentsByLesson = createSelector(
  getLessonId,
  getAttachments,
  (lessonId, attachments) =>
    Object.values(attachments).filter(
      (attachment) => attachment.attachmentableId === lessonId
    )
);

export const getAllAttachments = createSelector(getAttachments, (attachments) =>
  Object.values(attachments)
);

export const getAttachmentsFetchedIds = createSelector(
  getAllAttachments,
  getFetchedIds,
  (attachments, ids) =>
    attachments.filter((attachment) => ids.includes(attachment.id))
);
