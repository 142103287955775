import React from "react";
import {
  Link,
  Button,
  Icon,
  spacing,
  typographyColors,
  decorationColors,
  css,
} from "../library";
import truncateString from "../../utils/truncateString";
import { AiOutlineLock } from "react-icons/ai";

const container = css({
  background: decorationColors.decorationBrownDark,
  padding: spacing.m,
  borderRadius: spacing.m,
  display: "flex",
  flexDirection: "column",
});
const mainSubscriptionClass = css({
  gridColumnEnd: "span 2",
  "@media(max-width: 768px)": {
    gridColumnEnd: "auto",
  },
});
const imageWrapper = css({
  position: "relative",
  paddingBottom: "45.25%",
  height: 0,
});
const mainImageWrapperClass = css({
  paddingBottom: "28%",
  "@media(max-width: 768px)": {
    paddingBottom: "45.25%",
  },
});
const imageClass = css({
  position: "absolute",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  border: "none",
  borderRadius: spacing.m,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center center",
});
const contentClass = css({
  flex: 1,
  display: "flex",
  gap: spacing.m,
  flexDirection: "column !important",
  alignItems: "space-between !important",
  marginTop: spacing.m,
});
const mainContent = css({
  flexDirection: "row !important",
  "@media(max-width: 768px)": {
    flexDirection: "column !important",
  },
});
const descriptionClass = css({
  flex: 1,
  color: typographyColors.inverseBody,
});
const actionClass = css({
  display: "block",
  maxHeight: 36,
  minWidth: 118,
  width: "100% ",
  marginTop: "auto !important",
  marginBottom: "auto !important",
});
const ownedActionClass = css({
  minWidth: 140,
});
const mainActionClass = css(actionClass, {
  width: 118,
  "@media(max-width: 768px)": {
    width: "100%",
  },
});

export default function HomeSubscriptionCard({
  subscription,
  hasSubscription,
}) {
  return (
    <div className={css(container, subscription.main && mainSubscriptionClass)}>
      <div
        className={css(
          imageWrapper,
          subscription.main && mainImageWrapperClass
        )}
      >
        <div
          className={css(imageClass, {
            backgroundImage: `url(${subscription.imageUrl})`,
          })}
        ></div>
      </div>
      <div className={css(contentClass, subscription.main && mainContent)}>
        <div className={descriptionClass}>
          {truncateString(subscription.description, 220)}
        </div>

        <Link
          component={Button}
          color={hasSubscription ? "white" : "red"}
          href={!hasSubscription && subscription.ctaLink}
          target={!hasSubscription && "_blank"}
          className={css(
            actionClass,
            subscription.main && mainActionClass,
            hasSubscription && ownedActionClass
          )}
          to={hasSubscription && `/subscriptions/${subscription.id}`}
        >
          {!hasSubscription && <Icon name="lock" />}
          {hasSubscription ? "Acessar curso" : "Comprar"}
        </Link>
      </div>
    </div>
  );
}
