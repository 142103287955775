import { hot } from "react-hot-loader/root";
import React from "react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import lmsReducer from "../reducers/lmsReducer";
import applyDevTools from "../lib/applyDevTools";
import Routes from "../routes";
import { getSession } from "../processes/sessionProcesses";
import applyGlobalStyles from "../components/library/applyGlobalStyles";

Bugsnag.start({
  apiKey: "1cd3994a9f9422d1d0d1a4770b510639",
  plugins: [new BugsnagPluginReact()],
});

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

const currentUserId = getSession()?.currentUserId;
const initialState = { currentUserId };
const browserHistory = createBrowserHistory();
const store = createStore(
  lmsReducer(browserHistory),
  initialState,
  compose(
    applyMiddleware(routerMiddleware(browserHistory)),
    applyDevTools(window.__REDUX_DEVTOOLS_EXTENSION__)
  )
);

applyGlobalStyles();

function LMS() {
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <Routes history={browserHistory} />
      </Provider>
    </ErrorBoundary>
  );
}

if (module.hot) {
  module.hot.accept("../routes", () => {
    APP();
  });
}

export default hot(LMS);
