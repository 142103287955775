import { css } from "glamor";
import React from "react";
import Hint from "../library/Hint";
import Title from "../library/Title";
import RadioField from "../library/forms/RadioField";
import { spacing, uiColors } from "../library/theme";

const modalTitleStyle = {
  padding: `${spacing.s} 0`,
  borderBottom: `1px solid ${uiColors.border}`,
  marginBottom: 0,
};
const typeOptionsContainer = css({
  display: "flex",
  flexDirection: "column",
  gap: spacing.xl,
  padding: `${spacing.l} 0`,
});
const typeOptionWrapper = css({
  display: "flex",
  justifyContent: "space-between",
});
const typeOptionTitleStyle = {
  marginBottom: 0,
};

export const RecommendationsTypeOptionsFields = () => {
  return (
    <>
      <Title style={modalTitleStyle} strong>
        Nova publicação
      </Title>

      <div className={typeOptionsContainer}>
        <div className={typeOptionWrapper}>
          <div>
            <Title strong style={typeOptionTitleStyle}>
              Lojas / Fornecedores
            </Title>
            <Hint>Indique seu fornecedor favorito.</Hint>
          </div>
          <RadioField name="type" value="Supplier" />
        </div>

        <div className={typeOptionWrapper}>
          <div>
            <Title strong style={typeOptionTitleStyle}>
              Confeitaria
            </Title>
            <Hint>Indique uma confeitaria para todos na plataforma.</Hint>
          </div>
          <RadioField name="type" value="CandyStore" />
        </div>
      </div>
    </>
  );
};
