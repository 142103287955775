const getConfigs = (action) => action.entities;

const initialState = {
  cities: { loading: false },
  tags: [],
  trailSurveyId: null,
};

export default function configsReducer(state = initialState, action) {
  switch (action.type) {
    case "CITIES_FETCH_REQUESTED":
      return { ...state, cities: { loading: true } };
    case "CITIES_FETCHED":
      return {
        ...state,
        cities: { ...state.cities, ...action.entities.cities, loading: false },
      };
    case "CONFIGS_FETCHED":
      return {
        ...state,
        ...getConfigs(action),
        trailSurveyId: action.trailSurveyId,
      };
    default:
      return state;
  }
}
