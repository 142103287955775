import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { fetchRecipes } from "../../processes/recipeProcesses ";
import { getAllRecipes } from "../../selectors/recipeSelectors";
import { RecipesList } from "./RecipesList";

function RecipesListContainer(props) {
  const { dispatch } = props;

  useEffect(() => {
    fetchRecipes(dispatch);
  }, [dispatch]);

  return <RecipesList {...props} />;
}

function mapStateToProps(state) {
  return {
    loading: state.recipeList.loading,
    recipes: getAllRecipes(state),
  };
}

export default connect(mapStateToProps)(RecipesListContainer);
