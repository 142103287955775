import React, { useState, useCallback } from "react";
import { connect } from "react-redux";
import buildForm from "../library/forms/buildForm";
import ProfileEditForm from "./ProfileEditForm";
import { updateProfile } from "../../processes/profileProcesses";
import { getCurrentUser } from "../../selectors/userSelectors";

const form = buildForm("profileEdit");

function ProfileEditFormContainer(props) {
  const [openTerms, setOpenTerms] = useState(false);
  const openTermsHandler = useCallback(() => {
    setOpenTerms(true);
  }, [openTerms]);
  const closeTermsHandler = useCallback(() => {
    setOpenTerms(false);
  }, [openTerms]);

  return (
    <ProfileEditForm
      openTerms={openTerms}
      openTermsHandler={openTermsHandler}
      closeTermsHandler={closeTermsHandler}
      {...props}
    />
  );
}

function mapStateToProps(state) {
  const currentUser = getCurrentUser(state);
  return {
    initialValues: currentUser,
    imageUrl: form.getValue("imageUrl", state),
    currentUser,
  };
}

export default connect(mapStateToProps)(
  form.connect({
    onSubmit: updateProfile,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(ProfileEditFormContainer)
);
