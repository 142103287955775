import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getSubscriptionById } from "../../selectors/subscriptionSelectors";
import { fetchSubscription } from "../../processes/subscriptionProcesses";
import Subscription from "./Subscription";
import { fetchClassrooms } from "../../processes/classroomProcesses";
import { listOfClassroomsWithProgressBySubscription } from "../../selectors/classroomSelectors";
import { getUserTags } from "../../selectors/configSelectors";

function SubscriptionContainer(props) {
  const { dispatch } = props;
  const subscriptionId = props?.match?.params?.id;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const isSubRoute = !!props?.match?.params?.subscriptionId;
    if (!subscriptionId || isSubRoute) return;

    setLoading(true);
    fetchSubscription(dispatch, subscriptionId)
      .then(() => {
        fetchClassrooms(dispatch);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [dispatch, subscriptionId, props?.match?.path]);

  return <Subscription loading={loading} {...props} />;
}

function mapStateToProps(state, ownProps) {
  const subscriptionId = parseInt(ownProps?.match?.params?.id);
  const subscription = getSubscriptionById(state, subscriptionId);
  const hasClassroomsInProgress = Boolean(
    listOfClassroomsWithProgressBySubscription(state, subscriptionId).length &&
      listOfClassroomsWithProgressBySubscription(state, subscriptionId).length >
        1
  );

  return {
    subscription,
    hasClassroomsInProgress,
    tags: getUserTags(state),
  };
}

export default connect(mapStateToProps)(SubscriptionContainer);
