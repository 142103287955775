import { round } from "../utils/calculateHelpers";

export default function calculate({ calculator, calculatorFormValues }) {
  const answersSum =
    calculatorFormValues.eventType +
    calculatorFormValues.period +
    calculatorFormValues.fullParty +
    calculatorFormValues.abundance +
    calculatorFormValues.withAlcoholicDrinks;

  const perGuest = +(answersSum / 5).toFixed(2);

  return {
    title: calculator.name,
    sweetAmount: +(calculatorFormValues.quantity * perGuest).toFixed(2),
    imageUrl: calculator?.imageUrl,
    perGuest,
  };
}
