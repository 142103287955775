import { getInput, getMoldFactor, round } from "../utils/calculateHelpers";

const ORIGINAL_DIAMETER = 21;
const CAKE_COVERED_TOPPING = 525;
const CAKE_SEMINAKED_TOPPING = 300;
const CAKE_RIBBON = 255 * 1.1;

export default function calculate({ calculator, calculatorFormValues }) {
  const {
    shape,
    height,
    width,
    diameter,
    layerFactor,
    cakeType,
    fruitsPresent,
    custom = false,
  } = calculatorFormValues;

  const baseYield =
    (cakeType == "covered" ? CAKE_COVERED_TOPPING : CAKE_SEMINAKED_TOPPING) +
    CAKE_RIBBON;

  const quantitySum = calculator?.meta?.supplies?.reduce(
    (acc, { quantity }) => +acc + +quantity,
    0
  );

  const moldFactor = getMoldFactor({
    originalDiameter: ORIGINAL_DIAMETER,
    shape,
    height,
    width,
    diameter,
  });

  const desiredYield = baseYield * (custom ? 1 : moldFactor);

  const factor = (desiredYield / quantitySum) * layerFactor;

  const supplyList = calculator.meta.supplies.map(({ quantity, ...attrs }) => {
    return { quantity: round(quantity * factor), ...attrs };
  });

  return {
    title: calculator.name,
    imageUrl: calculator.imageUrl,
    ctaLabel: calculator.ctaLabel,
    ctaLink: calculator.ctaLink,
    supplies: supplyList,
    factor,
  };
}
