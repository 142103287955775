import { round } from "../utils/calculateHelpers";

const BASE_GRAM = 40;

const perGuestByQuantity = (quantity) => {
  if (quantity <= 20) {
    return BASE_GRAM * 3.75;
  } else if (quantity <= 50) {
    return BASE_GRAM * 2.5;
  } else if (quantity <= 100) {
    return BASE_GRAM * 1.875;
  } else if (quantity <= 150) {
    return BASE_GRAM * 1.25;
  } else {
    return BASE_GRAM * 1;
  }
};

export default function calculate({ calculator, calculatorFormValues }) {
  const perGuest = perGuestByQuantity(calculatorFormValues.quantity);

  return {
    title: calculator.name,
    cakeAmount: +((calculatorFormValues.quantity * perGuest) / 1000).toFixed(2),
    imageUrl: calculator?.imageUrl,
    perGuest,
  };
}
