import { createSelector } from "reselect";
import { getCommentId } from "./commentSelectors";
import { orderByCreatedAtAsc } from "../utils/orderByCreatedAt";

const getReplies = (state) => state.entities.replies;

export const getRepliesByCommentId = createSelector(
  getReplies,
  getCommentId,
  (replies, commentId) =>
    Object.values(replies)
      .filter((reply) => reply.commentId === commentId)
      .sort(orderByCreatedAtAsc)
);
