import React from "react";
import { css } from "glamor";

export default function Title(props) {
  const { children, strong, as = 'h3', ...rest } = props;
  const Element = as;

  const titleClass = css({
    paddingTop: 0,
    marginTop: 0,
  }, strong && { fontWeight: 'bold' });

  return (
    <Element className={titleClass} {...rest}>
      {children}
    </Element>
  );
}
