import React from "react";
import { connect } from "react-redux";
import TrailModal from "./TrailModal";

export const TrailModalContainer = (props) => {
  return <TrailModal {...props} />;
};

const mapStateToProps = (state) => ({
  trailSurveyId: state.entities.configs.trailSurveyId,
  currentUserId: state.currentUserId,
});

export default connect(mapStateToProps)(TrailModalContainer);
