import React from "react";
import { css } from "glamor";
import LessonListItemContainer from "../lessons/LessonListItemContainer";
import { spacing, typographyColors, uiColors } from "../library/theme";

const titleContainer = css({
  padding: `${spacing.s} ${spacing.m}`,
  backgroundColor: uiColors.contentCards,
  borderBottom: `1px solid ${uiColors.border}`,
});
const titleClass = css({ color: typographyColors.defaultTitle });

export default function LevelListItem({ level, lessons }) {
  return (
    <div>
      <div className={titleContainer}>
        <h4 className={titleClass}>{level.name}</h4>
      </div>
      {lessons?.map((lesson, index) => (
        <LessonListItemContainer key={index} lesson={lesson} />
      ))}
    </div>
  );
}
