import React from "react";
import { Label, TextField, spacing } from "../../library";

const retangularStyle = {
  marginTop: spacing.xxs,
  display: "flex",
  alignItems: "center",
  gap: spacing.xxs,
  width: "100%",
};
const fieldWrapper = {
  flex: 1,
};

export default function RetangularDimensionsFields({
  label = "Dimensões (cm)",
  heightFieldName = "height",
  widthFieldName = "width",
}) {
  return (
    <>
      <Label required>{label}</Label>
      <div style={retangularStyle}>
        <div style={fieldWrapper}>
          <TextField
            name={heightFieldName}
            placeholder="XX"
            required
            type="number"
            errorMessageDisabled
            step="0.1"
            fluid
          />
        </div>
        X
        <div style={fieldWrapper}>
          <TextField
            name={widthFieldName}
            placeholder="XX"
            required
            type="number"
            errorMessageDisabled
            step="0.1"
            fluid
          />
        </div>
      </div>
    </>
  );
}
