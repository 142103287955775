import React from "react";
import { css } from "glamor";
import Button from "../library/Button";
import { Form, TextField } from "../library/forms";
import { spacing } from "../library/theme";
import { Grid, Card } from "semantic-ui-react";
import Container from "../library/Container";
import ImageField from "../library/forms/ImageField";
import TermsContainer from "../terms/TermsContainer";
import Title from "../library/Title";

const titleStyle = { paddingTop: spacing.m };
const buttonMargin = css({
  display: "flex",
  flex: 1,
  marginTop: spacing.m,
  justifyContent: "flex-end",
});
const formStyle = css({ paddingTop: spacing.m });

export default function ProfileEditForm(props) {
  const { openTerms, openTermsHandler, closeTermsHandler, currentUser } = props;

  return (
    <Container>
      <TermsContainer
        open={openTerms}
        onClose={closeTermsHandler}
        onlyPreview
      />
      <Grid padded centered>
        <Grid.Column computer={8} mobile={16}>
          <ImageField name="avatarUrl" />
          <Form className={formStyle.toString()} onSubmit={props.handleSubmit} error={props.error}>
            <TextField
              name="name"
              placeholder="Nome"
              required
              fluid
            />
            <TextField
              name="email"
              type="email"
              placeholder="E-mail"
              disabled
              required
              fluid
            />
            <Title strong style={titleStyle}>Redefinir senha</Title>
            <TextField
              name="password"
              type="password"
              icon="lock"
              iconPosition="left"
              placeholder="Nova senha"
              fluid
            />
            <TextField
              name="passwordConfirmation"
              type="password"
              icon="lock"
              iconPosition="left"
              placeholder="Repetir senha"
              fluid
            />
            <TextField
              name="currentPassword"
              type="password"
              icon="lock"
              iconPosition="left"
              hint="É necessário digitar a senha atual para confirmar. Ao atualizar a senha, você será deslogado e redirecionado para a tela de login."
              placeholder="Senha atual"
              fluid
            />
            <div className={buttonMargin}>
              <Button fluid color="red" size="large">
                Salvar
              </Button>
            </div>
            <div className={buttonMargin}>
              <Button
                basic
                fluid
                type="button"
                size="large"
                onClick={openTermsHandler}
              >
                Termos de Uso
              </Button>
            </div>
          </Form>
        </Grid.Column>
      </Grid>
    </Container>
  );
}
