export function round(number) {
  return Math.ceil(+number);
}

export function calculateCircleArea(diameter) {
  const radius = diameter / 2;
  return Math.PI * Math.pow(radius, 2);
}

export function calculateRetangularArea(height, width) {
  return height * width;
}

export function calculateTotalWeightForSupplies({
  calculator,
  calculatorFormValues,
}) {
  const factor = calculator.meta.inputs.reduce((acc, current) => {
    return acc * (calculatorFormValues[current.key] / current.defaultValue);
  }, 1);

  return {
    title: calculator.name,
    imageUrl: calculator.imageUrl,
    ctaLabel: calculator.ctaLabel,
    ctaLink: calculator.ctaLink,
    supplies: calculator.meta.supplies.map(({ quantity, ...attrs }) => {
      return { ...attrs, quantity: round(quantity * factor) };
    }),
    factor,
  };
}

export function getInput(calculator, key) {
  return calculator?.meta?.inputs?.find((input) => input.key == key)
    ?.defaultValue;
}

export function getMoldFactor({
  originalDiameter,
  shape,
  height,
  width,
  diameter,
}) {
  const originalArea = calculateCircleArea(originalDiameter);
  const desiredArea =
    shape == "retangular"
      ? calculateRetangularArea(height, width)
      : calculateCircleArea(diameter);

  return desiredArea / originalArea;
}
