import React from "react";
import { Card } from "semantic-ui-react";
import { css } from "glamor";
import Button from "../library/Button";

const cardStyle = css({
  display: "flex",
  marginTop: "0 !important",
});

const descriptionStyle = css({
  wordWrap: "break-word",
});

export default function AdvancedSearchListItem({ content }) {
  const { name, title, description, imageUrl, url, meta, previewPath } =
    content;

  return (
    <Card className={cardStyle} fluid>
      {imageUrl && (
        <div
          className={css({
            display: "block",
            width: "100%",
            height: "0px !important",
            position: "relative",
            overflow: "hidden",
            paddingBottom: "148%",
          })}
        >
          <div
            className={css({
              position: "absolute",
              top: "0",
              bottom: "0",
              left: "0",
              right: "0",
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundImage: `url(${imageUrl})`,
            })}
          ></div>
        </div>
      )}
      <Card.Content>
        <Card.Header>{name || title}</Card.Header>
        <Card.Description className={descriptionStyle}>
          {description}
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <Button
          fluid
          color="red"
          href={url || meta?.previewPath || previewPath}
          target="_blank"
        >
          {url ? "Baixar" : "Acessar"}
        </Button>
      </Card.Content>
    </Card>
  );
}
