import { apiGet, apiPost } from "./helpers/api";
import { normalize, schema } from "normalizr";
import { notifySubmitSucceeded } from "./notifierProcesses";

const ratings = new schema.Entity("ratings");

export const fetchRatings = (dispatch) => {
  apiGet("/api/v1/ratings").then((response) => {
    dispatch({
      type: "RATINGS_FETCHED",
      ...normalize(response.body.data, new schema.Array(ratings)),
    });
  });
};

const createRating = (dispatch, ratingableType, ratingableId, value, text) => {
  apiPost("/api/v1/ratings")
    .send({
      ratingableType,
      ratingableId,
      value,
      text,
    })
    .then((response) => {
      dispatch({
        type: "RATING_CREATED",
        ...normalize({ ...response.body.data }, ratings),
      });
      notifySubmitSucceeded(dispatch, "Recebemos sua avaliação. Obrigado!");
    });
};

export const createLessonRating = (dispatch, ratingableId, value) =>
  createRating(dispatch, "Lesson", ratingableId, value);

export const createClassroomRating = (values, dispatch, props) => {
  const { ratingableId } = props;
  const { value, text } = values;
  createRating(dispatch, "Classroom", ratingableId, value, text);
};
