import { SubmissionError } from "redux-form";
import { normalize, schema } from "normalizr";
import { apiPut } from "./helpers/api";
import { notifySubmitSucceeded } from "./notifierProcesses";
import { replace } from "connected-react-router";
import normalizeObject from "../lib/normalizeObject";
import { put } from "superagent";
import middleware from "./helpers/railsMiddleware";

export const user = new schema.Entity("users");

const isFile = (obj) => obj && !!obj.lastModified;

const updatedUser = (dispatch, response) => {
  dispatch({
    type: "USER_UPDATED",
    ...normalize(response.body.data, user),
  });
};

const updateAvatar = (avatar, dispatch) => {
  const req = put("/api/v1/auth").use(middleware);
  req.attach("avatar", avatar);
  return req
    .then((response) => {
      updatedUser(dispatch, response);
    })
    .catch((err) => {
      throw new SubmissionError({ _error: err.response?.body?.errors });
    });
};

export const updateProfile = (values, dispatch, props) => {
  const { avatarUrl, ...attrs } = values;
  if (isFile(avatarUrl)) updateAvatar(avatarUrl, dispatch);
  return apiPut("/api/v1/auth")
    .send(normalizeObject(attrs, "snake"))
    .then((response) => {
      updatedUser(dispatch, response);
      notifySubmitSucceeded(
        dispatch,
        "Uhuu, Seu perfil foi atualizado com sucesso!"
      );
      dispatch(replace("/"));
      props.reset();
    })
    .catch((err) => {
      throw new SubmissionError({
        _error: err.response.body.errors.full_messages[0],
      });
    });
};
