import React from "react";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import buildForm from "../../library/forms/buildForm";
import CustomCalculatorForm from "./CustomCalculatorForm";
import { getAllCalculators } from "../../../selectors/calculatorSelectors";

const CALCULATOR_FORM_NAME = "calculatorForm";
const FORM_NAME = "customCalculatorForm";
const form = buildForm(FORM_NAME);

function mapStateToProps(state, { calculator, calculate = () => {} }) {
  const customFormValues = state?.form[FORM_NAME]?.values;
  const calculatorFormValues = state?.form[CALCULATOR_FORM_NAME]?.values;

  const factor = customFormValues
    ? calculate({
        calculator: customFormValues,
        calculatorFormValues: calculatorFormValues,
        calculators: getAllCalculators(state),
        custom: true,
      })?.factor
    : 1;

  return {
    initialValues: {
      ...calculator,
      meta: {
        ...calculator.meta,
        supplies: calculator.meta.supplies.filter(
          ({ id, category }) =>
            category !== "filling" || calculatorFormValues?.fillingId == id
        ),
        inputs: calculator.meta.inputs.filter(
          ({ customEnabled }) => customEnabled
        ),
      },
    },
    factor,
  };
}

export default connect(mapStateToProps)(
  form.connect({ enableReinitialize: true })(CustomCalculatorForm)
);
