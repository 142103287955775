import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { change, clearFields, formValueSelector, reduxForm } from "redux-form";
import { submitRecipesFilter } from "../../processes/recipeProcesses ";
import { fetchThemes } from "../../processes/themeProcesses";
import { getAllThemes } from "../../selectors/themeSelectors";
import debounce from "../../utils/debounce";
import { Recipes } from "./Recipes";

const FORM_NAME = "recipesFilterForm";

function RecipesContainer(props) {
  const {
    dispatch,
    formValues: { themeIdEq, nameCont, courseNameCont, rowsPerPage },
  } = props;

  useEffect(() => {
    fetchThemes(dispatch);
  }, [dispatch]);

  const cleanFilterHandler = useCallback(() => {
    dispatch(
      clearFields(
        FORM_NAME,
        false,
        false,
        "themeIdEq",
        "nameCont",
        "courseNameCont"
      )
    );
  }, [dispatch]);

  const onPageChangeHandler = useCallback(
    (_, data) => {
      dispatch(change(FORM_NAME, "page", data?.activePage));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(change(FORM_NAME, "page", 1));
  }, [themeIdEq, nameCont, courseNameCont, rowsPerPage]);

  return (
    <Recipes
      onPageChangeHandler={onPageChangeHandler}
      cleanFilterHandler={cleanFilterHandler}
      {...props}
    />
  );
}

function mapStateToProps(state) {
  const recipesFormValueSelector = formValueSelector(FORM_NAME);
  const formValues = recipesFormValueSelector(
    state,
    "themeIdEq",
    "nameCont",
    "courseNameCont",
    "rowsPerPage"
  );

  return {
    initialValues: { rowsPerPage: 10, themeIdEq: " " },
    currentPage: state.recipeList.currentPage,
    totalPages: state.recipeList.totalPages,
    themes: getAllThemes(state),
    formValues,
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: FORM_NAME,
    onChange: debounce(submitRecipesFilter, 500),
  })(RecipesContainer)
);
