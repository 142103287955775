import React from "react";
import Button from "../library/Button";
import { Table } from "semantic-ui-react";
import Link from "../library/Link";

export default function CertificateListItem(props) {
  const { classroom, subscription } = props;

  return (
    <Table.Row verticalAlign="middle">
      {subscription && (
        <>
          <Table.Cell width="4">{subscription?.name}</Table.Cell>
          <Table.Cell width="1" textAlign="right">
            <Link
              component={Button}
              href={`/subscriptions/${subscription?.id}/certificates`}
              target="_blank"
            >
              Baixar
            </Link>
          </Table.Cell>
        </>
      )}

      {classroom && (
        <>
          <Table.Cell width="4">{classroom?.name}</Table.Cell>
          <Table.Cell width="1" textAlign="right">
            <Link
              component={Button}
              href={`/classrooms/${classroom?.id}/certificates`}
              target="_blank"
            >
              Baixar
            </Link>
          </Table.Cell>
        </>
      )}
    </Table.Row>
  );
}

