import React from "react";
import { css } from "glamor";
import { spacing, styles, uiColors } from "../library/theme";
import { Form, TextField } from "../library/forms";
import Button from "../library/Button";

const fieldsWrapper = css({
  display: "flex",
});
const buttonStyle = {
  marginLeft: spacing.xs,
};

export default function CommentForm(props) {
  return (
    <Form onSubmit={props.handleSubmit}>
      <div className={fieldsWrapper}>
        <TextField
          name="text"
          placeholder="Mensagem"
          width="100%"
          fluid
        />
        <Button
          icon="send"
          color="red"
          style={buttonStyle}
        />
      </div>
    </Form>
  );
}
