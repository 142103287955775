import React from "react";
import { Grid, DropdownField } from "../../library";
import makeDropDownOptions from "../../../utils/makeDropDownOptions";
import {
  ShapeField,
  LayerFactorField,
  CakeTypeField,
  CircleDiameterField,
  RetangularDimensionsFields,
} from "../utils";

const cakeOptions = [
  { text: "Coberto/Seminaked", value: "covered", key: "covered" },
  { text: "Naked", value: "naked", key: "naked" },
];

const layerOptions = [
  { text: "3 massas e 2 recheios", value: 1, key: "3" },
  { text: "4 massas e 3 recheios", value: 1.5, key: "4" },
  { text: "5 massas e 4 recheios", value: 2, key: "5" },
  { text: "6 massas e 5 recheios", value: 2.5, key: "6" },
  { text: "7 massas e 6 recheios", value: 3, key: "7" },
];

export default function FillingCalculatorFields({
  shape,
  calculator,
  calculators,
}) {
  const cakeFillingOptions = makeDropDownOptions(
    calculators?.filter(
      ({ id, slug }) => calculator.id != id && slug?.includes("filling")
    ),
    "Nenhuma combinação"
  );

  return (
    <>
      <Grid.Column>
        <ShapeField />
      </Grid.Column>

      <Grid.Column>
        <LayerFactorField options={layerOptions} />
      </Grid.Column>

      <Grid.Column>
        <CakeTypeField options={cakeOptions} />
      </Grid.Column>

      <Grid.Column>
        <DropdownField
          name="fillingCalculatorId"
          label="Selecione uma combinação"
          options={cakeFillingOptions}
          placeholder="Selecione uma opção"
          errorMessageDisabled
          fluid
        />
      </Grid.Column>

      {shape == "circular" && (
        <Grid.Column>
          <CircleDiameterField />
        </Grid.Column>
      )}

      {shape == "retangular" && (
        <Grid.Column>
          <RetangularDimensionsFields />
        </Grid.Column>
      )}
    </>
  );
}
