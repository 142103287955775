import { getInput, getMoldFactor, round } from "../utils/calculateHelpers";

const layerOptions = {
  3: 1,
  4: 1.33,
  5: 1.66,
  6: 2,
  7: 2.33,
};
const alternativeLayerOption = {
  3: 1,
  4: 1.5,
  5: 2,
  6: 2.5,
  7: 3,
};

const getLayerFactor = (layerOption, name, alternativeEnabled = true) => {
  if (["Cinta", "Recheio"].includes(name) && alternativeEnabled) {
    return alternativeLayerOption[layerOption];
  }

  return layerOptions[layerOption];
};

const getSupplyList = ({
  supplies,
  cakeType,
  fruitsPresent,
  layerOption,
  moldFactor,
  alternativeLayerEnabled,
}) => {
  return supplies
    .filter((supply) => {
      const isFromSelectedCakeType = supply.category
        .split(",")
        .some((category) => category == cakeType);

      const isAFruitSupply = supply.category.includes("fruits");
      const WithFruitSelected = fruitsPresent == "withFruits";

      return isAFruitSupply
        ? WithFruitSelected && isFromSelectedCakeType
        : isFromSelectedCakeType;
    })
    .map(({ quantity, name, ...attrs }) => {
      const layerFactor = getLayerFactor(
        layerOption,
        name,
        alternativeLayerEnabled
      );

      return {
        quantity: round(quantity * layerFactor * moldFactor),
        name,
        ...attrs,
      };
    });
};

export default function calculate({ calculator, calculatorFormValues }) {
  const {
    shape,
    height,
    width,
    diameter,
    layerFactor: layerOption,
    cakeType,
    fruitsPresent,
  } = calculatorFormValues;

  const originalDiameter = getInput(calculator, "diameter");
  const moldFactor = getMoldFactor({
    originalDiameter,
    shape,
    height,
    width,
    diameter,
  });

  const supplyList = getSupplyList({
    supplies: calculator.meta.supplies,
    cakeType,
    fruitsPresent,
    layerOption,
    moldFactor,
  });

  const supplyListForWeight = getSupplyList({
    supplies: calculator.meta.supplies,
    cakeType,
    fruitsPresent,
    layerOption,
    moldFactor,
    alternativeLayerEnabled: false,
  });

  const cakeWeight = supplyListForWeight?.reduce((acc, current) => {
    return acc + current.quantity;
  }, 0);
  const slicesQuantity = Math.ceil(cakeWeight / 104);

  return {
    title: calculator.name,
    subtitle: `O peso final aproximado é de ${round(
      cakeWeight
    )}g e o rendimento aproximado de é de ${slicesQuantity} fatias.`,
    imageUrl: calculator.imageUrl,
    ctaLabel: calculator.ctaLabel,
    ctaLink: calculator.ctaLink,
    supplies: supplyList,
  };
}
