import { replace } from "connected-react-router";
import { normalize, schema } from "normalizr";
import { SubmissionError } from "redux-form";
import toQueryString from "../utils/toQueryString";
import { apiGet, apiPost } from "./helpers/api";
import { notifySubmitSucceeded } from "./notifierProcesses";

const recommendationSchema = new schema.Entity("recommendations");

export const fetchRecommendations = (dispatch, params = {}) => {
  const filters = params ? toQueryString(params) : "";
  dispatch({ type: "RECOMMENDATIONS_FETCH_REQUESTED" });
  apiGet(`/api/v1/recommendations?${filters}`).then((response) => {
    dispatch({
      type: "RECOMMENDATIONS_FETCHED",
      ...normalize(response.body.data, new schema.Array(recommendationSchema)),
    });
  });
};

export const submitRecommendationsFilter = (values, dispatch) => {
  fetchRecommendations(dispatch, values);
};

export const createRecommendation = (values, dispatch, props) => {
  return apiPost("/api/v1/recommendations/")
    .send({ ...values, type: `Recommendation::${values.type}` })
    .then(() => {
      notifySubmitSucceeded(dispatch, "Indicação realizada com sucesso.");
      dispatch(replace(`/recommendations/`));
      props.reset();
    })
    .catch((err) => {
      throw new SubmissionError({ _error: err.response?.body?.errors });
    });
};
