import React from "react";
import { connect } from "react-redux";
import Trails from "./Trails";
import { getTagById } from "../../selectors/configSelectors";
import { listOfClassroomsByCurrentTrail } from "../../selectors/classroomSelectors";
import { getCurrentUser } from "../../selectors/userSelectors";
import { getQueryParam } from "../../processes/helpers/getQueryParam";

function TrailsContainer({ trailId, ...props }) {
  return trailId ? <Trails {...props} /> : <></>;
}

function mapStateToProps(state) {
  const trailId = getCurrentUser(state)?.trailId;
  const tagId = getQueryParam("tagId");
  return {
    classrooms: listOfClassroomsByCurrentTrail(state, trailId),
    tag: getTagById(state, tagId),
    trailId,
  };
}

export default connect(mapStateToProps)(TrailsContainer);
