import React from "react";
import SuppliesInput from "./SuppliesInput";
import OriginalValuesInput from "./OriginalValuesInput";
import Form from "../../library/forms/Form";
import { FieldArray } from "redux-form";
import { spacing, uiColors } from "../../library";
import { css } from "glamor";

const containerStyle = {
  marginTop: spacing.xl,
  display: "flex",
  gap: spacing.m,
  flexWrap: "wrap",
  flexDirection: "column",
  padding: spacing.m,
  background: uiColors.fieldBackground,
  borderRadius: spacing.m,
  marginBottom: spacing.l,
};
const titleClass = css({
  fontWeight: "bold",
});
const inputsWrapper = css({
  display: "flex",
  flexWrap: "wrap",
  gap: spacing.m,
});

export default function CustomCalculatorForm({
  calculator,
  factor,
  originalFields,
}) {
  return (
    <Form style={containerStyle}>
      <h3 className={titleClass}>{calculator?.group} | Calcule sua receita</h3>

      <div className={inputsWrapper}>
        <FieldArray
          name="meta.inputs"
          calculator={calculator}
          component={OriginalValuesInput}
          rerenderOnEveryChange
        />
      </div>

      <h4 className={titleClass}>Ingredientes</h4>
      <FieldArray
        name="meta.supplies"
        component={SuppliesInput}
        rerenderOnEveryChange
        factor={factor}
      />
    </Form>
  );
}
