import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import Spinner from "../components/library/Spinner";
import { statusString } from "../utils/statusString";
import { replace } from "connected-react-router";

const hasAccessToProps = (testAccess) => (state) => {
  return {
    hasAccess: testAccess(state),
  };
};

export function restrictAccess(Component, testAccess) {
  const dispatch = useDispatch();
  return connect(hasAccessToProps(testAccess))(function RestrictedComponent({
    hasAccess,
    ...props
  }) {
    useEffect(() => {
      if (typeof hasAccess === "string" && hasAccess === statusString.deny)
        dispatch(replace("/"));
    }, [hasAccess]);
    if (typeof hasAccess === "string" && hasAccess === statusString.loading) {
      return <Spinner />;
    }
    return hasAccess === statusString.allow && <Component {...props} />;
  });
}
