import { getInput, getMoldFactor, round } from "../utils/calculateHelpers";

const CAKE_COVERED_FILLING = 490;
const CAKE_NAKED_FILLING = 975;
const NOT_COMBINED_ADITIONAL = 180;
const ORIGINAL_DIAMETER = 21;

const getBaseYield = ({ cakeType, extraCalculator, custom }) => {
  const baseByType =
    cakeType == "covered" ? CAKE_COVERED_FILLING : CAKE_NAKED_FILLING;

  if (custom) return CAKE_COVERED_FILLING;

  return extraCalculator ? baseByType : NOT_COMBINED_ADITIONAL + baseByType;
};

const getFactor = ({
  calculator,
  extraCalculator,
  cakeType,
  layerFactor,
  shape,
  height,
  width,
  diameter,
  custom,
}) => {
  const customYield = getInput(calculator, "yield");

  const quantitySum =
    customYield ||
    calculator?.meta?.supplies?.reduce(
      (acc, { quantity }) => +acc + +quantity,
      0
    );
  const moldFactor = getMoldFactor({
    originalDiameter: ORIGINAL_DIAMETER,
    shape,
    height,
    width,
    diameter,
  });

  const baseYield = getBaseYield({ cakeType, extraCalculator, custom });

  const desiredYield = baseYield * moldFactor;

  return (desiredYield / quantitySum) * layerFactor;
};

export default function calculate({
  calculator,
  calculators,
  calculatorFormValues,
  custom = false,
}) {
  const {
    shape,
    height,
    width,
    diameter,
    layerFactor,
    cakeType,
    fillingCalculatorId,
  } = calculatorFormValues;

  const extraCalculator = calculators.find(
    ({ id }) => id == fillingCalculatorId
  );

  const factor = getFactor({
    calculator,
    extraCalculator,
    cakeType,
    layerFactor,
    shape,
    height,
    width,
    diameter,
    custom,
  });

  const supplyList = calculator?.meta?.supplies?.map(
    ({ quantity, ...attrs }) => {
      return { quantity: round(quantity * factor), ...attrs };
    }
  );

  const extraFactor = getFactor({
    calculator: extraCalculator,
    extraCalculator: calculator,
    cakeType,
    layerFactor,
    shape,
    height,
    width,
    diameter,
    custom,
  });

  const extraSupplyList = extraCalculator?.meta?.supplies?.map(
    ({ quantity, ...attrs }) => {
      return { quantity: round(quantity * extraFactor), ...attrs };
    }
  );

  return {
    title: calculator.name,
    extraTitle: extraCalculator ? `+ ${extraCalculator.name}` : "",
    imageUrl: calculator.imageUrl,
    ctaLabel: `${calculator ? `${calculator.name} -` : ""} ${
      calculator.ctaLabel
    }`,
    ctaLink: calculator.ctaLink,
    extraCtaLabel: `${extraCalculator ? `${extraCalculator?.name} -` : ""} ${
      extraCalculator?.ctaLabel
    }`,
    extraCtaLink: extraCalculator?.ctaLink,
    supplies: supplyList,
    extraSupplies: extraCalculator ? extraSupplyList : [],
    factor: factor,
  };
}
