import React from "react";
import { Title, spacing } from "../../library";

const titleStyle = {
  display: "flex",
  flexDirection: "column",
  gap: spacing.xxs,
  padding: "0 14px",
};
const subTitleStyle = { fontSize: 14 };

export default function MoldCalculatorDescription({ fromShape, toShape }) {
  const isHoledShapeSelected = fromShape == "holed" || toShape == "holed";

  return (
    <Title as="h4" style={titleStyle} strong>
      <div>
        Tem uma receita que você faz em um tipo e tamanho de fôrma e quer fazer
        em outra? Descubra por qual número multiplicar seus ingredientes.
      </div>
      {isHoledShapeSelected && (
        <p style={subTitleStyle}>
          Como descobrir o volume das minhas fôrmas? É simples, coloque a fôrma
          sobre uma balança e encha 3/4 da fôrma com água. Coloque o peso com
          vírgula (',') aqui na tabela. Exemplo, uma fôrma redonda de 20x4 cm
          comporta 1300 gramas (coloque 1,3 na calculadora da fôrma original
          redonda), e uma fôrma redonda com furo no meio de 21x8cm comporta 1900
          g (coloque 1,9 na calculadora de fôrma desejada).
        </p>
      )}
    </Title>
  );
}
