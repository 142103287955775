import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { Modal } from "semantic-ui-react";
import ClassroomContainer from "./ClassroomContainer";
import { replace } from "connected-react-router";

export default function ClassroomModal(props) {
  const { subscriptionId } = props?.match?.params;
  const dispatch = useDispatch();

  const goToSubscription = useCallback(() => {
    dispatch(replace(`/subscriptions/${subscriptionId}`));
  }, [dispatch]);

  return (
    <Modal open closeIcon onClose={goToSubscription}>
      <ClassroomContainer subscriptionId={subscriptionId} />
    </Modal>
  );
}
