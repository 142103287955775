import React from "react";
import makeDropDownOptions from "../../../utils/makeDropDownOptions";
import { DropdownField, Grid } from "../../library";
import {
  CircleDiameterField,
  LayerFactorField,
  ShapeField,
  RetangularDimensionsFields,
} from "../utils";

const getCakeFillingOptions = (calculator) => {
  const fillings = calculator?.meta?.supplies?.filter(
    ({ category }) => category
  );
  return makeDropDownOptions(fillings, fillings?.length && "Nenhuma");
};

export default function BatterCalculatorFields({ shape, calculator }) {
  const cakeFillingOptions = getCakeFillingOptions(calculator);

  return (
    <>
      <Grid.Column>
        <ShapeField />
      </Grid.Column>
      <Grid.Column>
        <LayerFactorField />
      </Grid.Column>
      {!!cakeFillingOptions.length && (
        <Grid.Column>
          <DropdownField
            name="fillingId"
            label="Selecionar a saborização"
            options={cakeFillingOptions}
            placeholder="Selecione uma opção"
            errorMessageDisabled
            fluid
          />
        </Grid.Column>
      )}
      {shape == "circular" && (
        <Grid.Column>
          <CircleDiameterField />
        </Grid.Column>
      )}
      {shape == "retangular" && (
        <Grid.Column>
          <RetangularDimensionsFields />
        </Grid.Column>
      )}
    </>
  );
}
