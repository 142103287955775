import { createSelector } from "reselect";
import { getClassroomById } from "./classroomSelectors";
import { makeIsWatched } from "./lessonsUserSelectors";

const getRatings = (state) => state.entities.ratings;
const getId = (_, id) => id;

const getLessonRating = createSelector(
  getRatings,
  getId,
  (ratings, ratingableId) =>
    Object.values(ratings).filter(
      (rate) =>
        rate.ratingableType === "Lesson" && rate.ratingableId === ratingableId
    )[0]
);

export const canRateLesson = createSelector(
  getLessonRating,
  makeIsWatched,
  (rating, watched) => !rating && watched
);

export const getClassroomRating = createSelector(
  getRatings,
  getId,
  (ratings, ratingableId) =>
    Object.values(ratings).filter(
      (rate) =>
        rate.ratingableType === "Classroom" &&
        rate.ratingableId === ratingableId
    )[0]
);

export const canRateClassroom = createSelector(
  getClassroomRating,
  getClassroomById,
  (ratings, classroom) => !ratings && classroom?.meta?.progress >= 0.8
);
