import React from "react";
import { css } from "glamor";
import LobbyLayout from "../lobby/LobbyLayout";
import Link from "../library/Link";
import Button from "../library/Button";
import { CardForm, TextField } from "../library/forms";
import { spacing, typographyColors } from "../library/theme";

const linkClass = css({
  color: typographyColors.defaultLink,
  fontWeight: '500',
  textTransform: 'uppercase',
  display: 'block',
  textAlign: 'right',
  paddingTop: 20,
  "&:hover": { color: "rgba(0,0,0, 0.5)" },
});
const buttonMargin = css({ marginTop: spacing.m });

export default function SignInForm(props) {
  return (
    <LobbyLayout>
      <CardForm onSubmit={props.handleSubmit} error={props.error}>
        <TextField
          className={buttonMargin}
          name="email"
          type="email"
          hint="Digite seu e-mail ou nome de usuário"
          placeholder="Usuário"
          size="large"
          required
          fluid
          />
        <TextField
          className={buttonMargin}
          name="password"
          type="password"
          placeholder="Senha"
          size="large"
          required
          fluid
        />
        <Link to="/forgotten-password" className={linkClass}>
          Esqueci minha senha
        </Link>
        <div className={buttonMargin}>
          <Button
            color="red"
            disabled={props.pristine || props.submitting}
            size="large"
            fluid
          >
            LOGIN
          </Button>
        </div>
      </CardForm>
    </LobbyLayout>
  );
}
