import { createSelector } from "reselect";
import { getAllClassrooms } from "./classroomSelectors";
import orderByPosition from "./helpers/orderByPosition";

const getConfigs = (state) => state.entities.configs;
const getConfigName = (_, name) => name;
export const getTags = (state) => state.entities.configs.tags;
export const getId = (_, id) => id;

export const getConfig = createSelector(
  getConfigs,
  getConfigName,
  (configs, name) => {
    const config = configs[name];
    return config && Object.values(config);
  }
);

export const getCitiesByStateId = createSelector(
  getConfigs,
  getId,
  (configs, id) =>
    Object.values(configs["cities"])?.filter((city) => city?.stateId == id)
);

export const isLoadingCities = createSelector(
  getConfigs,
  (configs) => configs["cities"]?.loading
);

export const getTagIdsWithClassrooms = createSelector(
  getAllClassrooms,
  (classrooms) =>
    [...new Set(classrooms.map((classroom) => classroom.tags))].reduce(
      (a, b) => a.concat(b),
      []
    )
);

export const getUserTags = createSelector(
  getTags,
  getTagIdsWithClassrooms,
  (tags, tagIds) =>
    Object.values(tags)
      .filter((tag) => tagIds.includes(tag.id))
      .sort(orderByPosition)
);

export const getTagById = createSelector(
  getTags,
  getId,
  (tags, id) => Object.values(tags).filter((tag) => tag.id == id)[0]
);
