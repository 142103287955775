export const spacing = {
  xxs: "4px",
  xs: "8px",
  s: "12px",
  m: "20px",
  l: "32px",
  xl: "52px",
  xxl: "84px",
};

export const colors = {
  brand: "#fffde6",
  brandDark: "#e04942",
  white: "#fff",
  greyAlternative: "#F6F6F9",
  greyLightest: "#f3f3f4",
  greyLighter: "#e7e9ea",
  greyLight: "#cfd2d4",
  greyDark: "#676A6C",
  greyDarker: "#4F4F4F",
  greyDarkest: "#1D2224",
  brownDark: "#3D2921",
  brown: "#b78751",
  danger: "#de3f2e",
  warning: "#e07900",
  success: "#10ac3a",
  decorationSoft: "#fcc8b9",
  decorationHard: "#aadeca",
  shadow: "rgba(0, 0, 0, 0.1)",
  overlay: "rgba(2, 13, 66, 0.40)",
};

export const decorationColors = {
  decorationBrand: colors.brand,
  decorationBrandDark: colors.brandDark,
  decorationSoft: colors.decorationSoft,
  decorationHard: colors.decorationHard,
  decorationBrown: colors.brown,
  decorationBrownDark: colors.brownDark,
};

export const utilityColors = {
  information: colors.brand,
  danger: colors.danger,
  success: colors.success,
  warning: colors.warning,
  shadow: colors.shadow,
  overlay: colors.overlay,
};

export const typographyColors = {
  defaultLink: colors.brandDark,
  defaultTitle: colors.greyDarker,
  defaultBody: colors.greyDark,
  subtitle: colors.greyDark,
  inverseTitle: colors.white,
  inverseBody: colors.greyLightest,
};

export const uiColors = {
  background: colors.greyAlternative,
  theme: colors.brandDark,
  backgroundDark: colors.brandDark,
  backgroundDarker: colors.greyDarker,
  backgroundDarkest: colors.greyDarkest,
  border: colors.greyLighter,
  borderInverse: colors.greyDark,
  fieldBackground: colors.white,
  contentCards: colors.greyLightest,
};

export const stateColors = {
  focus: colors.brand,
  hover: colors.greyDark,
  empty: colors.greyLight,
  filled: colors.greyLighter,
  disabled: colors.greyLightest,
};

export const buttonColors = {
  primary: colors.brand,
  default: colors.greyLighter,
  textPrimary: colors.white,
  textDefault: colors.greyDark,
};

export const styles = {
  defaultFontFamily: {
    fontFamily: "Poppins",
    fontWeight: "300",
  },
  roundedCorners: { borderRadius: spacing.xxs },
  border: {
    boxShadow: `0 0 0 1px ${uiColors.border} inset`,
  },
  shadow: { boxShadow: `0 1px 2px ${utilityColors.shadow}` },
  cardShadow: { boxShadow: "0px 3px 6px #3A5E9429" },
  transition: { transition: "all 150ms ease-in" },
  bodyText: {
    color: typographyColors.defaultBody,
    fontSize: 14,
    letterSpacing: "-0.4px",
    lineHeight: 1,
  },
  wrappingText: {
    lineHeight: 1.5,
  },
  bodyTextBold: {
    fontWeight: 600,
  },
  bodyTextItalic: {
    fontStyle: "italic",
  },
  monospace: {
    fontFamily: "monospace",
    letterSpacing: "normal",
  },
  bodyTextSmall: {
    fontSize: 12,
  },
  whiteText: {
    color: typographyColors.inverseTitle,
    textShadow: `0px 1px 2px ${utilityColors.shadow}`,
  },
  whiteBodyText: {
    color: typographyColors.inverseBody,
    textShadow: `0px 1px 2px ${utilityColors.shadow}`,
  },
  gridHorizontalScroll: {
    display: "grid",
    gridGap: "16px",
    padding: "16px",
    gridTemplateColumns: "repeat(auto-fill, 160px)",
    gridAutoFlow: "column",
    gridAutoColumns: "160px",
    overflowX: "auto",
    marginBottom: "20px",
    paddingLeft: 0,
  },
};
