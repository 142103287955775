import React from "react";
import { createSelector } from "reselect";
import { Dropdown } from "../components/library";
import { onlyUnique } from "../utils/onlyUnique";

const getCalculators = (state) => state.entities.calculators;
const getId = (_, id) => id;

export const getAllCalculators = createSelector(getCalculators, (calculators) =>
  Object.values(calculators)
);

export const getCalculatorById = createSelector(
  getCalculators,
  getId,
  (calculators, id) => {
    return calculators[id];
  }
);

export const getCalculatorOptions = createSelector(
  getAllCalculators,
  (calculators) => {
    const calculatorGroups = calculators
      .map(({ group }) => group)
      .filter(onlyUnique);

    return calculatorGroups.reduce((acc, group) => {
      acc[group] = {
        name: group,
        results: calculators
          .filter((calculator) => calculator.group == group)
          .map((calculator) => {
            return {
              key: calculator.id,
              title: calculator.name,
              value: calculator.id,
              category: calculator.group,
            };
          }),
      };

      return acc;
    }, {});
  }
);
