import React, { useCallback } from "react";
import Rating from "../Rating";

export default function RatingInput(props) {
  const { onChange, value } = props;
  const ratingHandler = useCallback((_, { rating }) => {
    onChange(rating);
  }, []);

  return <Rating onRate={ratingHandler} value={value} {...props} />;
}
