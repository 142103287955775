import { apiGet } from "./helpers/api";
import { normalize, schema } from "normalizr";

const tagSchema = new schema.Entity("tags");

export const fetchTags = (dispatch) => {
  return apiGet("/api/v1/tags").then((response) => {
    dispatch({
      type: "TAGS_FETCHED",
      ...normalize(response.body.data, [tagSchema]),
    });
  });
};

export const fetchTag = (dispatch, tagId) => {
  return apiGet(`/api/v1/tags/${tagId}`).then((response) => {
    dispatch({
      type: "TAGS_FETCHED",
      ...normalize(response.body.data, [tagSchema]),
    });
  });
};
