import React, { useCallback } from "react";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import SearchForm from "./SearchForm";
import debounce from "../../utils/debounce";
import { submitSearch } from "../../processes/searchProcesses";

const categoryFormat = (category) => ({
  ...category,
  results: category?.results?.map((result) => ({
    title: result.title,
    image: result.imageUrl,
    url: result.url,
    previewPath: result.previewPath,
    description: result.description,
  })),
});

const resultOptions = (categories) => {
  return categories && !Array.isArray(categories)
    ? {
        classrooms: categoryFormat(categories["classrooms"]),
        lessons: categoryFormat(categories["lessons"]),
        attachments: categoryFormat(categories["attachments"]),
      }
    : categories;
};

function SearchFormContainer(props) {
  const resultSelectHandler = useCallback((e, props) => {
    const { url, previewPath } = props.result;
    if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
      window.location = url || previewPath;
    }
    window.open(url || previewPath, "_blank");
  }, []);

  return <SearchForm onResultSelect={resultSelectHandler} {...props} />;
}

function mapStateToProps(state) {
  const { loading, results } = state.searchResults;

  return {
    initialValues: {},
    loading,
    results: resultOptions(results),
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "searchForm",
    onSubmit: submitSearch,
    onChange: debounce(submitSearch, 500),
  })(SearchFormContainer)
);
