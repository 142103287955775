import { replace } from "connected-react-router";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { Modal } from "semantic-ui-react";
import CreateRecommendationsFormContainer from "./CreateRecommendationsFormContainer";

export default function CreateRecommendationsModal() {
  const dispatch = useDispatch();

  const goToMocks = useCallback(() => {
    dispatch(replace("/recommendations"));
  }, [dispatch]);

  return (
    <Modal open closeIcon size="small" onClose={goToMocks}>
      <CreateRecommendationsFormContainer />
    </Modal>
  );
}
