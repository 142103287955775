import React, { useCallback, createRef } from "react";
import ReactPlayer from "react-player";
import { css } from "glamor";
import { styles } from "../library/theme";

const container = css({
  position: "relative",
  paddingBottom: "56.25%",
  height: 0,
});
const videoStyle = css(styles.roundedCorners, {
  position: "absolute",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  border: "none",
});

const PROGRESS_INTERVAL = 15000;

export default function Video({ url, progress, lessonProgressHandler }) {
  const playerRef = createRef();
  const onProgressHandler = useCallback(
    ({ played }) => played !== 0 && lessonProgressHandler(played),
    [lessonProgressHandler]
  );
  const onDurationHandler = useCallback(
    (duration) => {
      const playedSeconds = duration * progress;
      playedSeconds && playerRef.current.seekTo(playedSeconds);
    },
    [progress]
  );

  return (
    <div className={container}>
      <ReactPlayer
        ref={playerRef}
        url={url}
        className={videoStyle}
        onProgress={onProgressHandler}
        onDuration={onDurationHandler}
        progressInterval={PROGRESS_INTERVAL}
        width="100%"
        height="100%"
        controls
      />
    </div>
  );
}
