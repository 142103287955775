import React from "react";
import TextField from "../../library/forms/TextField";
import { Button, Icon, uiColors, spacing } from "../../library";
import { css } from "glamor";
import { round } from "../utils/calculateHelpers";

const customSupplyContainer = css({
  padding: spacing.m,
  display: "flex",
  justifyContent: "center",
  flexWrap: "wrap",
  gap: spacing.s,
  background: uiColors.contentCards,
  borderRadius: spacing.m,
});
const fieldWrapper = css({
  flex: 1,
  minWidth: 200,
});
const resultClass = css({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minWidth: "75px",
  fontSize: 18,
  fontWeight: "bolder",
  paddingTop: spacing.m,
});
const removeWrapperClass = css({
  display: "flex",
  alignItems: "end",
});
const addSupplyWrapperClass = css({
  display: "flex",
  justifyContent: "center",
});

const RemoveButton = ({ fields, index }) => {
  const removeSupply = React.useCallback(
    () => fields.remove(index),
    [fields, index]
  );

  return (
    <div className={removeWrapperClass}>
      <Button type="button" icon="trash" color="white" onClick={removeSupply} />
    </div>
  );
};

export default function SuppliesInput({ fields, factor }) {
  const createNewSupply = React.useCallback(() => {
    fields.push({ quantity: 0 });
  }, [fields]);

  return fields ? (
    <>
      {fields.map((supply, index) => (
        <div key={index} className={customSupplyContainer}>
          <div className={fieldWrapper}>
            <TextField
              name={`${supply}.name`}
              placeholder="Seu ingrediente"
              label="Ingrediente"
              fluid
            />
          </div>

          <div className={fieldWrapper}>
            <TextField
              name={`${supply}.quantity`}
              label="Quantidade (g)"
              placeholder="Gramas"
              type="number"
              errorMessageDisabled
              step="0.1"
              fluid
            />
          </div>

          <div className={resultClass}>
            {round(fields?.get(index)?.quantity * (factor || 1))}g
          </div>

          <RemoveButton fields={fields} index={index} />
        </div>
      ))}

      <div className={addSupplyWrapperClass}>
        <Button type="button" color="red" onClick={createNewSupply}>
          <Icon name="plus" />
          Adicionar Ingrediente
        </Button>
      </div>
    </>
  ) : null;
}
