const getThemes = (action) => action.entities.themes;

export default function themesReducer(state = {}, action) {
  switch (action.type) {
    case "THEME_FETCHED":
    case "THEMES_FETCH_REQUESTED":
      return {};
    case "THEMES_FETCHED":
      return { ...state, ...getThemes(action) };
    default:
      return state;
  }
}
