import React from "react";
import { css } from "glamor";
import Link from "./Link";
import IconMore from "./IconMore";

const imageWrapper = css({
  overflow: "hidden",
  borderRadius: "10px",
});
const hoverClass = css({
  transform: "scale(1.1)",
  "> :first-child": {
    display: "flex !important",
  },
  "> div": {
    opacity: 1,
  },
});
const imageContainer = css({
  position: "relative",
  height: 0,
  height: "250px",
  backgroundSize: "cover !important",
  backgroundPosition: "center !important",
  borderRadius: "10px",
  overflow: "hidden",
  transition: "all .5s",
  "> div": {
    transition: "all .5s",
    opacity: 0,
  },
  ":hover": {
    transform: "scale(1.1)",
    "> :first-child": {
      display: "flex !important",
    },
    "> div": {
      opacity: 1,
    },
  },
});

export default function Card({
  to,
  imageUrl,
  icon = "play",
  iconColor,
  label = null,
  customImageWrapper,
  customImageContainer,
  hovered,
}) {
  const hoverStyle = {
    background: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${imageUrl})`,
    backgroundSize: "cover !important",
    backgroundPosition: "center !important",
  };

  return (
    <Link to={to} draggable="false">
      <div className={css(imageWrapper, customImageWrapper)}>
        <div
          className={css(
            imageContainer,
            hovered && hoverClass,
            customImageContainer,
            {
              backgroundImage: `url(${imageUrl})`,
              ":hover": hoverStyle,
            },
            hovered && hoverStyle
          )}
        >
          <IconMore icon={icon} iconColor={iconColor} label={label} />
        </div>
      </div>
    </Link>
  );
}
