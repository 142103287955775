import React from "react";
import { connect } from "react-redux";
import Classrooms from "./Classrooms";
import { listOfClassroomsWithProgressBySubscription } from "../../selectors/classroomSelectors";

function ClassroomsInProgressContainer(props) {
  const { subscriptionId } = props;
  return (
    <Classrooms
      subscriptionId={subscriptionId}
      title="Continuar assistindo"
      {...props}
    />
  );
}

function mapStateToProps(state, ownProps) {
  const { subscriptionId } = ownProps;
  return {
    classrooms: listOfClassroomsWithProgressBySubscription(
      state,
      subscriptionId
    ),
    loading: state.classroomList.loading,
  };
}

export default connect(mapStateToProps)(ClassroomsInProgressContainer);
