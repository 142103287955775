import React from "react";
import { Title } from "../../library";

const titleStyle = {
  padding: "0 14px",
};

export default function CakePerGuestCalculatorDescription() {
  return (
    <Title as="h4" style={titleStyle} strong>
      Esses números são apenas indicações. Cada evento é único e o comportamento
      pode mudar para mais ou para menos conforme o tipo de público, se há
      bebida alcoólica ou outros doces no local.
    </Title>
  );
}
