import React, { useState, useEffect, useCallback } from "react";
import { css } from "glamor";
import { Search } from "semantic-ui-react";

const blockStyle = {
  display: "block !important",
  width: "100%",
};

const container = css({
  "> div > div.ui.input": {
    width: "100%",
  },
  "> div.ui.search > .results.visible": {
    left: 0,
    width: "fit-content",
    overflowY: "scroll",
    maxHeight: "50vh",
    "@media(max-width: 768px)": {
      width: "auto",
      display: "block",
    },
  },
  "> div.ui.category.search>.results.visible .results": {
    display: "block !important",
  },
  "> div.ui.category.search>.results.visible, > div.ui.category.search>.results.visible .category, > div.ui.category.search>.results.visible .category>.name, > div.ui.category.search>.results.visible .results":
    {
      "@media(max-width: 768px)": blockStyle,
    },
});

const escapeRegExp = (string) => {
  return string.replace(/[.*+\-?^${}()|[\]\\]/g, "\\$&");
};

export default function SearchCategoryInput(props) {
  const { placeholder, options, onChange } = props;

  const [open, setOpen] = useState(false);
  const [results, setResults] = useState(options);
  const [value, setValue] = useState("");

  const openHandler = useCallback(() => setOpen(true), []);
  const closeHandler = useCallback(() => setOpen(false), []);

  useEffect(() => setResults(options), [options]);

  const searchChangeHandler = useCallback(
    (e, data) => {
      setOpen(true);
      setValue(data.value);

      if (!data.value.length) {
        setValue("");
        setResults(options);
        return;
      }

      const re = new RegExp(escapeRegExp(data.value), "i");
      const isMatch = (result) => {
        return re.test(result.title) || re.test(result?.category);
      };

      const filteredResults = Object.keys(options).reduce((memo, name) => {
        const results = options[name].results.filter(isMatch);
        if (results.length) {
          memo[name] = { name, results };
        }
        return memo;
      }, {});

      setResults(filteredResults);
    },
    [options]
  );

  const resultSelectHandler = useCallback(
    (e, data) => {
      onChange(data.result.value);
      setValue(data.result.title);
      setOpen(false);
    },
    [onChange]
  );

  const blurHandler = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <div className={container}>
      <Search
        category
        placeholder={placeholder}
        open={open}
        onFocus={openHandler}
        onBlur={blurHandler}
        onResultSelect={resultSelectHandler}
        onSearchChange={searchChangeHandler}
        results={results}
        value={value}
      />
    </div>
  );
}
