const getRecommendations = (action) => action.entities.recommendations;

export default function recommendationsReducer(state = {}, action) {
  switch (action.type) {
    case "RECOMMENDATION_FETCHED":
    case "RECOMMENDATIONS_FETCH_REQUESTED":
      return {}
    case "RECOMMENDATIONS_FETCHED":
      return { ...state, ...getRecommendations(action) };
    default:
      return state;
  }
}
