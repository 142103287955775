import React, { useState, useCallback } from "react";
import { css } from "glamor";
import IconMore from "./IconMore";
import { Modal } from "semantic-ui-react";

const imageWrapper = css({
  overflow: "hidden",
  borderRadius: "10px",
});
const imageContainer = css({
  position: "relative",
  height: 0,
  height: "250px",
  backgroundSize: "cover !important",
  backgroundPosition: "center !important",
  borderRadius: "10px",
  overflow: "hidden",
  transition: "all .5s",
  ":hover": {
    transform: "scale(1.1)",
    "> :first-child": {
      display: "flex !important",
    },
    "> div": {
      opacity: 1,
    },
  },
  "> div": {
    transition: "all .5s",
    opacity: 0,
  },
});

export default function CardModal({
  imageUrl,
  icon = "play",
  iconColor,
  label = null,
  size = "large",
  component,
}) {
  const [open, setOpen] = useState(false);
  const closeModal = useCallback(() => setOpen(false), []);
  const openModal = useCallback(() => setOpen(true), []);

  return (
    <Modal
      closeIcon
      size={size}
      open={open}
      onClose={closeModal}
      onOpen={openModal}
      trigger={
        <div className={imageWrapper}>
          <div
            className={css(imageContainer, {
              backgroundImage: `url(${imageUrl})`,
              ":hover": {
                background: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${imageUrl})`,
              },
            })}
          >
            <IconMore icon={icon} iconColor={iconColor} label={label} />
          </div>
        </div>
      }
    >
      <Modal.Content scrolling>{component}</Modal.Content>
    </Modal>
  );
}
