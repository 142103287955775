import { css } from "glamor";
import React from "react";
import { Icon } from "semantic-ui-react";
import ClassroomListItem from "../classrooms/ClassroomListItem";
import Link from "../library/Link";
import { spacing, typographyColors, uiColors } from "../library/theme";
import Title from "../library/Title";

const container = css({
  display: "flex",
  gap: spacing.m,
  cursor: "pointer",
  ":hover": {
    "> div > h3": {
      color: typographyColors.defaultLink,
    },
    "> div > div > div > div > div > div": {
      transform: "scale(1.1)",
      transition: "all .5s",
    },
  },
});
const imageWrapper = css({
  display: "grid",
  gridGap: "16px",
  gridTemplateColumns: "repeat(auto-fill, 160px)",
  gridAutoFlow: "column",
  gridAutoColumns: "160px",
  overflowX: "auto",
  marginBottom: "20px",
});
const descriptionWrapper = css({
  display: "inline",
});
const description = css({
  color: typographyColors.subtitle,
  fontWeight: 500,
});
const difficultyClass = css({
  marginBottom: 0,
  fontSize: 12,
  fontWeight: 510,
  color: typographyColors.defaultBody,
});
const difficulties = {
  easy: { label: "Básico", color: "green" },
  medium: { label: "Intermediário", color: "yellow" },
  hard: { label: "Avançado", color: "red" },
};

export default function TagClassroomListItem(props) {
  const { classroom } = props;

  return (
    <Link to={`/classrooms/${classroom?.id}`} className={container}>
      <div>
        <div className={imageWrapper}>
          <ClassroomListItem classroom={classroom} hideTitle hideDifficulty />
        </div>
      </div>
      <div className={descriptionWrapper}>
        {classroom?.difficulty && (
          <p className={difficultyClass}>
            <Icon
              name="circle"
              color={difficulties[classroom?.difficulty].color}
              size="small"
            />
            {difficulties[classroom?.difficulty].label.toUpperCase()}
          </p>
        )}
        <Title as="h3" strong>
          {classroom?.name}
        </Title>
        <p className={description}>{classroom?.description}</p>
      </div>
    </Link>
  );
}
