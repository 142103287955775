import { apiGet } from "./helpers/api";
import { normalize, schema } from "normalizr";
import toQueryString from "../utils/toQueryString";

export const classroom = new schema.Entity("classrooms");

export const fetchClassroom = (dispatch, classroomId) => {
  dispatch({ type: "CLASSROOMS_FETCH_REQUESTED" });
  apiGet(`/api/v1/classrooms/${classroomId}`).then((response) =>
    dispatch({
      type: "CLASSROOM_FETCHED",
      ...normalize(response.body.data, classroom),
    })
  );
};

export const fetchSubscriptionClassrooms = (dispatch, subscriptionId) => {
  return apiGet(`/api/v1/subscriptions/${subscriptionId}/classrooms`).then(
    (response) =>
      dispatch({
        type: "CLASSROOMS_FETCHED",
        ...normalize(response.body.data, classroom),
      })
  );
};

export const fetchClassrooms = (dispatch, params = {}) => {
  const filters = params ? toQueryString(params) : "";
  dispatch({ type: "CLASSROOMS_FETCH_REQUESTED" });
  apiGet(`/api/v1/classrooms?${filters}`).then((response) =>
    dispatch({
      type: "CLASSROOMS_FETCHED",
      ...normalize(response.body.data, new schema.Array(classroom)),
    })
  );
};

export const submitFilter = (values, dispatch) => {
  fetchClassrooms(dispatch, values);
};
