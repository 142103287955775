import { connect } from "react-redux";
import {
  getClassroomsBySubscription,
  makeIsExpired,
} from "../../selectors/subscriptionSelectors";
import Classrooms from "../classrooms/Classrooms";

function mapStateToProps(state, ownProps) {
  const subscription = ownProps?.subscription;
  const isExpired = makeIsExpired(state, subscription?.id);

  return {
    title: `${subscription?.name} ${isExpired ? "(Assinatura Expirada)" : ""}`,
    classrooms: getClassroomsBySubscription(state, subscription?.id),
  };
}

export default connect(mapStateToProps)(Classrooms);
