import Fields from "./MoldCalculatorFields";
import Result from "./MoldCalculatorResult";
import Description from "./MoldCalculatorDescription";
import calculate from "./calculate";

const MoldCalculator = {
  Description,
  Fields,
  Result,
  Custom: () => null,
  calculate,
};

export default MoldCalculator;
