import React from "react";
import { spacing } from "../library/theme";

const chatIframeStyle = {
  width: "100%",
  height: `calc(100vh - 150px)`,
  padding: `0 ${spacing.s}`,
};

export default function Chat({ url }) {
  return <iframe src={url} style={chatIframeStyle} frameBorder="0" />;
}
