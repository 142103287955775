import React from "react";
import { css } from "glamor";
import { Accordion, Icon } from "semantic-ui-react";
import LevelListItemContainer from "../levels/LevelListItemContainer";
import { styles, spacing } from "../library/theme";

const layout = css(styles.shadow);
const titleContainer = css({
  padding: `${spacing.m} !important`,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});
const titleClass = css({
  margin: 0,
  paddingRight: spacing.s,
  maxWidth: "100%",
});

export default function LevelGroupListItem({
  levelGroup,
  levels,
  handleClick,
  active,
}) {
  return (
    <Accordion className={layout.toString()}>
      <Accordion.Title
        className={titleContainer.toString()}
        onClick={handleClick}
      >
        <h4 className={titleClass}>{levelGroup.name}</h4>
        <Icon name={active ? "chevron up" : "chevron down"} />
      </Accordion.Title>
      <Accordion.Content active={active}>
        {levels?.map((level, index) => (
          <LevelListItemContainer key={index} level={level} />
        ))}
      </Accordion.Content>
    </Accordion>
  );
}
