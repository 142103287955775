import React from "react";
import { css } from "glamor";
import { spacing, uiColors } from "./theme";
import Link from "./Link";
import { decorationColors, typographyColors } from "./theme";
import { FaChevronLeft } from "react-icons/fa";
import Footer from "../footer/Footer";
import Title from "./Title";
import LibraryContainer from "./Container";

const linkClass = css({
  color: decorationColors.decorationBrandDark,
  marginRight: spacing.m,
  whiteSpace: "nowrap",
});
const header = css({
  display: "flex",
  minHeight: 87,
  flexWrap: "wrap",
  alignItems: "center",
  padding: spacing.m,
  whiteSpace: "pre-line",
  wordBreak: "break-word",
  "> h1, h2, h3": {
    color: typographyColors.defaultTitle,
    flex: 1,
    fontWeight: "bold",
  },
  "@media (max-width: 414px)": {
    minHeight: 110,
    flexDirection: "column",
    alignItems: "flex-start",
    "> a": { marginBottom: spacing.m },
  },
});
const containedHeadClass = css({
  paddingRight: 0,
  paddingLeft: 0,
});
const content = css({
  padding: spacing.m,
  minHeight: "calc(100vh - 70px)",
});
const containedContentClass = css({
  padding: 0,
});
const filterWrapper = css({
  flex: 1,
  display: "inline-flex",
  justifyContent: "flex-end",
  alignItems: "center",
});
const titleWrapper = {
  display: "flex",
  flexDirection: "column",
  gap: spacing.s,
  marginTop: spacing.l,
  marginBottom: spacing.xs,
  fontSize: 16,
};

export default function Page(props) {
  const { title, subtitle, backlink, filter, children, contained, ...rest } =
    props;
  const Container = contained ? LibraryContainer : "span";

  return (
    <div {...rest}>
      <Container>
        {title && (
          <div className={css(header, contained && containedHeadClass)}>
            {backlink && (
              <Link to={backlink} className={linkClass}>
                <FaChevronLeft size={12} /> VOLTAR
              </Link>
            )}
            <div style={titleWrapper}>
              <Title as="h2" strong>
                {title}
              </Title>
              {subtitle && <p>{subtitle}</p>}
            </div>
            {filter && <div className={filterWrapper}>{filter}</div>}
          </div>
        )}
        <div className={css(content, contained && containedContentClass)}>
          {children}
        </div>
        <Footer />
      </Container>
    </div>
  );
}
