import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Tag from "./Tag";
import { fetchTag, fetchTags } from "../../processes/tagProcesses";
import { fetchClassrooms } from "../../processes/classroomProcesses";
import { getClassroomsByTagId } from "../../selectors/classroomSelectors";
import { getAllTagsExcept, getTagById } from "../../selectors/tagSelectors";

function TagContainer(props) {
  const [loading, setLoading] = useState(false);

  const { dispatch, tagId } = props;

  useEffect(() => {
    setLoading(true);
    fetchTag(dispatch, tagId)
      .then(() => fetchClassrooms(dispatch))
      .then(() => fetchTags(dispatch, tagId))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, [dispatch, tagId]);

  return <Tag loading={loading} {...props} />;
}

function mapStateToProps(state, ownProps) {
  const tagId = ownProps?.match?.params?.tagId;
  const tag = getTagById(state, tagId);

  return {
    tagId,
    tag,
    tags: getAllTagsExcept(state, tag?.id),
    classrooms: getClassroomsByTagId(state, tag?.id),
  };
}

export default connect(mapStateToProps)(TagContainer);
