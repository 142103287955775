import React from "react";
import { connect } from "react-redux";
import Comments from "./Comments";
import { fetchComments } from "../../processes/commentProcesses";
import { getCommentsByLesson } from "../../selectors/commentSelectors";

function CommentsContainer(props) {
  const { dispatch, currentClassroomId, lessonId } = props;

  const fetchCommentsHandler = React.useCallback(
    (_, data) =>
      fetchComments(dispatch, currentClassroomId, lessonId, data.activePage),
    [dispatch, currentClassroomId, lessonId]
  );

  React.useEffect(() => {
    fetchCommentsHandler(dispatch, currentClassroomId, lessonId);
  }, [dispatch, fetchCommentsHandler]);

  return <Comments fetchCommentsHandler={fetchCommentsHandler} {...props} />;
}

function mapStateToProps(state, ownProps) {
  const lessonId = ownProps.lessonId;
  return {
    comments: getCommentsByLesson(state, lessonId),
    currentClassroomId: state.currentClassroomId,
    totalPages: state.commentsList.totalPages,
    loading: state.commentsList.loading,
    currentPage: state.commentsList.currentPage,
    lessonId,
  };
}

export default connect(mapStateToProps)(CommentsContainer);
