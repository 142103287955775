import React, { useEffect } from "react";
import { connect } from "react-redux";
import Classrooms from "./Classrooms";
import { listOfClassrooms } from "../../selectors/classroomSelectors";
import { fetchClassrooms } from "../../processes/classroomProcesses";

function ClassroomsContainer(props) {
  const { dispatch, tag } = props;
  const params = { tagsIdIn: tag?.id };

  useEffect(() => {
    fetchClassrooms(dispatch, params);
  }, [dispatch, tag]);

  return <Classrooms {...props} />;
}

function mapStateToProps(state) {
  return {
    loading: state.classroomList.loading,
    classrooms: listOfClassrooms(state),
  };
}

export default connect(mapStateToProps)(ClassroomsContainer);
