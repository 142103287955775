import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { clearFields, formValueSelector, reduxForm } from "redux-form";
import { fetchCities } from "../../processes/configProcessess";
import { createRecommendation } from "../../processes/recommendationProcesses";
import {
  getCitiesByStateId,
  getConfig,
  isLoadingCities,
} from "../../selectors/configSelectors";
import { CreateRecommendationsForm } from "./CreateRecommendationsForm";

const FORM_NAME = "createRecommendationsForm";

function CreateRecommendationsFormContainer(props) {
  const { dispatch, stateId, isLocationOnline } = props;

  const [nextStep, nextStepState] = useState(false);

  const nextStepHandler = useCallback(() => {
    nextStepState(true);
  }, []);

  useEffect(() => {
    stateId && fetchCities(dispatch, stateId);
  }, [dispatch, stateId]);

  useEffect(() => {
    if (isLocationOnline) {
      dispatch(
        clearFields(
          FORM_NAME,
          false,
          false,
          "stateId",
          "cityId",
          "addressZipcode",
          "address",
          "addressNumber"
        )
      );
    }
  }, [dispatch, isLocationOnline]);

  return (
    <CreateRecommendationsForm
      nextStep={nextStep}
      nextStepHandler={nextStepHandler}
      {...props}
    />
  );
}

function mapStateToProps(state) {
  const createRecommendationsFormSelector = formValueSelector(FORM_NAME);
  const { type, stateId, locationType } = createRecommendationsFormSelector(
    state,
    "type",
    "stateId",
    "locationType"
  );

  const isSupplier = type == "Supplier";
  const isCandyStore = type == "CandyStore";
  const isLocationPhysical = locationType == "physical";
  const isLocationOnline = locationType == "online";

  return {
    type,
    isSupplier,
    isCandyStore,
    isLocationPhysical,
    isLocationOnline,
    stateId,
    states: getConfig(state, "states"),
    cities: getCitiesByStateId(state, stateId),
    loadingCities: isLoadingCities(state),
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: FORM_NAME,
    onSubmit: createRecommendation,
  })(CreateRecommendationsFormContainer)
);
