import React from "react";
import { connect } from "react-redux";
import {
  makeCurrentProgress,
  makeIsWatched,
} from "../../selectors/lessonsUserSelectors";
import LessonListItem from "./LessonListItem";
import { makeIsMobile } from "../../utils/useScreenWidth";

function LessonListItemContainer(props) {
  const { subscriptionId } = props;
  const isMobile = makeIsMobile();

  return (
    <LessonListItem
      subscriptionId={subscriptionId}
      isMobile={isMobile}
      {...props}
    />
  );
}

function mapStateToProps(state, ownProps) {
  const lesson = ownProps.lesson;
  return {
    lesson,
    currentClassroomId: state.currentClassroomId,
    currentLessonId: state.currentLessonId,
    isWatched: makeIsWatched(state, lesson?.id),
    progress: makeCurrentProgress(state, lesson?.id),
  };
}

export default connect(mapStateToProps)(LessonListItemContainer);
