import React from "react";
import { Progress, Icon } from "semantic-ui-react";
import { css } from "glamor";
import { spacing } from "../library/theme";
import truncateString from "../../utils/truncateString";
import Card from "../library/Card";
import CardModal from "../library/CardModal";
import ClassroomContainer from "./ClassroomContainer";

const columnStyle = css({
  gridRow: "1",
  gridColumn: "auto",
  position: "relative",
});
const difficultyClass = css({
  display: "flex",
  alignItems: "center",
  paddingBottom: spacing.xxs,
  marginBottom: 0,
  fontWeight: 300,
  fontSize: "10px",
});
const difficulties = {
  easy: { label: "Básico", color: "green" },
  medium: { label: "Intermediário", color: "yellow" },
  hard: { label: "Avançado", color: "red" },
};
const titleClass = css({
  paddingTop: 0,
  marginTop: 0,
  fontWeight: "bold",
});
const progressClass = css({
  marginTop: `${spacing.xs} !important`,
  marginBottom: `${spacing.xxs} !important`,
  "& .bar": {
    backgroundColor: "#61EA20 !important",
  },
});

export default function ClassroomListItem({
  classroom,
  hideTitle,
  hideDifficulty,
  hideProgress,
  icon,
  iconColor,
  locked,
  lessonsCount,
  subscriptionId,
}) {
  const { name, id, meta, imageUrl, difficulty } = classroom;
  const progressValue = meta.progress * 100;

  return (
    <div className={columnStyle}>
      {locked ? (
        <CardModal
          imageUrl={imageUrl}
          icon={icon}
          iconColor={iconColor}
          component={
            <ClassroomContainer
              locked
              classroom={classroom}
              lessonsCount={lessonsCount}
              hideProgress={hideProgress}
              subscriptionId={subscriptionId}
            />
          }
        />
      ) : (
        <Card
          imageUrl={imageUrl}
          icon={icon}
          iconColor={iconColor}
          to={`/subscriptions/${subscriptionId}/classrooms/${id}`}
        />
      )}
      {!hideProgress && (
        <Progress
          className={progressClass.toString()}
          percent={progressValue}
          size="tiny"
        ></Progress>
      )}
      {!hideDifficulty && difficulty && (
        <div className={difficultyClass}>
          <Icon
            name="circle"
            color={difficulties[difficulty].color}
            size="tiny"
          />
          {difficulties[difficulty].label.toUpperCase()}
        </div>
      )}
      {!hideTitle && <h4 className={titleClass}>{truncateString(name, 60)}</h4>}
    </div>
  );
}
