import {
  calculateCircleArea,
  calculateRetangularArea,
  round,
} from "../utils/calculateHelpers";

const calculateByShapeType = (preposition = "from", shapeType, values = {}) => {
  const {
    fromDiameter,
    toDiameter,
    fromHeight,
    toHeight,
    fromWidth,
    toWidth,
    fromVolume,
    toVolume,
  } = values;

  const height = preposition == "from" ? fromHeight : toHeight;
  const width = preposition == "from" ? fromWidth : toWidth;
  const diameter = preposition == "from" ? fromDiameter : toDiameter;
  const volume = preposition == "from" ? fromVolume : toVolume;

  const calculate = {
    retangular: calculateRetangularArea(height, width),
    circular: calculateCircleArea(diameter),
    holed: volume,
  };

  return calculate[shapeType];
};

export default function calculate({
  calculator,
  calculatorFormValues: { fromShape, toShape, ...otherValues },
}) {
  const fromShapeType =
    fromShape == "holed" || toShape == "holed" ? "holed" : fromShape;
  const toShapeType =
    fromShape == "holed" || toShape == "holed" ? "holed" : toShape;

  const originalResult = calculateByShapeType(
    "from",
    fromShapeType,
    otherValues
  );

  const desiredResult = calculateByShapeType("to", toShapeType, otherValues);

  return {
    title: calculator.name,
    factor: +(desiredResult / originalResult).toFixed(2),
  };
}
