import React from "react";
import { css } from "glamor";
import { Grid, Progress } from "semantic-ui-react";
import Link from "../library/Link";
import Paragraph from "../library/Paragraph";
import { spacing, decorationColors, uiColors } from "../library/theme";
import Card from "../library/Card";
import truncateString from "../../utils/truncateString";

const container = css({
  display: "flex",
  gap: spacing.m,
  borderRadius: spacing.m,
  padding: spacing.s,
  marginBottom: spacing.xxs,
  ":hover": {
    cursor: "pointer",
    backgroundColor: `${decorationColors.decorationBrandDark}15`,
    "> div > div > h3": { color: decorationColors.decorationBrandDark },
  },
});
const activeClass = css({
  backgroundColor: `${decorationColors.decorationBrandDark}10`,
});
const activeTitleClass = css({
  color: decorationColors.decorationBrandDark,
});
const thumbWrapper = css({
  width: "50%",
  minWidth: "145px",
});
const imageWrapper = css({
  position: "relative",
  paddingBottom: "143.25%",
  height: 0,
});
const imageClass = css({
  height: "auto",
  position: "absolute",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  border: "none",
  borderRadius: spacing.s,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center center",
});

const progressClass = css({
  marginTop: `${spacing.xs} !important`,
  "& .bar": {
    backgroundColor: "#61EA20 !important",
  },
});
const descriptionClass = css({
  wordBreak: "break-all",
  fontSize: "1.1rem",
  lineHeight: "1.6rem",
});
const titleClass = css({
  fontWeight: "bold",
  marginTop: spacing.xxs,
  marginBottom: `${spacing.xxs} !important`,
});
const numberClass = css({
  fontWeight: "bold",
  marginBottom: `0 !important`,
  opacity: "0.2",
});
const rightContent = css({
  flex: 1,
});

export default function LessonListItem(props) {
  const {
    lesson,
    currentClassroomId,
    currentLessonId,
    index,
    progress,
    sidebar,
    hideProgress,
    locked,
    subscriptionId,
    isMobile,
  } = props;
  const active = currentLessonId == lesson?.id;

  const [hovered, setHovered] = React.useState(false);
  const mouseEnterHandler = React.useCallback(() => {
    setHovered(true);
  }, []);
  const mouseLeaveHandler = React.useCallback(() => {
    setHovered(false);
  }, []);

  const lessonPath = `/subscriptions/${subscriptionId}/classrooms/${currentClassroomId}/lessons/${lesson.id}`;
  const textSize = isMobile ? 150 : 700;

  const number = (index + 1).toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });

  const cardProps = locked
    ? { icon: "lock", iconColor: "grey" }
    : { to: lessonPath };

  return (
    <Link
      to={!locked && lessonPath}
      onClick={props.hideLeftSidebarHandler}
      onMouseEnter={mouseEnterHandler}
      onMouseLeave={mouseLeaveHandler}
    >
      <div
        to={!locked && lessonPath}
        className={css(container, active && activeClass)}
      >
        <div className={thumbWrapper}>
          <Card
            {...cardProps}
            imageUrl={lesson.imageUrl}
            hovered={hovered}
            customImageWrapper={imageWrapper}
            customImageContainer={imageClass}
          />

          {!hideProgress && (
            <Progress
              className={progressClass.toString()}
              percent={progress ? progress * 100 : 0}
              size="tiny"
            ></Progress>
          )}
        </div>
        <div className={rightContent}>
          <div>
            {!props.sidebar && <h1 className={numberClass}>{number}</h1>}
            <h3 className={titleClass}>{lesson.name}</h3>
          </div>
          {!props.sidebar && (
            <Paragraph layout className={descriptionClass}>
              {truncateString(lesson.description, textSize)}
            </Paragraph>
          )}
        </div>
      </div>
    </Link>
  );
}
