import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getUserTags } from "../../selectors/configSelectors";
import Tags from "./Tags";
import { fetchConfigs } from "../../processes/configProcessess";

function TagsContainer(props) {
  const [loading, setLoading] = useState(false);

  const { dispatch } = props;

  useEffect(() => {
    setLoading(true);
    fetchConfigs(dispatch)
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [dispatch]);

  return <Tags loading={loading} {...props} />;
}

function mapStateToProps(state) {
  return {
    tags: getUserTags(state),
  };
}

export default connect(mapStateToProps)(TagsContainer);
