import React from "react";
import { Carousel as ReactResponsiveCarousel } from "react-responsive-carousel";

const defaultCarouselProps = {
  centerMode: false,
  autoPlay: true,
  dinamicHeight: true,
  interval: 4000,
  showStatus: false,
  showThumbs: false,
  stopOnHover: true,
  infiniteLoop: true,
};

export default function Carousel(props) {
  const { children, ...carouselProps } = props;
  return (
    <ReactResponsiveCarousel {...defaultCarouselProps} {...carouselProps}>
      {children}
    </ReactResponsiveCarousel>
  );
}
