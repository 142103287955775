import React from "react";
import { css } from "glamor";
import { spacing, utilityColors } from "../theme";

const labelStyles = {
  default: {
    textAlign: "left",
    display: "inline-block",
    cursor: "pointer",
  },
  required: {
    ":after": {
      content: "'*'",
      color: utilityColors.danger,
      verticalAlign: "top",
      marginLeft: spacing.xxs,
    },
  },
  disabled: {
    opacity: 0.5,
  },
};

export default function Label(props) {
  const { children, required, disabled, className, strong, ...rest } = props;
  const label = css(
    labelStyles.default,
    required && labelStyles.required,
    disabled && labelStyles.disabled,
    strong && { fontWeight: "bold" },
    className
  );
  return (
    <label className={label} {...rest}>
      {children}
    </label>
  );
}
