import React from "react";
import Empty from "../images/empty.png";
import CoverImage from "../images/cover.jpg";
import { css, spacing, typographyColors } from "../../library";
import { makeIsMobile } from "../../../utils/useScreenWidth";

const containter = css({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  padding: `${spacing.m} 0px`,
});
const title = css({
  margin: `${spacing.s} 0px ${spacing.xs} 0px`,
  color: typographyColors.defaultTitle,
  fontWeight: "bold",
});

export default function EmptyCalculatorPreview(props) {
  const isMobile = makeIsMobile();

  return (
    <div className={containter}>
      {isMobile ? (
        <>
          <img src={Empty} />
          <h3 className={title}>Escolha uma calculadora</h3>
        </>
      ) : (
        <img src={CoverImage} width="100%" />
      )}
    </div>
  );
}
