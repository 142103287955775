import { css } from "glamor";
import {
  spacing,
  styles,
  typographyColors,
  uiColors,
  decorationColors,
} from "./theme";

export default function applyGlobalStyles() {
  css.global("body", {
    ...styles.defaultFontFamily,
    fontSize: 14,
  });

  css.global("h1, h2, h3, h4, h5, h6, p", {
    ...styles.defaultFontFamily,
    color: typographyColors.defaultTitle,
  });

  // OVERRIDES
  css.global(".ui.form input", {
    fontFamily:
      "Roboto, Lato,'Helvetica Neue',Arial,Helvetica,sans-serif !important",
    fontWeight: 100,
  });
  css.global(".ui.comments", {
    maxWidth: "100%",
  });
  css.global(".ui.comments .comment", {
    paddingBottom: spacing.m,
    marginBottom: spacing.m,
    borderBottom: `1px solid ${uiColors.border}`,
  });
  css.global(".ui.comments .comment .comments .comment", {
    paddingBottom: 0,
    marginBottom: 0,
    borderBottom: 0,
  });
  css.global(".ui.comments .comment .comments", {
    marginTop: spacing.s,
  });
  css.global(".ui.comments .comment .text", {
    ...styles.bodyText,
    fontFamily: "Poppins, Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
    lineHeight: 1.5,
    paddingRight: spacing.s,
    marginBottom: 0,
  });
  css.global(".ui.search>.results", {
    left: "auto",
    right: 0,
  });
  css.global(".ui.progress .bar", {
    minWidth: 0,
  });
  css.global(".ui.segment.tab", {
    border: 0,
    padding: 0,
    boxShadow: "none",
  });
  css.global(".ui.button.white", {
    color: decorationColors.decorationBrandDark,
    backgroundColor: uiColors.fieldBackground,
  });
  css.global(".ui.button.white:hover", {
    color: decorationColors.decorationBrandDark,
    backgroundColor: decorationColors.decorationBrand,
  });
  css.global("input[type=radio]", {
    accentColor: "red",
  });
}
