import React, { useEffect } from "react";
import { connect } from "react-redux";
import Classroom from "./Classroom";
import { currentClassroom } from "../../selectors/classroomSelectors";
import { fetchClassroom } from "../../processes/classroomProcesses";
import { canRateClassroom } from "../../selectors/ratingSelectors";
import { listOfLessons } from "../../selectors/lessonSelectors";

function ClassroomContainer(props) {
  const { dispatch, currentClassroomId } = props;

  useEffect(() => {
    fetchClassroom(dispatch, currentClassroomId);
  }, [dispatch, currentClassroomId]);

  return <Classroom {...props} />;
}

function mapStateToProps(state) {
  const classroom = currentClassroom(state);
  return {
    classroom,
    lessonsCount: listOfLessons(state).length,
    currentClassroomId: state.currentClassroomId,
    canRate: canRateClassroom(state, classroom?.id),
  };
}

export default connect(mapStateToProps)(ClassroomContainer);
