import React, { useCallback, useEffect, useState } from "react";
import { AiOutlineLock } from "react-icons/ai";
import { IoBookmarkOutline } from "react-icons/io5";
import { matchPath, useLocation } from "react-router-dom";
import { getQueryParam } from "../../processes/helpers/getQueryParam";
import {
  AccordionMenu,
  Menu,
  MenuItem,
  css,
  spacing,
  typographyColors,
} from "../library";
import TrailModal from "../profileEdit/TrailModal";

const container = css({
  display: "flex",
  flexDirection: "column",
  paddingTop: spacing.s,
});
const mobileClass = css({
  marginTop: 0,
});
const cursorPointer = css({ cursor: "pointer" });
const accordionMenuWrapper = {
  marginRight: "1vw",
  color: typographyColors.defaultTitle,
};

export default function MainMenu(props) {
  const {
    mobile,
    signOutHandler,
    trailSurveyId,
    currentUser,
    subscriptions,
    hasMainSubscriptions,
  } = props;
  const location = useLocation();
  const currentPath = location.pathname;
  const className = css(container, mobile && mobileClass);
  const profileEditPath = "/profile/edit";
  const certificatesPath = "/certificates";
  const calculatorsPath = "/calculators";
  const tagsIdIn = getQueryParam("tagId");

  const openCondition =
    !!trailSurveyId && currentUser?.trailId === null && hasMainSubscriptions;

  const [openModal, setOpenModal] = useState(false);
  const toggleModal = useCallback(() => {
    setOpenModal(!openModal);
  }, [openModal]);

  useEffect(() => {
    if (openCondition) {
      setOpenModal(true);
    }
  }, [openCondition]);

  return (
    <Menu className={className} vertical>
      <MenuItem
        to="/profile/edit"
        icon="user circle outline"
        active={
          !!matchPath(currentPath, { path: profileEditPath, exact: true })
        }
        label="Minha conta"
      />
      {!!subscriptions.length && (
        <div style={accordionMenuWrapper}>
          <AccordionMenu
            active={
              !!matchPath(currentPath, {
                path: "/subscriptions/:subscriptionId",
              })
            }
            label="Meus Produtos"
            disabled={!subscriptions?.length}
            icon={IoBookmarkOutline}
          >
            {subscriptions.map((subscription) => (
              <MenuItem
                to={
                  currentUser?.validSubscriptionIds?.includes(
                    subscription.id
                  ) && `/subscriptions/${subscription.id}`
                }
                active={
                  !!matchPath(currentPath, {
                    path: `/subscriptions/${subscription.id}`,
                    exact: true,
                  })
                }
                disabled={
                  !currentUser?.validSubscriptionIds?.includes(subscription.id)
                }
                disabledIcon={<AiOutlineLock size={22} />}
                disabledHref={subscription.ctaLink}
                label={subscription.name}
              />
            ))}
          </AccordionMenu>
        </div>
      )}
      <MenuItem
        to="/certificates"
        icon="id badge outline"
        active={
          !!matchPath(currentPath, { path: certificatesPath, exact: true })
        }
        label="Meus certificados"
      />
      {hasMainSubscriptions && (
        <>
          <MenuItem
            to="/recipes"
            icon="book"
            active={
              !!matchPath(currentPath, {
                path: "/recipes",
                exact: true,
              })
            }
            label="Índice de Receitas"
          />
          <MenuItem
            as="a"
            target="_blank"
            href="https://www.facebook.com/groups/addolcire"
            icon="users"
            label="Acessar comunidade"
          />
          <MenuItem
            to="#"
            icon="pencil"
            onClick={toggleModal}
            label="Refazer questionário"
          />
          <MenuItem
            to="/calculators"
            icon="calculator"
            active={
              !!matchPath(currentPath, { path: calculatorsPath, exact: true })
            }
            label="Calculadoras"
          />
          <MenuItem
            to="/recommendations"
            icon="star outline"
            active={
              !!matchPath(currentPath, {
                path: "/recommendations",
                exact: true,
              })
            }
            label="Indicações"
          />
        </>
      )}
      <MenuItem
        as="a"
        target="_blank"
        href="mailto:nani.addolcire@gmail.com"
        icon="mail outline"
        label="Falar com a Addolcire"
      />
      <MenuItem
        className={cursorPointer}
        onClick={signOutHandler}
        label="Sair"
        icon="arrow alternate circle right outline"
      />
      <TrailModal
        openModal={openModal}
        onClose={toggleModal}
        trailSurveyId={trailSurveyId}
        currentUserId={currentUser?.id}
      />
    </Menu>
  );
}
