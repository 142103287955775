const defaultSort = (a, b) => {
  return a.text - b.text;
};
export default function makeDropDownOptions(
  collection = [],
  blank,
  sort = defaultSort
) {
  const options = [];
  if (blank) options.push({ text: blank, value: " " });
  collection.map((item, index) =>
    options.push({
      key: item.id || index,
      text: `${item.name || item.title}`,
      value: item.id || item.value,
    })
  );
  return options.sort(sort);
}
