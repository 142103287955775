import { getInput, getMoldFactor, round } from "../utils/calculateHelpers";

const FIXED_FILLING_FACTOR = 100;
const CAKE_COVERED_TOPPING = 525;
const CAKE_SEMINAKED_TOPPING = 300;
const CAKE_RIBBON = 255;
const ORIGINAL_DIAMETER = 21;

export default function calculate({
  calculator,
  calculatorFormValues,
  custom = false,
}) {
  const {
    shape,
    height,
    width,
    diameter,
    layerFactor,
    cakeType,
    fruitsPresent,
    fillingId,
  } = calculatorFormValues;

  const supplies = calculator?.meta?.supplies?.filter(
    (supply) => !supply.category
  );

  const customYield = getInput(calculator, "yield");
  const quantitySum =
    customYield ||
    calculator?.meta?.supplies
      ?.filter(({ category }) => !category)
      ?.reduce((acc, { quantity }) => +acc + +quantity, 0);

  const moldFactor = getMoldFactor({
    originalDiameter: ORIGINAL_DIAMETER,
    shape,
    height,
    width,
    diameter,
  });

  const baseYield =
    cakeType == "covered"
      ? CAKE_COVERED_TOPPING + CAKE_RIBBON
      : CAKE_SEMINAKED_TOPPING + CAKE_RIBBON;

  const desiredYield = (baseYield * moldFactor) / quantitySum;

  const factor = desiredYield * (custom ? 1 : layerFactor);

  const fillingFactor =
    (baseYield * moldFactor * (custom ? 1 : layerFactor)) /
    FIXED_FILLING_FACTOR;

  const supplyList = calculator?.meta?.supplies
    .filter((supply) => !supply.category || supply.id == fillingId)
    ?.map(({ quantity, category, ...attrs }) => {
      return {
        quantity: +(quantity * (category ? fillingFactor : factor)).toFixed(2),
        category,
        ...attrs,
      };
    });

  return {
    title: calculator.name,
    imageUrl: calculator.imageUrl,
    ctaLabel: calculator.ctaLabel,
    ctaLink: calculator.ctaLink,
    supplies: supplyList,
    factor,
  };
}
