import React from "react";
import {
  css,
  spacing,
  uiColors,
  typographyColors,
  Container,
} from "../../library";

const container = {
  marginTop: spacing.xl,
  padding: spacing.m,
  background: uiColors.fieldBackground,
  borderRadius: spacing.m,
  display: "flex",
  flexDirection: "column",
  gap: spacing.m,
  color: typographyColors.defaultBody,
};
const titleClass = css({
  color: typographyColors.defaultTitle,
  marginBottom: spacing.m,
  fontWeight: "bold",
});
const resultNumberClass = css({ fontWeight: "bold", fontSize: 16 });

export default function MoldCalculatorResult({ result }) {
  return result ? (
    <Container style={container}>
      <h3 className={titleClass}>{result.title}</h3>
      <p>
        Multiplique sua receita por{" "}
        <strong className={resultNumberClass}>{result.factor}</strong>
      </p>
    </Container>
  ) : null;
}
