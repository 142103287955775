import React from "react";
import { css } from "glamor";
import {
  styles,
  spacing,
  typographyColors,
  decorationColors,
} from "../library/theme";
import Button from "../library/Button";

let bounce = css.keyframes({
  "0%": { transform: "scale(0.1)", opacity: 0 },
  "60%": { transform: "scale(1.2)", opacity: 1 },
  "100%": { transform: "scale(1)" },
});

const container = css(styles.roundedCorners, styles.transition, {
  width: 220,
  height: 140,
  minHeight: 140,
  backgroundColor: decorationColors.decorationHard,
  padding: spacing.s,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  animation: `${bounce} 0.2s`,
  marginTop: spacing.s,
  position: "relative",
  "&:first-child": {
    marginTop: spacing.m,
  },
  "&:last-child": {
    marginBottom: spacing.m,
  },
});
const textClass = css({ color: typographyColors.defaultBody });
const deleteStyle = {
  position: "absolute",
  top: spacing.s,
  right: spacing.xxs,
};

export default function AnnotationListItem({ annotation, deleteHandler }) {
  return (
    <div className={container}>
      <Button
        onClick={deleteHandler}
        icon="delete"
        size="mini"
        style={deleteStyle}
        circular
      ></Button>
      <p className={textClass}>{annotation.text}</p>
    </div>
  );
}
